import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import { AdminLayout } from "../../../layout/admin";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";
import { useEffect } from "react";

const EditHariLibur = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [hariLibur, setHariLibur] = useState(null);
  const [tanggal, setTanggal] = useState("");
  const [keterangan, setKeterangan] = useState("");

  useEffect(() => {
    setNavTo("/a/libur");
    refreshToken();
    getDetailHariLibur();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeTanggal = (value) => {
    setTanggal(`${value.$y}-${value.$M + 1}-${value.$D}`);
  };

  const handleChangeKeterangan = (keterangan) => {
    setKeterangan(keterangan);
  };

  const getDetailHariLibur = async () => {
    try {
      const url = window.location.pathname.split("/");
      const uuid = url[url.length - 1];
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/libur/${uuid}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setHariLibur(response.data);
      setTanggal(response.data.tanggal);
      setKeterangan(response.data.keterangan);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
      }
    }
  };

  const handleSubmitHariLibur = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.put(
        `${process.env.REACT_APP_API_URL}/a/libur/${hariLibur.id}`,
        { tanggal: tanggal, keterangan: keterangan },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/libur");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Hari Libur
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmitHariLibur}>
            {hariLibur !== null ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Tanggal Libur"
                  defaultValue={dayjs(hariLibur.tanggal)}
                  sx={{ width: "100%", marginBottom: "20px" }}
                  onChange={(newValue) => handleChangeTanggal(newValue)}
                />
              </LocalizationProvider>
            ) : (
              <Skeleton height={60} />
            )}
            {hariLibur !== null ? (
              <TextField
                id="outlined-basic"
                label="Keterangan"
                defaultValue={hariLibur.keterangan}
                variant="outlined"
                sx={{ width: "100%", marginBottom: "20px" }}
                onChange={(e) => handleChangeKeterangan(e.currentTarget.value)}
              />
            ) : (
              <Skeleton height={60} />
            )}
            <Button type="submit" variant="contained">
              Edit
            </Button>
          </form>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default EditHariLibur;
