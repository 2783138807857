import * as React from "react";
import {
    Box,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    OutlinedInput,
    InputAdornment,
    Button,
    TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useContext } from "react";
import MenuContext from "../../context/MenuProvider";
import MessageContext from "../../context/MessageProvider";
import axios from "axios";
import { useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KoleksiContext from "../../context/KoleksiProvider";

const ShareKoleksiDialog = (props) => {
  const { idKoleksi } = useContext(KoleksiContext);
  const { setMessage } = useContext(MessageContext);

  const { stateShareKoleksiDialog, setStateShareKoleksiDialog } =
    useContext(MenuContext);

  const handleClose = () => {
    setStateShareKoleksiDialog(false);
  };

  const handleCopy = () => {
    try {
        navigator.clipboard.writeText(`simpus.stis.ac.id/koleksi/${idKoleksi}`);
        setStateShareKoleksiDialog(false);
        setMessage({
            isOpen: true,
            content: "Tautan berhasil disalin",
            severity: "success",
        });

    } catch (error) {
        console.log(error.response);
        setMessage({
            isOpen: true,
            content: "Tautan gagal disalin",
            severity: "error",
        });
    }
  };


  return (
    <Dialog open={stateShareKoleksiDialog} onClose={handleClose}>
        <DialogTitle>Bagikan</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Salin tautan berikut untuk membagikan koleksi.
                </DialogContentText>
                <TextField
                    disabled="true"
                    variant="standard"
                    fullWidth
                />
                <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                        placeholder="Telusuri"
                        size="small"
                        color="primary"
                        disabled="true"
                        value={`simpus.stis.ac.id/koleksi/${idKoleksi}`}
                        sx={{ 
                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                            "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            }, 
                            "&.Mui-focused": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            },
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                onClick={handleCopy}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                </DialogContent>
                <DialogActions>
                <Button variant="outlined" onClick={handleClose}>Batal</Button>
                <Button variant="contained" onClick={handleCopy}>Salin Tautan</Button>
            </DialogActions>
      </Dialog>
  );
};

export default ShareKoleksiDialog;
