import {
  Alert,
  Autocomplete,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputBase,
  Typography,
  Container,
  Stack,
  TextField,
  Skeleton,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { MyLayout } from "../../layout";
import SearchIcon from "@mui/icons-material/Search";
import KoleksiCard from "../../components/KoleksiCard";
import color from "../../theme/Color";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TablePaginationDemo from "../../components/TablePagination";
import MessageContext from "../../context/MessageProvider";
import TokenContext from "../../context/TokenProvider";
import MenuContext from "../../context/MenuProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const options = [
  { label: "Tingkat 1", value: "01" },
  { label: "Tingkat 2", value: "02" },
  { label: "Tingkat 3", value: "03" },
  { label: "Tingkat 4", value: "04" },
];

const subOptions = {
  "01": ["D3", "KS", "ST"],
  "02": ["D3", "KS", "ST"],
  "03": ["D3", "SD", "SI", "SE", "SK"],
  "04": ["SD", "SI", "SE", "SK"],
};

const RekomendasiAnda = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const { rekomendasiDialog, setRekomendasiDialog } = useContext(MenuContext);
  const { id, setId } = useContext(MenuContext);
  const navigate = useNavigate();
  const [rekomendasiGanjil, setRekomendasiGanjil] = useState(null);
  const [rekomendasiGenap, setRekomendasiGenap] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTingkat, setSelectedTingkat] = useState(null);
  const [selectedJurusan, setSelectedJurusan] = useState(null);
  const [semesterGanjil, setSemesterGanjil] = useState("");
  const [semesterGenap, setSemesterGenap] = useState("");

  useEffect(
    () => {
      setNavTo("/rekomendasi");
      getMatkulRekomendasiGanjil();
      getMatkulRekomendasiGenap();
    },
    [
      // page,
      // rowsPerPage,
      // getKoleksiTrigger,
    ]
  );

  useEffect(() => {
    console.log("selectedTingkat : ", selectedTingkat);
    console.log("semester Ganjil: ", semesterGanjil);
    console.log("smt Genap : ", semesterGenap);
    calculateSmtGanjil();
    calculateSmtGenap();
  }, [selectedTingkat]);

  const handleTingkatChange = (event, value) => {
    setSelectedTingkat(value ? value.value : null);
    setSelectedJurusan(null);
  };

  const handleAutocompleteChange = (event, value) => {
    setSelectedJurusan(value);
  };

  const handleClickLihatBuku = (idMatkul) => {
    setId(idMatkul);
    setRekomendasiDialog(true);
  };

  const handleClickTampilkan = () => {
    getMatkulRekomendasiGanjil();
    getMatkulRekomendasiGenap();
  };

  const renderAutocompleteOptions = () => {
    if (selectedTingkat && subOptions[selectedTingkat]) {
      return subOptions[selectedTingkat];
    } else {
      return [];
    }
  };

  const calculateSmtGanjil = () => {
    if (selectedTingkat === "01") {
      setSemesterGanjil("01");
    } else if (selectedTingkat === "02") {
      setSemesterGanjil("03");
    } else if (selectedTingkat === "03") {
      setSemesterGanjil("05");
    } else if (selectedTingkat === "04") {
      setSemesterGanjil("07");
    }
  };

  const calculateSmtGenap = (tingkat) => {
    if (selectedTingkat === "01") {
      setSemesterGenap("02");
    } else if (selectedTingkat === "02") {
      setSemesterGenap("04");
    } else if (selectedTingkat === "03") {
      setSemesterGenap("06");
    } else if (selectedTingkat === "04") {
      setSemesterGenap("08");
    }
  };

  const getMatkulRekomendasiGanjil = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rekomendasi?kode=${selectedTingkat}/${selectedJurusan}/${semesterGanjil}/&page=${page}&size=${rowsPerPage}`
      );
      setRekomendasiGanjil(response.data);
      console.log("rekomendasiGanjil : ", response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const getMatkulRekomendasiGenap = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rekomendasi?kode=${selectedTingkat}/${selectedJurusan}/${semesterGenap}/&page=${page}&size=${rowsPerPage}`
      );
      setRekomendasiGenap(response.data);
      console.log("rekomendasiGenap : ", response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <MyLayout
      title={"Rekomendasi Anda"}
      menu={"Koleksi"}
      submenu={"Rekomendasi Anda"}
    >
      <Box
        sx={{
          backgroundColor: color.dark_white,
          marginTop: "-20px",
          paddingY: "20px",
        }}
      >
        <Container maxWidth="lg">
          <div>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label}
              value={
                options.find((option) => option.value === selectedTingkat) ||
                null
              }
              onChange={handleTingkatChange}
              size="small"
              disablePortal
              renderInput={(params) => (
                <TextField {...params} label="Tingkat" />
              )}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                },
                "&.Mui-focused": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
            />
            <br />
            <Autocomplete
              options={renderAutocompleteOptions()}
              value={selectedJurusan}
              onChange={handleAutocompleteChange}
              size="small"
              disablePortal
              renderInput={(params) => (
                <TextField {...params} label="Jurusan" />
              )}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                },
                "&.Mui-focused": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
            />
            <br />
            {/* <TextField label="Nilai" value={getSelectedValue()} /> */}
            <Button
              variant="contained"
              size="small"
              onClick={handleClickTampilkan}
            >
              Tampilkan
            </Button>
          </div>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: color.light_gray }}>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: "40px", paddingBottom: "48px" }}
        >
          <Alert severity="info" sx={{ marginBottom: "24px" }}>
            Untuk menambahkan/mengubah rekomendasi buku, pengurus angkatan
            divisi akademik dapat bekerja sama dengan petugas perpustakaan. Buku
            yang tidak tersedia bisa diajukan.
          </Alert>
          <Typography variant="h6" textAlign="center">
            Semester Ganjil
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Kode</StyledTableCell>
                  <StyledTableCell align="center">Matkul</StyledTableCell>
                  <StyledTableCell align="center">Aksi</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rekomendasiGanjil !== null &&
                  rekomendasiGanjil.rows.map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell align="center">
                        {row.kodeMatkul}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.matkul}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClickLihatBuku(row.id)}
                        >
                          Lihat Buku
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {rekomendasiGanjil === null && (
                  <>
                    <StyledTableRow key={1}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={2}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={3}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={4}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={5}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Stack direction="row" justifyContent="center">
            <TablePaginationDemo />
          </Stack> */}
        </Container>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: "40px", paddingBottom: "48px" }}
        >
          <Typography variant="h6" textAlign="center">
            Semester Genap
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Kode</StyledTableCell>
                  <StyledTableCell align="center">Matkul</StyledTableCell>
                  <StyledTableCell align="center">Aksi</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rekomendasiGenap !== null &&
                  rekomendasiGenap.rows.map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell align="center">
                        {row.kodeMatkul}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.matkul}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClickLihatBuku(row.id)}
                        >
                          Lihat Buku
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {rekomendasiGenap === null && (
                  <>
                    <StyledTableRow key={1}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={2}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={3}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={4}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={5}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Stack direction="row" justifyContent="center">
            <TablePaginationDemo />
          </Stack> */}
        </Container>
      </Box>
    </MyLayout>
  );
};

export default RekomendasiAnda;
