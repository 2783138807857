import * as React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Typography,
  OutlinedInput,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useState, useEffect } from "react";
import MenuContext from "../../context/MenuProvider";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import Html5QrcodePlugin from "../QrCodeScanner";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Loading from '../Loading';

const tabTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          variant: "contained",
          // Penyesuaian saat terpilih
          "&.Mui-selected": {
            backgroundColor: "#023d8a",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#012e63", // Penyesuaian saat hover
            },
          },
          "&:not(.Mui-selected)": {
            backgroundColor: "#e0e0e0",
            color: "inherit",
            "&:hover": {
              backgroundColor: "#cccccc", // Penyesuaian saat hover
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variant: "contained",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#023d8a",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Roboto", "Arial", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: "0" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const QRScanner = (props) => {
  const { stateQRScanner, setStateQRScanner } = useContext(MenuContext);
  const { setStateAlertDialog } = useContext(MenuContext);
  const { stateTujuanBerkunjungDialog, setStateTujuanBerkunjungDialog } =
    useContext(MenuContext);
  const { stateFormPenilaianDialog, setStateFormPenilaianDialog } =
    useContext(MenuContext);
  const { setMessage } = useContext(MessageContext);
  const [codeForm, setCodeForm] = useState("");
  // const [usage, setUsage] = useState("kunjungan");
  const [decodedResults, setDecodedResults] = useState([]); //Scan Result
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [tokenTrigger, setTokenTrigger] = useState(false);
  const [profil, setProfil] = useState(null);

  const handleToken = () => {
    getProfil(); //untuk pengecekan masuk perpus
    console.log("handleToken berhasil");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setStateQRScanner(false);
  };

  const handleClickOpen = () => {
    setStateQRScanner(true);
  };

  // const handleClick = (value) => {
  //   setUsage(value);
  // };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/profil`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setProfil(response.data);
      if (profil.inPerpus === false) {
        setStateQRScanner(false);
        setMessage({
          isOpen: true,
          content: "Anda harus berkunjung sebelum meminjam",
          severity: "error",
        });
        setValue(0);
      }
    } catch (error) {
      if (error.response) {
        setValue(0);
        console.log(error.response);
        setStateQRScanner(false);
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleSendCodeBerkunjung = async (codeBerkunjung) => {
    try {
      handleClose();
      setLoading(true);
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/inout-perpus`,
        { tokenInput: codeBerkunjung },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setLoading(false);
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      if (response.data.inPerpus === true) {
        setStateTujuanBerkunjungDialog(true);
      } else {
        setStateFormPenilaianDialog(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setMessage({
        isOpen: true,
        content: error.response.data.message,
        severity: "error",
      });
      handleClose();
    }
  };

  const handlePeminjaman = async (qrcode, noInduk) => {
    try {
      handleClose();
      setLoading(true);
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/peminjaman`,
        {
          qrcode: qrcode,
          noInduk: noInduk,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(response.data);
      setLoading(false);
      navigate("/riwayat-pinjaman");
      setStateAlertDialog({
        isOpen: true,
        title: "Pengajuan Pinjaman Buku Berhasil",
        content: "Silahkan ke administrator untuk melakukan konfirmasi",
        severity: "success",
      });
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setMessage({
        isOpen: true,
        content: error.response.data.message,
        severity: "error",
      });
    }
  };

  const onNewScanKunjungan = (decodedText, decodedResult) => {
    setDecodedResults((prev) => [...prev, decodedResult]);
    console.log("App [result]", decodedResult.decodedText);
    handleSendCodeBerkunjung(decodedResult.decodedText);
    console.log("App [result]", decodedResult);
  };

  const onNewScanPinjaman = (decodedText, decodedResult) => {
    setDecodedResults((prev) => [...prev, decodedResult]);
    console.log("App [result]", decodedResult.decodedText);
    handlePeminjaman(decodedResult.decodedText, "");
    console.log("App [result]", decodedResult);
  };

  return (
    <ThemeProvider theme={tabTheme}>
      <Dialog
        fullScreen={xs}
        open={stateQRScanner}
        onClose={handleClose}
        maxWidth="xs"
        sx={{
          textAlign: "center",
        }}
      >
        <AppBar
          style={{ display: xs ? "block" : "none" }}
          sx={{ position: "relative" }}
        >
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              QR Scanner
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                value={value}
                centered
                onChange={handleChange}
                indicatorColor="rgba(0,0,0,0)"
                aria-label="basic tabs example"
                sx={{ padding: "0", overflow: "visible" }}
              >
                <Tab
                  label="Kunjungan"
                  {...a11yProps(0)}
                  sx={{
                    borderRadius: "10px 0 0 10px",
                  }}
                />
                <Tab
                  label="Peminjaman Buku"
                  {...a11yProps(1)}
                  onClick={handleToken}
                  sx={{
                    borderRadius: "0 10px 10px 0",
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Html5QrcodePlugin
                fps={1}
                qrbox={1000}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanKunjungan}
              />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setCodeForm(e.currentTarget.value)}
                  placeholder={"Masukkan kode kunjungan"}
                  size="small"
                  sx={{
                    "::placeholder": {
                      textAlign: "center",
                    },
                    "&&": {
                      textAlign: "center",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => handleSendCodeBerkunjung(codeForm)}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Html5QrcodePlugin
                fps={1}
                qrbox={1000}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanPinjaman}
              />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  type="text"
                  onChange={(e) => setCodeForm(e.currentTarget.value)}
                  placeholder={"Masukkan nomor induk buku"}
                  size="small"
                  sx={{
                    "::placeholder": {
                      textAlign: "center",
                    },
                    "&&": {
                      textAlign: "center",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => handlePeminjaman("", codeForm)}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </CustomTabPanel>
          </Box>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loading/>
      </Backdrop>
    </ThemeProvider>
  );
};

export default QRScanner;
