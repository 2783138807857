import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import { AdminLayout } from "../../../layout/admin";
import axios from "axios";
import jwtDecode from "jwt-decode";

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
];

const CreateNotifikasi = () => {
  const navigate = useNavigate();
  const {
    accessToken,
    setAccessToken,
    decodedData,
    setDecodedData,
    navTo,
    setNavTo,
  } = useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [kategori, setKategori] = useState("");
  const [judul, setJudul] = useState("");
  const [konten, setKonten] = useState("");

  useEffect(() => {
    setNavTo("/a/notifikasi/create");
    refreshToken();
  }, [accessToken]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeKategori = (event) => {
    setKategori(event.target.value);
  };

  const handleChangeJudul = (event) => {
    setJudul(event.target.value);
  };

  const handleChangeKonten = (event) => {
    setKonten(event.target.value);
  };

  const handleSubmitNotifikasi = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/notifikasi`,
        { kategori: kategori, judul: judul, konten: konten },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/notifikasi");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Notifikasi
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmitNotifikasi}>
            <FormControl fullWidth sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">Kepada</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={kategori}
                label="Kepada"
                onChange={handleChangeKategori}
              >
                <MenuItem value={"1"}>Semua Pengguna</MenuItem>
                <MenuItem value={"2"}>NIM/NIP</MenuItem>
                <MenuItem value={"3"}>Semua Mahasiswa</MenuItem>
                <MenuItem value={"4"}>Semua Dosen</MenuItem>
                <MenuItem value={"5"}>Mahasiswa Tingkat I</MenuItem>
                <MenuItem value={"6"}>Mahasiswa Tingkat II</MenuItem>
                <MenuItem value={"7"}>Mahasiswa Tingkat III</MenuItem>
                <MenuItem value={"8"}>Mahasiswa Tingkat IV</MenuItem>
                <MenuItem value={"9"}>Mahasiswa Prodi KS</MenuItem>
                <MenuItem value={"10"}>Mahasiswa Prodi Statistik</MenuItem>
                <MenuItem value={"11"}>Mahasiswa Prodi D3</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              disabled={kategori !== "2"}
              sx={{ width: "100%", marginBottom: "20px" }}
              multiple
              id="tags-outlined"
              options={top100Films}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="NIM/NIP" />
              )}
            />
            <TextField
              id="outlined-basic"
              label="Judul"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeJudul}
            />
            <TextField
              id="outlined-basic"
              label="Pesan"
              variant="outlined"
              multiline
              rows={5}
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeKonten}
            />
            <Button type="submit" variant="contained">
              Tambah
            </Button>
          </form>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CreateNotifikasi;
