import { createTheme } from "@mui/material";

export const globalTheme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: "none",
    }
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#023d8a',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#FF8D28',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});