import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Stack } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import color from "../theme/Color";
import { useContext } from "react";
import MenuContext from "../context/MenuProvider";
import KoleksiContext from "../context/KoleksiProvider";

function formatDate(dateString) {
  const date = new Date(dateString);

  // Daftar nama bulan
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const formattedDate = `${day} ${months[monthIndex]} ${year}`;
  return formattedDate;
}

const PeminjamanCard = (props) => {
  const { setStateKoleksi } = useContext(MenuContext);
  const { setIdKoleksi } = useContext(KoleksiContext);

  const toggleDrawer = (open, id) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIdKoleksi(id);
    setStateKoleksi(open);
  };

  return (
    <Card
      onClick={toggleDrawer(true, props.id)}
      sx={{
        backgroundColor: color.light_gray,
        width: "100%",
        margin: "auto",
        marginBottom: "40px",
        boxShadow: "none",
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            margin: "0",
            padding: "0",
            paddingBottom: "0",
            height: "fit-content",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <Typography color="InactiveCaptionText" sx={{ fontSize: "12px" }}>
                {props["namaJenisPustaka"]}, {props["namaKlasifikasi"]}
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>{props.judul}</Typography>
              <Typography sx={{ fontSize: "14px" }}>{props.penulis}</Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ marginTop: "10px" }}
              >
                {props.isKonfirmasi === false ? (
                  <>
                    <WarningIcon color = "warning" />
                    <Typography color = "warning" sx={{ fontSize: "12px", color: "#ed6c02" }}>
                      Anda belum melakukan konfirmasi
                    </Typography>
                  </>
                ) : props.isKembalikan === true ? (
                  <>
                    <CheckCircleOutlineIcon color="success"/> {/* Ganti dengan ikon yang sesuai */}
                    <Typography sx={{ fontSize: "12px", color: "#2e7d32" }}>
                      Telah dikembalikan
                    </Typography>
                  </>
                ) : props.isTerlambat === true ? (
                  <>
                    <WarningIcon color="error"/> {/* Ganti dengan ikon yang sesuai */}
                    <Typography sx={{ fontSize: "12px", color: "#d32f2f" }}>
                      Pengembalian terlambat
                    </Typography>
                  </>
                ) : (
                  <>
                    <AccessTimeIcon />
                    <Typography sx={{ fontSize: "12px" }}>
                      Tanggal pengembalian : {formatDate(props.batasKembali)}
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
            <Stack textAlign="center">
              <Typography sx={{ fontSize: "12px" }}>
                {props["jumlahDibaca"]}
              </Typography>
              <Typography color="InactiveCaptionText" sx={{ fontSize: "12px" }}>
                dibaca
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {props["jumlahDisimpan"]}
              </Typography>
              <Typography color="InactiveCaptionText" sx={{ fontSize: "12px" }}>
                disimpan
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PeminjamanCard;
