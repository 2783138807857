import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../../context/TokenProvider";
import ShowMatkulD3Semester1 from "./Tingkat1/D3/Semester1";
import ShowMatkulD3Semester2 from "./Tingkat1/D3/Semester2";
import ShowMatkulKSSemester2 from "./Tingkat1/KS/Semester2";
import ShowMatkulKSSemester1 from "./Tingkat1/KS/Semester1";
import ShowMatkulSTSemester1 from "./Tingkat1/ST/Semester1";
import ShowMatkulSTSemester2 from "./Tingkat1/ST/Semester2";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanelTingkat1 = () => {
  const { setAccessToken, setDecodedData, setNavTo } = useContext(TokenContext);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setNavTo("/a/rekomendasi");
    refreshToken();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="D3" {...a11yProps(0)} />
          <Tab label="KS" {...a11yProps(1)} />
          <Tab label="ST" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ShowMatkulD3Semester1 />
        <ShowMatkulD3Semester2 />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowMatkulKSSemester1 />
        <ShowMatkulKSSemester2 />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ShowMatkulSTSemester1 />
        <ShowMatkulSTSemester2 />
      </TabPanel>
    </Box>
  );
};

export default TabPanelTingkat1;
