import Login from "../pages/Login";
import Register from "../pages/Register";
import Beranda from "../pages/Beranda";
// Tentang Kami
import TataTertib from "../pages/TentangKami/TataTertib";
import StrukturOrganisasi from "../pages/TentangKami/StrukturOrganisasi";
// Fasilitas
import MejaBaca from "../pages/TentangKami/Fasilitas/MejaBaca";
import KoleksiCetak from "../pages/TentangKami/Fasilitas/KoleksiCetak";
import KoleksiElektronik from "../pages/TentangKami/Fasilitas/KoleksiElektronik";
import FasilitasLainnya from "../pages/TentangKami/Fasilitas/FasilitasLainnya";
// Layanan
import PelayananSirkulasi from "../pages/TentangKami/Layanan/PelayananSirkulasi";
import PeminjamanBahanPustaka from "../pages/TentangKami/Layanan/PeminjamanBahanPustaka";
import SuratBebasPerpustakaan from "../pages/TentangKami/Layanan/SuratBebasPerpustakaan";
// Koleksi
import KoleksiBahanPustaka from "../pages/Koleksi/KoleksiBahanPustaka";
import RekomendasiAnda from "../pages/Koleksi/RekomendasiAnda";
import RiwayatPeminjaman from "../pages/Koleksi/RiwayatPeminjaman";
import RiwayatBaca from "../pages/Koleksi/RiwayatBaca"; 
import PenandaKoleksi from "../pages/Koleksi/PenandaKoleksi";
// Bantuan
import Faq from "../pages/Bantuan/Faq";
import KritikDanSaran from "../pages/Bantuan/KritikDanSaran";
import Denah from "../pages/Bantuan/Denah";
import Dashboard from "../pages/Admin/Dashboard";
import ShowPengguna from "../pages/Admin/Pengguna/Show";
import ShowFeedback from "../pages/Admin/Feedback/Show";
import ShowPengumuman from "../pages/Admin/Pengumuman/Show";
import CreatePengumuman from "../pages/Admin/Pengumuman/Create";
import ShowHariLibur from "../pages/Admin/HariLibur/Show";
import ShowLinkPerpustakaan from "../pages/Admin/LinkPerpustakaan/Show";
import CreateLinkPerpustakaan from "../pages/Admin/LinkPerpustakaan/Create";
import CreateHariLibur from "../pages/Admin/HariLibur/Create";
import ShowKunciLoker from "../pages/Admin/KunciLoker/Show";
import CreateKunciLoker from "../pages/Admin/KunciLoker/Create";
import ShowNotifikasi from "../pages/Admin/Notifikasi/Show";
import CreateNotifikasi from "../pages/Admin/Notifikasi/Create";
import ShowBahanPustaka from "../pages/Admin/BahanPustaka/Show";
import CreateBahanPustaka from "../pages/Admin/BahanPustaka/Create";
import ShowPeminjaman from "../pages/Admin/Peminjaman/Show";
import EditHariLibur from "../pages/Admin/HariLibur/Edit";
import EditLinkPerpustakaan from "../pages/Admin/LinkPerpustakaan/Edit";
import EditPengumuman from "../pages/Admin/Pengumuman/Edit";
import CreateAdmin from "../pages/Admin/Pengguna/CreateAdmin";
import ShowQrcode from "../pages/Admin/QRCode/Show";
import CreateDenahPerpus from "../pages/Admin/DenahPerpus/Create";
import ShowDenahPerpus from "../pages/Admin/DenahPerpus/Show";
import EditDenahPerpus from "../pages/Admin/DenahPerpus/Edit";
import LoginPengunjung from "../pages/Admin/LoginPengunjung";
import EditBahanPustaka from "../pages/Admin/BahanPustaka/Edit";
import GoogleLoginSuccess from "../pages/GoogleLoginSuccess";
import ShowRekomendasi from "../pages/Admin/Rekomendasi/Show";
import Reader from "../components/Reader";
import ShowStatistikPengunjung from "../pages/Admin/Statistik/Pengunjung/Show";
import ShowStatistikPeminjaman from "../pages/Admin/Statistik/Peminjaman/Show";
import ShowFAQ from "../pages/Admin/FAQ/Show";
import CreateFAQ from "../pages/Admin/FAQ/Create";
import EditFAQ from "../pages/Admin/FAQ/Edit";
import ShowTataTertib from "../pages/Admin/TataTertib/Show";
import CreateTataTertib from "../pages/Admin/TataTertib/Create";

export const customRoutesAdmin = [
  {
    name: "login-pengunjung",
    path: "/a/login-pengunjung",
    element: <LoginPengunjung />,
  },
  {
    name: "dashboard",
    path: "/a/dashboard",
    element: <Dashboard />,
  },
  {
    name: "pengguna-create",
    path: "/a/pengguna/create",
    element: <CreateAdmin />,
  },
  {
    name: "pengguna",
    path: "/a/pengguna",
    element: <ShowPengguna />,
  },
  {
    name: "saran",
    path: "/a/saran",
    element: <ShowFeedback />,
  },
  {
    name: "pengumuman-create",
    path: "/a/pengumuman/create",
    element: <CreatePengumuman />,
  },
  {
    name: "pengumuman-edit",
    path: "/a/pengumuman/*",
    element: <EditPengumuman />,
  },
  {
    name: "pengumuman",
    path: "/a/pengumuman",
    element: <ShowPengumuman />,
  },
  {
    name: "libur-create",
    path: "/a/libur/create",
    element: <CreateHariLibur />,
  },
  {
    name: "libur-edit",
    path: "/a/libur/*",
    element: <EditHariLibur />,
  },
  {
    name: "libur",
    path: "/a/libur",
    element: <ShowHariLibur />,
  },
  {
    name: "denah-create",
    path: "/a/denah/create",
    element: <CreateDenahPerpus />,
  },
  {
    name: "denah-edit",
    path: "/a/denah/*",
    element: <EditDenahPerpus />,
  },
  {
    name: "denah",
    path: "/a/denah",
    element: <ShowDenahPerpus />,
  },
  {
    name: "link-create",
    path: "/a/link/create",
    element: <CreateLinkPerpustakaan />,
  },
  {
    name: "link-edit",
    path: "/a/link/*",
    element: <EditLinkPerpustakaan />,
  },
  {
    name: "link",
    path: "/a/link",
    element: <ShowLinkPerpustakaan />,
  },
  {
    name: "kunci",
    path: "/a/kunci",
    element: <ShowKunciLoker />,
  },
  {
    name: "kunci-create",
    path: "/a/kunci/create",
    element: <CreateKunciLoker />,
  },
  {
    name: "notifikasi",
    path: "/a/notifikasi",
    element: <ShowNotifikasi />,
  },
  {
    name: "notifikasi-create",
    path: "/a/notifikasi/create",
    element: <CreateNotifikasi />,
  },
  {
    name: "koleksi-edit",
    path: "/a/koleksi/*",
    element: <EditBahanPustaka />,
  },
  {
    name: "koleksi-create",
    path: "/a/koleksi/create",
    element: <CreateBahanPustaka />,
  },
  {
    name: "koleksi",
    path: "/a/koleksi",
    element: <ShowBahanPustaka />,
  },
  {
    name: "peminjaman",
    path: "/a/peminjaman",
    element: <ShowPeminjaman />,
  },
  {
    name: "qrcode",
    path: "/a/qrcode",
    element: <ShowQrcode />,
  },
  {
    name: "rekomendasi",
    path: "/a/rekomendasi",
    element: <ShowRekomendasi />,
  },
  {
    name: "faq-edit",
    path: "/a/faq/*",
    element: <EditFAQ />,
  },
  {
    name: "faq-create",
    path: "/a/faq/create",
    element: <CreateFAQ />,
  },
  {
    name: "faq",
    path: "/a/faq",
    element: <ShowFAQ />,
  },
  {
    name: "tata-tertib",
    path: "/a/tata-tertib/create",
    element: <CreateTataTertib />,
  },
  {
    name: "tata-tertib",
    path: "/a/tata-tertib",
    element: <ShowTataTertib />,
  },
  {
    name: "statistik-pengunjung",
    path: "/a/statistik/pengunjung",
    element: <ShowStatistikPengunjung />,
  },
  {
    name: "statistik-peminjaman",
    path: "/a/statistik/peminjaman",
    element: <ShowStatistikPeminjaman />,
  },
];

export const customRoutes = [
  {
    name: "login",
    path: "/login",
    element: <Login />,
  },
  {
    name: "register",
    path: "/register",
    element: <Register />,
  },
  {
    name: "beranda",
    path: "/",
    element: <Beranda />,
  },

  // Tentang Kami
  {
    name: "tata tertib",
    path: "tata-tertib",
    element: <TataTertib />,
  },
  {
    name: "struktur organisasi",
    path: "str-org",
    element: <StrukturOrganisasi />,
  },
  // Fasilitas
  {
    name: "meja baca",
    path: "/baca",
    element: <MejaBaca />,
  },
  {
    name: "koleksi cetak",
    path: "/koleksi-cetak",
    element: <KoleksiCetak />,
  },
  {
    name: "koleksi ",
    path: "/koleksi-elektronik",
    element: <KoleksiElektronik />,
  },
  {
    name: "fasilitas lainnya",
    path: "/fasilitas-lainnya",
    element: <FasilitasLainnya />,
  },
  // Layanan
  {
    name: "pelayanan sirkulasi",
    path: "/pelayanan-sirkulasi",
    element: <PelayananSirkulasi />,
  },
  {
    name: "peminjaman bahan pustaka",
    path: "/peminjaman",
    element: <PeminjamanBahanPustaka />,
  },
  {
    name: "surat bebas perpustakaan",
    path: "/sbp",
    element: <SuratBebasPerpustakaan />,
  },
  // Koleksi
  {
    name: "koleksi bahan pustaka",
    path: "/koleksi/*",
    element: <KoleksiBahanPustaka />,
  },
  {
    name: "rekomendasi anda",
    path: "/rekomendasi",
    element: <RekomendasiAnda />,
  },
  {
    name: "riwayat pinjaman",
    path: "/riwayat-pinjaman",
    element: <RiwayatPeminjaman />,
  },
  {
    name: "riwayat baca",
    path: "/riwayat-baca",
    element: <RiwayatBaca />,
  },
  {
    name: "penanda koleksi",
    path: "/penanda-koleksi",
    element: <PenandaKoleksi />,
  },
  {
    name: "baca",
    path: "/koleksi/baca",
    element: <Reader />,
  },
  // {
  //   name:"baca ebook",
  //   path:"/baca-ebook",
  //   element:<BacaEBook/>,
  // },

  // Bantuan
  {
    name: "faq",
    path: "/faq",
    element: <Faq />,
  },
  {
    name: "kritik dan saran",
    path: "/kritik-saran",
    element: <KritikDanSaran />,
  },
  {
    name: "denah",
    path: "/denah/*",
    element: <Denah />,
  },
  {
    name: "success",
    path: "/auth/google/success",
    element: <GoogleLoginSuccess />,
  },
];
