import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  FormGroup,
  Chip,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import MenuContext from "../../context/MenuProvider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import color from "../../theme/Color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import Loading from '../Loading';
// import CheckboxChip from '../CheckboxChip';

const FormPenilaianDialog = (props) => {
  const { stateFormPenilaianDialog, setStateFormPenilaianDialog } =
    useContext(MenuContext);
  const { setMessage } = useContext(MessageContext);
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    setStateFormPenilaianDialog(false);
  };
  const handleClickOpen = () => {
    setStateFormPenilaianDialog(true);
  };

  const [komentar, setKomentar] = useState([]);

  const [state, setState] = useState({
    operasional: false,
    kenyamanan: false,
    koleksi: false,
    simpus: false,
    fasilitas: false,
    informasi: false,
    pelayanan: false,
  });

  const {
    operasional,
    kenyamanan,
    koleksi,
    simpus,
    fasilitas,
    informasi,
    pelayanan,
  } = state;

  const [rating, setRating] = useState(0);
  const [messageForm, setMessageForm] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event]: !prevState[event],
    }));
    if (state[event] === false) {
      setKomentar((prevState) => [...prevState, event]); //menambah
    } else {
      setKomentar((prevState) => prevState.filter((value) => value !== event)); //ngurangin
    }
  };

  const handleClickRating = (value) => {
    setRating(value);
  };

  useEffect(() => {
    console.log("state : ", state);
    console.log("rating : ", rating);
    console.log("komentar : ", komentar.join(","));
  }, [state, rating, komentar]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleSendRating = async () => {
    try {
      if (komentar.join(",") === "" || rating === 0) {
        setMessageForm("Form penilaian wajib diisi");
      } else {
        handleClose();
        setLoading(true);
        const response = await axiosJWT.post(
          `${process.env.REACT_APP_API_URL}/keluar`,
          {
            rating: rating,
            komentar: komentar.join(","),
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setLoading(false);
        setMessage({
          isOpen: true,
          content: response.data.message,
          severity: "success",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setMessage({
        isOpen: true,
        content: error.response.data.message,
        severity: "error",
      });
    }
  };

  const handleSendAndNavigate = () => {
    if (komentar.join(",") === "" || rating === 0) {
      setMessageForm("Form penilaian wajib diisi");
    } else {
      handleSendRating();
      navigate("/kritik-saran");
    }
  };

  return (
    <div>
      <Dialog open={stateFormPenilaianDialog} maxWidth="xs">
        <DialogTitle sx={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            color="success"
            sx={{
              fontSize: "56px",
            }}
          />
          <div>Logout Kunjungan Berhasil</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            Silahkan isi form penilaian sebelum meninggalkan perpustakaan
          </DialogContentText>
          <FormControl fullWidth="true" sx={{ marginTop: "16px" }}>
            {messageForm !== "" && (
              <Alert severity="error" sx={{ marginBottom: "5px" }}>
                {messageForm}
              </Alert>
            )}
            <FormLabel id="kunci-loker" sx={{ fontSize: "12px" }}>
              Nilai pengalamanmu menggunakan website ini
              <span style={{ color: red[500] }}> *</span>
            </FormLabel>
            <FormGroup
              row="true"
              sx={{ marginTop: "4px", marginLeft: "auto", marginRight: "auto" }}
            >
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon="fa-solid fa-face-angry"
                  size={sm ? "2x" : "3x"}
                  onClick={() => handleClickRating("1")}
                  style={{
                    margin: "4px",
                    color: rating == 1 ? color.yellow : color.grey,
                  }}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon="fa-solid fa-face-frown"
                  size={sm ? "2x" : "3x"}
                  onClick={() => handleClickRating("2")}
                  style={{
                    margin: "4px",
                    color: rating == 2 ? color.yellow : color.grey,
                  }}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon="fa-solid fa-face-meh"
                  size={sm ? "2x" : "3x"}
                  onClick={() => handleClickRating("3")}
                  style={{
                    margin: "4px",
                    color: rating == 3 ? color.yellow : color.grey,
                  }}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon="fa-solid fa-face-smile"
                  size={sm ? "2x" : "3x"}
                  onClick={() => handleClickRating("4")}
                  style={{
                    margin: "4px",
                    color: rating == 4 ? color.yellow : color.grey,
                  }}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon="fa-solid fa-face-grin-beam"
                  size={sm ? "2x" : "3x"}
                  onClick={() => handleClickRating("5")}
                  style={{
                    margin: "4px",
                    color: rating == 5 ? color.yellow : color.grey,
                  }}
                />
              </div>
            </FormGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            variant="standard"
            sx={{ marginTop: "16px" }}
          >
            <FormLabel component="legend" sx={{ fontSize: "12px" }}>
              Apa yang dapat kami tingkatkan?
              <span style={{ color: red[500] }}> *</span>
            </FormLabel>
            <FormGroup
              row="true"
              sx={{ marginTop: "4px", justifyContent: "center" }}
            >
              <Chip
                label={"Jam Operasional"}
                onClick={() => handleChange("operasional")}
                color={operasional ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
              <Chip
                label={"Kenyamanan"}
                onClick={() => handleChange("kenyamanan")}
                color={kenyamanan ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
              <Chip
                label={"Kelengkapan Koleksi"}
                onClick={() => handleChange("koleksi")}
                color={koleksi ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
              <Chip
                label={"SIMPus"}
                onClick={() => handleChange("simpus")}
                color={simpus ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
              <Chip
                label={"Fasilitas"}
                onClick={() => handleChange("fasilitas")}
                color={fasilitas ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
              <Chip
                label={"Kelengkapan Informasi"}
                onClick={() => handleChange("informasi")}
                color={informasi ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
              <Chip
                label={"Pelayanan Petugas"}
                onClick={() => handleChange("pelayanan")}
                color={pelayanan ? "success" : "default"}
                sx={{
                  width: "fit-content",
                  margin: "3px",
                }}
              />
            </FormGroup>
            {/* <FormHelperText>Pesan Peringatan :D</FormHelperText> */}
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ margin: "auto" }}>
          <Button
            variant="outlined"
            color="success"
            onClick={handleSendAndNavigate}
            autoFocus
          >
            Kirim &amp; Beri Saran
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSendRating}
            autoFocus
          >
            Kirim
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loading/>
      </Backdrop>
    </div>
  );
};

export default FormPenilaianDialog;
