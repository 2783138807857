import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import color from "../theme/Color";

const BasicBreadcrumbs = (props) => {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: color.light_white }}>
        <Typography>{props.menu}</Typography>
        <Typography>{props.submenu}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default BasicBreadcrumbs;
