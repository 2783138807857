import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuContext from "../../../context/MenuProvider";
import TokenContext from "../../../context/TokenProvider";
import color from "../../../theme/Color";

const menus = [
  {
    title: "FAQ",
    path: "/faq",
  },
  {
    title: "Kritik dan Saran",
    path: "/kritik-saran",
  },
  {
    title: "Denah",
    path: "/denah",
  },
];

const SubAppBarBantuan = () => {
  const navigate = useNavigate();
  const { stateAppBar, setStateAppBar} =
    useContext(MenuContext);
  const { accessToken } = useContext(TokenContext);

  const toggleSubAppBar = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateAppBar({ ...stateAppBar, [anchor]: open });
    if (path !== null) navigate(path);
  };

  const closeSubAppBar = () => {
    setStateAppBar({
      subAppBarTentangKami: false,
      subAppBarKoleksi: false,
      subAppBarBantuan: false,
    });
  };

  return (
    <Box
      sx={{
        paddingTop: "1em",
        paddingBottom: "1em",
        width: "100%",
        backgroundColor: color.white_transparent,
        backdropFilter: "blur(10px)",
        position: "fixed",
        top: "64px",
        right: "0",
        left: "0",
        zIndex: "10",
      }}
      onMouseOver={toggleSubAppBar("subAppBarBantuan", true, null)}
      onMouseOut={closeSubAppBar}
    >
      <Container maxWidth="lg">
        <Stack direction="row" spacing={12}>
          <Typography sx={{ width: "300px", marginTop: "10px" }}>
            Bantuan berupa ringkasan informasi pada FAQ, timbal balik pengguna
            pada kritik dan saran, dan denah ruangan perpustakaan
          </Typography>

          <List>
            {menus.map((menu, key) => (
              <ListItem key={key} disablePadding>
                <ListItemButton
                  onClick={toggleSubAppBar(
                    "subAppBarBantuan",
                    false,
                    menu.path
                  )}
                  sx={{
                    borderBottom: "1px solid rgb(230,230,230)",
                    borderLeft: "1px solid rgb(230,230,230)",
                  }}
                >
                  <ListItemText primary={menu.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Container>
    </Box>
  );
};

export default SubAppBarBantuan;
