import {
  Box,
  IconButton,
  Typography,
  Divider,
  Avatar,
  Drawer,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import "../css/Profil.css";
import MenuContext from "../context/MenuProvider";
import TokenContext from "../context/TokenProvider";
import MessageContext from "../context/MessageProvider";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ProfilScreenSmall = () => {
  const navigate = useNavigate();
  const { stateUserMenu, setStateUserMenu } = useContext(MenuContext);
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const [profil, setProfil] = useState(null);
  const [foto, setFoto] = useState("");
  const { message, setMessage } = useContext(MessageContext);

  useEffect(() => {
    getProfil();
    getFotoProfil();
  }, []);

  const getProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/profil`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setProfil(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getFotoProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/foto`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setFoto(response.data.gambar);
    } catch (error) {
      console.log(error.response);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateUserMenu({ [anchor]: open });
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/auth/logout`
      );
      setMessage({ isOpen: true, content: response.data.message });
      setAccessToken(null);
      navigate("/");
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  const handleAdmin = () => {
    navigate("/a/dashboard");
  };

  return (
    <Drawer
      anchor={"top"}
      open={stateUserMenu["profil"]}
      onClose={toggleDrawer("profil", false)}
    >
      <Box sx={{ backgroundColor: "white", height: "100vh" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "65px" }}
        >
          <Box sx={{ width: "40px", height: "40px" }}></Box>
          <Typography
            variant="h6"
            sx={{ marginTop: "5px", textAlign: "center" }}
          >
            PROFIL
          </Typography>
          <IconButton onClick={toggleDrawer("profil", false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <Box
          sx={
            {
              // paddingX: "100px",
            }
          }
        >
          <Avatar
            sx={{
              margin: "auto",
              marginTop: "2em",
              width: "100px",
              height: "100px",
            }}
            alt="Remy Sharp"
            src={foto}
          />
          <Typography
            variant="h6"
            sx={{ marginTop: "10px", textAlign: "center", fontWeight: "bold" }}
          >
            {profil !== null && profil.nama}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            {profil !== null && profil.email}
          </Typography>
          <Typography
            sx={{ fontWeight: "bold", margin: "18px auto", maxWidth: "240px" }}
          >
            Profil
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", margin: "16px auto" }}
          >
            <Table padding="none" sx={{ maxWidth: "240px", margin: "auto" }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", border: "none" }}>
                    Username
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px", border: "none" }}>
                    : {profil !== null && profil.username}
                  </TableCell>
                </TableRow>
              </TableBody>
              {profil && profil.inPerpus && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontSize: "16px", border: "none" }}>
                      Kunci Loker
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        border: "none",
                        minWidth: "160px",
                      }}
                    >
                      :{" "}
                      {profil.noKunci !== null
                        ? profil.noKunci
                        : "Tidak meminjam"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", border: "none" }}>
                    Kuota Unduh
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px", border: "none" }}>
                    : {profil !== null && profil.kuotaUnduh}
                    <BootstrapTooltip title="Kuota unduh merupakan batas baca E-Book dalam sehari">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          {decodedData.role === "admin" && (
            <Button
              variant="contained"
              onClick={handleAdmin}
              sx={{ margin: "8px 8px 24px" }}
            >
              Dashboard Administrasi
            </Button>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={handleLogout}
            sx={{ margin: "8px 8px 24px" }}
          >
            Log Out
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ProfilScreenSmall;
