import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LoginIcon from "@mui/icons-material/Login";
import DashboardIcon from "@mui/icons-material/Dashboard";
import KeyIcon from "@mui/icons-material/Key";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import GroupIcon from "@mui/icons-material/Group";
import BookIcon from "@mui/icons-material/Book";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import MapIcon from "@mui/icons-material/Map";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CampaignIcon from "@mui/icons-material/Campaign";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import QuizIcon from "@mui/icons-material/Quiz";
import GavelIcon from "@mui/icons-material/Gavel";
import { useNavigate } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import AdminSidebarContext from "../../../context/admin/SidebarProvider";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menu = [
  {
    name: "Login Pengunjung",
    icon: <LoginIcon />,
    path: "/a/login-pengunjung",
  },
  { name: "Dashboard", icon: <DashboardIcon />, path: "/a/dashboard" },
  { name: "Kunci Loker", icon: <KeyIcon />, path: "/a/kunci" },
  { name: "Peminjaman", icon: <BookIcon />, path: "/a/peminjaman" },
  { name: "Bahan Pustaka", icon: <LibraryBooksIcon />, path: "/a/koleksi" },
  { name: "Cetak QR Koleksi", icon: <QrCode2Icon />, path: "/a/qrcode" },
  { name: "Notifikasi", icon: <NotificationsIcon />, path: "/a/notifikasi" },
  { name: "Saran", icon: <FormatListBulletedIcon />, path: "/a/saran" },
  { name: "Pengguna", icon: <GroupIcon />, path: "/a/pengguna" },
];

const menuStatistik = [
  {
    name: "Pengunjung",
    icon: <GroupIcon />,
    path: "/a/statistik/pengunjung",
  },
  {
    name: "Peminjaman",
    icon: <BookIcon />,
    path: "/a/statistik/peminjaman",
  },
  {
    name: "Bahan Pustaka",
    icon: <LibraryBooksIcon />,
    path: null,
  },
];

const menuInformasi = [
  { name: "Pengumuman", icon: <CampaignIcon />, path: "/a/pengumuman" },
  { name: "Tanggal Libur", icon: <CalendarMonthIcon />, path: "/a/libur" },
  { name: "Link Perpustakaan", icon: <DatasetLinkedIcon />, path: "/a/link" },
  { name: "Denah Perpustakaan", icon: <MapIcon />, path: "/a/denah" },
  { name: "Rekomendasi", icon: <DescriptionIcon />, path: "/a/rekomendasi" },
  { name: "FAQ", icon: <QuizIcon />, path: "/a/faq" },
  { name: "Tata Tertib", icon: <GavelIcon />, path: "/a/tata-tertib" },
];

const AdminSidebar = () => {
  const navigate = useNavigate();
  const { adminSidebarOpen } = React.useContext(AdminSidebarContext);

  const changePage = (url) => {
    navigate(url);
  };
  return (
    <Drawer variant="permanent" open={adminSidebarOpen}>
      <Toolbar />
      <List>
        {menu.map(({ name, icon, path }) => (
          <ListItem key={name} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: adminSidebarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => changePage(path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: adminSidebarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={name}
                sx={{ opacity: adminSidebarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}

        <Divider />
        {adminSidebarOpen && (
          <Typography
            sx={{
              margin: "5px 13px 0",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Statistik
          </Typography>
        )}
        {menuStatistik.map(({ name, icon, path }) => (
          <ListItem key={name} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: adminSidebarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => changePage(path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: adminSidebarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={name}
                sx={{ opacity: adminSidebarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}

        <Divider />
        {adminSidebarOpen && (
          <Typography
            sx={{
              margin: "5px 13px 0",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Informasi Umum
          </Typography>
        )}
        {menuInformasi.map(({ name, icon, path }) => (
          <ListItem key={name} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: adminSidebarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => changePage(path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: adminSidebarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={name}
                sx={{ opacity: adminSidebarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
