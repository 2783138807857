import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { styled } from "@mui/material/styles";
import MessageContext from "../../../../context/MessageProvider";
import TokenContext from "../../../../context/TokenProvider";
import CustomPieChart from "../../../../components/admin/PieChart";
import fileDownload from "js-file-download";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PilihData = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const bulans = [
    { kode: "01", nama: "Januari" },
    { kode: "02", nama: "Februari" },
    { kode: "03", nama: "Maret" },
    { kode: "04", nama: "April" },
    { kode: "05", nama: "Mei" },
    { kode: "06", nama: "Juni" },
    { kode: "07", nama: "Juli" },
    { kode: "08", nama: "Agustus" },
    { kode: "09", nama: "September" },
    { kode: "10", nama: "Oktober" },
    { kode: "11", nama: "November" },
    { kode: "12", nama: "Desember" },
  ];
  const [pengunjung, setPengunjung] = useState(null);
  const [dataPieChart, setDataPieChart] = useState(null);
  const [dataTabel, setDataTabel] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [rentang, setRentang] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [bulan, setBulan] = useState("");
  const [tahun, setTahun] = useState("");

  useEffect(() => {
    setNavTo("/a/koleksi/create");
    refreshToken();
    if (rentang !== "") {
      if (tanggal !== "" || bulan !== "" || tahun !== "") {
        getStatistikPengunjung();
      }
    }
  }, [rentang, pengunjung, page, rowsPerPage]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRentang = (event) => {
    setRentang(event.target.value);
  };

  const handleChangeTanggal = (value) => {
    setTanggal(`${value.$y}-${value.$M + 1}-${value.$D}`);
  };

  const handleChangeBulan = (event) => {
    setBulan(event.target.value);
  };

  const handleChangeTahun = (event) => {
    setTahun(event.target.value);
  };

  const handleSubmitPilihData = async (e) => {
    e.preventDefault();
    try {
      let response;
      let query = `page=${page}&size=${rowsPerPage}`;

      switch (rentang) {
        case "harian":
          query += `&tanggal=${tanggal}`;
          break;
        case "bulanan":
          query += `&tahun=${tahun}&bulan=${bulan}`;
          break;
        case "tahunan":
          query += `&tahun=${tahun}`;
          break;
        default:
          return null;
      }

      response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/${rentang}?${query}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setPengunjung(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleClickVisualisasi = async (e) => {
    e.preventDefault();
    try {
      let responsePieChart, responseTabel;
      let query = ``;

      switch (rentang) {
        case "harian":
          query += `tanggal=${tanggal}`;
          break;
        case "bulanan":
          query += `tahun=${tahun}&bulan=${bulan}`;
          break;
        case "tahunan":
          query += `tahun=${tahun}`;
          break;
        default:
          return null;
      }

      responsePieChart = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/${rentang}/pie-chart?${query}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(`${process.env.REACT_APP_API_URL}/a/statistik/${rentang}/pie-chart?${query}`);

      responseTabel = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/${rentang}/tabel?${query}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setDataPieChart(responsePieChart.data);
      setDataTabel(responseTabel.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const getStatistikPengunjung = async () => {
    try {
      let response;
      let query = `page=${page}&size=${rowsPerPage}`;

      switch (rentang) {
        case "harian":
          query += `&tanggal=${tanggal}`;
          break;
        case "bulanan":
          query += `&tahun=${tahun}&bulan=${bulan}`;
          break;
        case "tahunan":
          query += `&tahun=${tahun}`;
          break;
        default:
          return null;
      }

      response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/${rentang}?${query}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setPengunjung(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const unduhFileExcelRekapPengunjung = async (e) => {
    try {
      e.preventDefault();
      let query = ``;

      switch (rentang) {
        case "harian":
          query += `tanggal=${tanggal}`;
          break;
        case "bulanan":
          query += `tahun=${tahun}&bulan=${bulan}`;
          break;
        case "tahunan":
          query += `tahun=${tahun}`;
          break;
        default:
          return null;
      }
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/${rentang}/excel?${query}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        }
      );

      fileDownload(response.data, `Rekap-Pengunjung.xlsx`);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
      }
    }
  };

  const calculateTotal = () => {
    let sum = 0;
    dataTabel.forEach((row) => {
      sum += row.jumlah;
    });
    return sum;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form
        onSubmit={handleSubmitPilihData}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormControl
          fullWidth
          sx={{ width: { xs: "100%", sm: "300px" }, marginBottom: "20px" }}
        >
          <InputLabel id="demo-simple-select-label">Pilih Rentang</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rentang}
            label="Pilih Rentang"
            onChange={handleChangeRentang}
          >
            <MenuItem value={"harian"}>Harian</MenuItem>
            <MenuItem value={"bulanan"}>Bulanan</MenuItem>
            <MenuItem value={"tahunan"}>Tahunan</MenuItem>
            <MenuItem value={"rentang"}>Isi Rentang</MenuItem>
          </Select>
        </FormControl>
        {rentang === "harian" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Tanggal Masuk"
              sx={{ width: { xs: "100%", sm: "300px" }, marginBottom: "20px" }}
              onChange={(newValue) => handleChangeTanggal(newValue)}
            />
          </LocalizationProvider>
        )}
        {(rentang === "bulanan" || rentang === "tahunan") && (
          <FormControl
            fullWidth
            sx={{ width: { xs: "100%", sm: "300px" }, marginBottom: "20px" }}
          >
            <InputLabel id="demo-simple-select-label">Pilih Tahun</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tahun}
              label="Pilih Tahun"
              onChange={handleChangeTahun}
              MenuProps={MenuProps}
            >
              <MenuItem value="2030">2030</MenuItem>
              <MenuItem value="2029">2029</MenuItem>
              <MenuItem value="2028">2028</MenuItem>
              <MenuItem value="2027">2027</MenuItem>
              <MenuItem value="2026">2026</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2020">2020</MenuItem>
              <MenuItem value="2019">2019</MenuItem>
            </Select>
          </FormControl>
        )}
        {rentang === "bulanan" && (
          <FormControl
            fullWidth
            sx={{ width: { xs: "100%", sm: "300px" }, marginBottom: "20px" }}
          >
            <InputLabel id="demo-simple-select-label">Pilih Bulan</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bulan}
              label="Pilih Bulan"
              onChange={handleChangeBulan}
              MenuProps={MenuProps}
            >
              {bulans.map((bulan) => (
                <MenuItem value={bulan.kode}>{bulan.nama}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Stack direction="row" columnGap={2}>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "fit-content" }}
          >
            Pilih
          </Button>
          {pengunjung !== null && (
            <Button
              variant="contained"
              onClick={unduhFileExcelRekapPengunjung}
              color="success"
            >
              Unduh Rekap
            </Button>
          )}
        </Stack>
      </form>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Username</StyledTableCell>
              <StyledTableCell align="center">nama</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Tanggal</StyledTableCell>
              <StyledTableCell align="center">Tujuan</StyledTableCell>
              <StyledTableCell align="center">Jam Datang</StyledTableCell>
              <StyledTableCell align="center">Jam Pulang</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pengunjung !== null &&
              pengunjung.rows.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell>{row.username}</StyledTableCell>
                  <StyledTableCell>{row.nama}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.tanggal}</StyledTableCell>
                  <StyledTableCell>{row.tujuan}</StyledTableCell>
                  <StyledTableCell>{row.jamDatang}</StyledTableCell>
                  <StyledTableCell>{row.jamPulang}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TablePagination
            count={pengunjung !== null ? pengunjung.count : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
      {pengunjung !== null && (
        <Button
          sx={{ margin: "20px 0 10px" }}
          variant="contained"
          onClick={handleClickVisualisasi}
        >
          Visualisasi
        </Button>
      )}
      {dataPieChart !== null && dataTabel !== null && (
        <>
          <Typography variant="h5">Visualisasi</Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "30px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                width: "fit-content",
                padding: "5px",
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgb(200,200,200)",
              }}
            >
              <Typography align="center">
                Jumlah Pengunjung berdasarkan Tujuan
              </Typography>
              <Box
                sx={{
                  width: "500px",
                  height: "350px",
                }}
              >
                <CustomPieChart data={dataPieChart} />
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{ height: "fit-content", width: "fit-content" }}
            >
              <Table sx={{ minWidth: 100 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center"></StyledTableCell>
                    <StyledTableCell align="center">Jumlah</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTabel.map((row, key) => 
                  (
                    <StyledTableRow key={key}>
                      <StyledTableCell>{row.kategori}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.jumlah}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow key={dataTabel.length+1}>
                      <StyledTableCell>Total</StyledTableCell>
                      <StyledTableCell align="center">
                        {calculateTotal()}
                      </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {}
    </Box>
  );
};

export default PilihData;
