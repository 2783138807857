import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { AdminLayout } from "../../../layout/admin";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import DialogContext from "../../../context/admin/DialogProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ShowLinkPerpustakaan = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setId, setType, setDeleteAlertDialogOpen } =
    useContext(DialogContext);
  const { setMessage } = useContext(MessageContext);
  const [linkPerpustakaan, setLinkPerpustakaan] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/a/link");
    refreshToken();
    getAllLinkPerpustakaan();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (id) => {
    navigate(`/a/link/${id}`);
  };

  const handleClickDelete = (id) => {
    setDeleteAlertDialogOpen(true);
    setId(id);
    setType("link");
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllLinkPerpustakaan = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/link?page=${page}&size=${rowsPerPage}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setLinkPerpustakaan(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate("/");
      }
    }
  };

  const changePage = () => {
    navigate("/a/link/create");
  };

  return (
    <AdminLayout>
      <Box sx={{ width: "100%" }}>
        <Button variant="contained" onClick={changePage}>
          <ControlPointIcon sx={{ marginRight: "10px" }} />
          Tambah
        </Button>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Nama</StyledTableCell>
                <StyledTableCell align="center">Link</StyledTableCell>
                <StyledTableCell align="center">Aksi</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linkPerpustakaan !== null &&
                linkPerpustakaan.rows.map((row, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell align="center">{row.nama}</StyledTableCell>
                    <StyledTableCell align="center">{row.link}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleClickEdit(row.id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleClickDelete(row.id)}
                      >
                        Hapus
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {linkPerpustakaan === null && (
                <>
                  <StyledTableRow key={1}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={2}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={3}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={4}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={5}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}
            </TableBody>
            <TablePagination
              count={linkPerpustakaan !== null ? linkPerpustakaan.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Box>
    </AdminLayout>
  );
};

export default ShowLinkPerpustakaan;
