import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../context/TokenProvider";
import MessageContext from "../context/MessageProvider";
import MenuContext from "../context/MenuProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RekomendasiDialog = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { id, setId } = useContext(MenuContext);
  const { setMessage } = useContext(MessageContext);
  const { rekomendasiDialog, setRekomendasiDialog } = useContext(MenuContext);
  const [buku, setBuku] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    getAllBuku();
  }, [id]);

  const handleClose = () => {
    setRekomendasiDialog(false);
  };

  const getAllBuku = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rekomendasi/buku/${id}`
      );
      setBuku(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
        navigate("/");
      }
    }
  };

  return (
    <Dialog
      open={rekomendasiDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogContent
        sx={{
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Kode</StyledTableCell>
                  <StyledTableCell align="center">Judul</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buku !== null &&
                  buku.rows.map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell align="center">
                        {row.kodeMatkulBuku}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.judul}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {buku === null && (
                  <>
                    <StyledTableRow key={1}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={2}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={3}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={4}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={5}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RekomendasiDialog;
