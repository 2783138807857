import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import MessageContext from "../../../context/MessageProvider";
import AdminSidebarContext from "../../../context/admin/SidebarProvider";
import TokenContext from "../../../context/TokenProvider";

const AdminAppBar = () => {
  const navigate = useNavigate();
  const { setAccessToken } = useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { adminSidebarOpen, setAdminSidebarOpen } =
    React.useContext(AdminSidebarContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePageToBeranda = () => {
    navigate("/");
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/auth/logout`
      );
      setMessage({ isOpen: true, content: response.data.message });
      setAccessToken(null);
      navigate("/");
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  const handleDrawerOpen = () => {
    setAdminSidebarOpen(!adminSidebarOpen);
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: 9999 }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Photos
        </Typography>
        {auth && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ zIndex: 9999 }}
            >
              <MenuItem onClick={handleChangePageToBeranda}>Beranda</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AdminAppBar;
