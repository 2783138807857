import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  Paper,
  TableCell,
  Skeleton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../../context/TokenProvider";
import MessageContext from "../../../../context/MessageProvider";
import { AdminLayout } from "../../../../layout/admin";
import CustomBarChart from "../../../../components/admin/BarChart";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ShowStatistikPeminjaman = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [statistikPeminjaman, setStatistikPeminjaman] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/a/statistik/peminjaman");
    refreshToken();
    getAllStatistikPeminjaman();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllStatistikPeminjaman = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/peminjaman?tahun=2023`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setStatistikPeminjaman(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: "100%" }}>
        <Stack direction="row" columnGap={5} sx={{ marginTop: "20px" }}>
          <TableContainer component={Paper} sx={{ width: "fit-content" }}>
            <Table sx={{ width: 250 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: "30px" }}>
                    Bulan
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "220px" }}>
                    Peminjaman Selesai
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistikPeminjaman !== null &&
                  statistikPeminjaman.map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell>{row.bulan}</StyledTableCell>
                      <StyledTableCell>{row.jumlah}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                {statistikPeminjaman === null && (
                  <>
                    <StyledTableRow key={1}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={2}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={3}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={4}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key={5}>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              padding: "5px",
              backgroundColor: "white",
              borderRadius: "5px",
              height: "fit-content",
              boxShadow: "0 2px 4px rgb(200,200,200)",
            }}
          >
            <Box
              sx={{
                minWidth: "500px",
                height: "300px",
              }}
            >
              <CustomBarChart data={statistikPeminjaman} />
            </Box>
          </Box>
        </Stack>
      </Box>
    </AdminLayout>
  );
};

export default ShowStatistikPeminjaman;
