import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { AdminLayout } from "../../../layout/admin";
import TokenContext from "../../../context/TokenProvider";
import DataTablePenggunaIntern from "./PenggunaIntern";
import DataTablePenggunaAdmin from "./PenggunaAdmin";
import DataTablePenggunaEkstern from "./PenggunaEkstern";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ShowPengguna = () => {
  const { setAccessToken, setDecodedData, setNavTo } = useContext(TokenContext);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setNavTo("/a/pengguna");
    refreshToken();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Mahasiswa dan Dosen" {...a11yProps(0)} />
            <Tab label="Admin" {...a11yProps(1)} />
            <Tab label="Pengunjung Luar" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DataTablePenggunaIntern />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataTablePenggunaAdmin />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DataTablePenggunaEkstern />
        </TabPanel>
      </Box>
    </AdminLayout>
  );
};

export default ShowPengguna;
