import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { useContext } from "react";
import MenuContext from "../context/MenuProvider";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import TokenContext from "../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../context/MessageProvider";

const KritikSaranDialog = (props) => {
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const { message, setMessage } = useContext(MessageContext);
  const [kategori, setKategori] = useState("");
  const [konten, setKonten] = useState("");
  const { stateKritikSaranDialog, setStateKritikSaranDialog } =
    useContext(MenuContext);

  const handleClose = () => {
    setStateKritikSaranDialog(false);
  };

  const handleChange = (event) => {
    setKategori(event.target.value);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const sendKritikSaran = async () => {
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/saran`,
        {
          kategori: kategori,
          konten: konten,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setMessage({ isOpen: true, content: response.data.message });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSendData = () => {
    sendKritikSaran();
    setStateKritikSaranDialog(false);
  };

  return (
    <Dialog open={stateKritikSaranDialog} onClose={handleClose}>
      <DialogTitle
        sx={{ fontWeight: "bold", fontSize: "16px", textAlign: "center" }}
      >
        Form Kritik dan Saran
      </DialogTitle>
      <DialogContent sx={{ width: "328px" }}>
        <FormControl fullWidth size="small">
          <Typography sx={{ fontSize: "12px" }}>Kategori</Typography>
          <Select
            value={kategori}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"1"}>Fasilitas</MenuItem>
            <MenuItem value={"2"}>Buku</MenuItem>
            <MenuItem value={"3"}>Pelayanan</MenuItem>
            <MenuItem value={"4"}>Sistem/Aplikasi</MenuItem>
            <MenuItem value={"5"}>Lainnya</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" sx={{ marginTop: "22px" }}>
          <Typography sx={{ fontSize: "12px" }}>Kritik dan Saran</Typography>
          <TextField
            multiline
            rows={4}
            value={konten}
            onChange={(e) => {
              setKonten(e.currentTarget.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ width: "fit-content", margin: "auto" }}>
        <Button variant="contained" onClick={handleSendData}>
          Kirim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KritikSaranDialog;
