export const layanan = [
  { 
    title: "Peminjaman Buku", 
    image: "./image/layanan/peminjaman_buku.png",
    path: "/peminjaman", 
  },
  { 
    title: "Koleksi Cetak", 
    image: "./image/layanan/koleksi_cetak.png",
    path: "/koleksi-cetak", 
  },
  {
    title: "Koleksi Elektronik",
    image: "./image/layanan/koleksi_elektronik.png",
    path: "/koleksi-elektronik",
  },
  {
    title: "Layanan Sirkulasi",
    image: "./image/layanan/layanan_sirkulasi.png",
    path: "/pelayanan-sirkulasi",
  },
  { 
    title: "Surat Bebas Perpustakaan", 
    image: "./image/layanan/sbp.png",
    path: "/sbp",
  },
  { 
    title: "Meja Baca", 
    image: "./image/layanan/meja_baca.png",
    path: "/baca",
  },
];

export const pengumuman = [
  {
    date: "27 Maret 2023",
    title: "Perilisan SIMPus versi Web",
    image: "",
    content:
      "SIMPus versi web kini telah tersedia dan dapat diakses melalui https://perpustakaan.stis.ac.id. Silahkan kunjungi laman tersebut dan rasakan pengalaman baru dalam menjelajahi berbagai informasi dan koleksi yang disediakan unit perpustakaan.",
  },
  {
    date: "27 Maret 2023",
    title: "Pembaharuan SIMPus dan SIMPus e-Reader",
    image: "",
    content:
      "Untuk mendapatkan fitur yang maksimal segera perbaharui SIMPus mu ke versi 2.3.0-beta6 dan SIMPus Reader ke versi 1.0-beta4.",
  },
  {
    date: "27 Maret 2023",
    title: "Skripsi Terbaru Tahun Ajaran 2020/2021",
    image: "",
    content:
      "Hallo Guys, kini skripsi terbaru tahun ajaran 2020-2021 sudah tersedia di SIMPus. Untuk membacanya pastikan install aplikasi SIMPus E-Reader di Playstore terlebih dahulu dan mulai tahun 2020, skripsi hanya tersedia dalam bentuk koleksi digital yang bisa dibaca melalui aplikasi SIMPus E-Reader. Selamat Membaca ^^.",
  },
];

export const faq = [
  {
    id: 1,
    title: "Bagaimana cara berkunjung di perpustakaan?",
    content:
      "Pengguna dapat melakukan scan kode QR pada komputer yang ada di meja sirkulasi. Setelah itu pastikan pengguna tidak membawa barang selain gawai/gadget berserta adaptornya. Jika membawa barang yang tidak diperkenankan masuk, pengguna dapat meminjam kunci loker dan meletakkan barang tersebut di loker yang tersedia. Info selengkapnya dapat dilihat di sini.",
  },
  {
    id: 2,
    title:
      "Apa saja yang boleh dibawa ketika berkunjung ke dalam perpustakaan?",
    content:
      "Pengguna hanya diperkenankan membawa gawai/gadget beserta adaptornya, selain itu pengguna diharapkan untuk menitipkan barangnya di loker perpustakaan.",
  },
  {
    id: 3,
    title: "Apa sanksi yang dikenakan jika kehilangan kunci loker?",
    content:
      "Pengguna akan dikenakan sanksi berupa denda sebesar Rp 50.000 atau menjadi relawan perpustakaan selama sehari.",
  },
  {
    id: 4,
    title: "Bagaimana cara meminjam buku?",
    content: "Jawaban pertanyaan 4",
  },
  {
    id: 5,
    title: "Berapa denda yang dibayarkan jika telat mengembalikan buku?",
    content: "Jawaban pertanyaan 5",
  },
  {
    id: 6,
    title: "Apa itu surat bebas perpustakaan?",
    content: "Jawaban pertanyaan 6",
  },
  {
    id: 7,
    title:
      "Apakah ada penerimaan volunteer/relawan untuk membantu perpustakaan?",
    content: "Jawaban pertanyaan 7",
  },
];
