import {
  Box,
  InputBase,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Container,
  Stack,
  TablePagination,
  Skeleton,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { MyLayout } from "../../layout";
import SearchIcon from "@mui/icons-material/Search";
import PenandaCard from "../../components/PenandaCard";
import KoleksiCard from "../../components/KoleksiCard";
import color from "../../theme/Color";
import { useContext, useEffect } from "react";
import TokenContext from "../../context/TokenProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuContext from "../../context/MenuProvider";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  right: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 0, 1, 1),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
  },
}));

const PenandaKoleksi = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const navigate = useNavigate();
  const { stateKoleksi } = useContext(MenuContext);
  const [penandaKoleksi, setPenandaKoleksi] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setNavTo("/penanda-koleksi");
    refreshToken();
    getPenandaKoleksi();
  }, [page, rowsPerPage]);

  useEffect(() => {
    // setNavTo("/penanda-koleksi");
    getPenandaKoleksi();
  }, [stateKoleksi]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getPenandaKoleksi = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/penanda-koleksi?page=${page}&size=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setPenandaKoleksi(response.data);
      // console.log(penandaKoleksi);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <MyLayout
      title={"Penanda Koleksi"}
      menu={"Koleksi"}
      submenu={"Penanda Koleksi"}
    >
      <Box
        sx={{
          backgroundColor: color.dark_white,
          marginTop: "-20px",
          paddingY: "20px",
        }}
      >
        <Container maxWidth="lg">
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Telusuri"
              size="small"
              color="primary"
              // value={keyword}
              // onKeyPress={handleEnterSubmit}
              // onChange={(e) => setKeyword(e.currentTarget.value)}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                },
                "&.Mui-focused": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                  // onClick={handleClickSubmit}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: color.light_gray }}>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: "40px", paddingBottom: "48px" }}
        >
          {penandaKoleksi !== null &&
            penandaKoleksi.rows.map((element, key) => (
              // <PenandaCard
              //   key={key}
              //   judul={element.judul}
              //   penulis={element.penulis}
              //   nama-jenis-pustaka={element.nama_jenis_pustaka}
              //   nama-klasifikasi={element.nama_klasifikasi}
              //   jumlah-dibaca={element.jumlah_dibaca}
              //   jumlah-disimpan={element.jumlah_disimpan}
              // />
              <KoleksiCard
                key={key}
                id={element.id}
                namaJenisPustaka={element.namaJenisPustaka}
                namaKlasifikasi={element.namaKlasifikasi}
                judul={element.judul}
                penulis={element.penulis}
                jumlahDibaca={element.jumlahDibaca}
                jumlahDisimpan={element.jumlahDisimpan}
                isDapatDipinjam={element.isDapatDipinjam}
                isDibaca={element.isDibaca}
              />
            ))}
          {penandaKoleksi === null && (
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ marginBottom: "40px" }}
                >
                  <Stack>
                    <Skeleton
                      variant="text"
                      width={250}
                      sx={{ fontSize: "12px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={261}
                      sx={{ fontSize: "16px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={220}
                      sx={{ fontSize: "14px" }}
                    />
                  </Stack>
                  <Stack alignItems="center">
                    <Skeleton
                      variant="text"
                      width={20}
                      sx={{ fontSize: "12px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={60}
                      sx={{ fontSize: "12px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={20}
                      sx={{ fontSize: "12px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={60}
                      sx={{ fontSize: "12px" }}
                    />
                  </Stack>
                </Stack>
              ))}
            </>
          )}
          <Stack direction="row" justifyContent="center">
            <TablePagination
              component="div"
              count={penandaKoleksi !== null ? penandaKoleksi.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default PenandaKoleksi;
