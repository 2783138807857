// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Box } from "@mui/material";

const ProsedurLogin = () => {
  return (
    <Box sx={{ width: "500px" }}>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        style={{ width: "100%" }}
      >
        <SwiperSlide>
          <img
            src="image/prosedur-kunjungan/langkah-1.png"
            alt="Langkah 1"
            style={{ height: "300px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="image/prosedur-kunjungan/langkah-2.png"
            alt="Langkah 2"
            style={{ height: "300px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="image/prosedur-kunjungan/langkah-3.png"
            alt="Langkah 3"
            style={{ height: "300px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="image/prosedur-kunjungan/langkah-4.png"
            alt="Langkah 4"
            style={{ height: "300px" }}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default ProsedurLogin;
