const color = {
  grey: "#E0E0E0",
  white_transparent: "rgba(255, 255, 255, 0.85)",
  dark_white: "#EEF6F8",
  white: "#F8F9FA",
  light_white: "#FFFFFF",
  light_gray: "#F9F9F9",
  dark_blue: "#023E8A", //primary
  dark_blue_30p : "rgba(2,62,138, 0.3)",
  dark_blue_50p : "rgba(2,62,138, 0.5)",
  blue: "#00B4D8",
  blue_7: "#0077B6",
  blue_3: "#90E0EF",
  light_blue: "#CAF0F8",
  yellow: "#FFCC4D",
  brown_yellowish: "#664500",
  // New Theme
  celestial_blue:"#0099E6",
  silver_lake_blue:"#7690B0",
  ut_orange:"#FF8D28", //secondary
  cafe_noir:"#4B3015",
  black:"#20706",
};

export default color;