import {
  CardMedia,
  Typography,
  Box,
  Link,
  Container,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper
} from "@mui/material";
import React, { useState } from "react";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";

const stepsKunjungan = [
  {
    label: 'Login menggunakan SSO google',
    description: `Klik tombol "Masuk" pada AppBar di atas. Kemudian, pilih "Sign in with Google".
                  Pilih akun STIS, lalu tunggu sampai login berhasil. Pengunjung luar Poiiteknik
                  Statistika STIS dapat melakukan register dan menggunakan akun pribadi mereka, namun
                  beberapa akses pada layanan tertentu dibatasi.`,
  },
  {
    label: 'Melakukan scan kode QR atau masukkan kode kunjungan',
    description: `Klik tombol "scan" pada ujung kanan bawah. Klik tombol "Request camera permission".
                  Pilih kamera belakang, lalu klik "Start Scanning". Scan kode QR yang ada di meja 
                  resepsionis/meja sirkulasi. Jika gagal melakukan scan, sebagai alternatif pengguna
                  dapat memasukkan kode 6 karakter yang ada di bawah kode QR`
    ,
  },
  {
    label: 'Isi form kunjungan',
    description: `Pilih tujuan berkunjung dan peminjaman kunci loker. Nomor kunci loker akan diperlihatkan 
                  setelah form terkirim. Pengguna juga dapat melihat nomor kunci loker pada profil.`,
  },
];

const stepsKeluar = [
  {
    label: 'Melakukan scan kode QR atau masukkan kode kunjungan',
    description: `Klik tombol "scan" pada ujung kanan bawah. Klik tombol "Request camera permission".
                  Pilih kamera belakang, lalu klik "Start Scanning". Scan kode QR yang ada di meja 
                  resepsionis/meja sirkulasi. Jika gagal melakukan scan, sebagai alternatif pengguna
                  dapat memasukkan kode 6 karakter yang ada di bawah kode QR`
    ,
  },
  {
    label: 'Isi form penilaian',
    description: `Nilai aplikasi dan pilih kategori yang dapat ditingkatkan perpustakaan. 
                  Klik "Kirim" atau "Kirim dan Beri Saran" jika ingin memberikan kritik dan saran
                  yang lebih rinci.`,
  },
];

const PelayananSirkulasi = () => {
  const [langkahKunjungan, setLangkahKunjungan] = useState(0);
  const [langkahKeluar, setLangkahKeluar] = useState(0);

  const handleNext = (jenis) => {
    if(jenis==="kunjungan"){
      setLangkahKunjungan((prevActiveStep) => prevActiveStep + 1);
    } else {
      setLangkahKeluar((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = (jenis) => {
    if(jenis==="kunjungan"){
      setLangkahKunjungan((prevActiveStep) => prevActiveStep - 1);
    } else {
      setLangkahKeluar((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = (jenis) => {
    if(jenis==="kunjungan"){
      setLangkahKunjungan(0);
    } else {
      setLangkahKeluar(0);
    }
  };

  return (
    <MyLayout title={"Pelayanan Sirkulasi"} menu={"Tentang Kami"} submenu={"Pelayanan Sirkulasi"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Absensi Kunjungan
              </Typography>
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Untuk melakukan absensi kunjungan, pengguna dapat 
                menekan tombol melayang scan pada pojok kanan bawah.
                Kemudian melakukan scan atau memasukkan kode kunjungan
                jika tidak bisa melakukan scan.
              </Typography>
              <Typography sx={{ fontSize: "14px", marginTop: "8px" }}>
                Berikut ini merupakan panduan absensi kunjungan :
                <Box>
                  <Stepper activeStep={langkahKunjungan} orientation="vertical">
                    {stepsKunjungan.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel
                          optional={
                            index === 2 ? (
                              <Typography variant="caption" sx={{fontSize: "14px", marginTop: "8px"}}>Langkah terakhir</Typography>
                            ) : null
                          }
                        >
                          {step.label}
                        </StepLabel>
                        <StepContent>
                          <Typography align="justify" sx={{fontSize: "14px", marginTop: "8px"}}>{step.description}</Typography>
                          <Box sx={{ mb: 2 }}>
                            <div>
                              <Button
                                variant="contained"
                                onClick={()=>handleNext("kunjungan")}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                {index === stepsKunjungan.length - 1 ? 'Selesai' : 'Selanjutnya'}
                              </Button>
                              <Button
                                disabled={index === 0}
                                onClick={()=>handleBack("kunjungan")}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Kembali
                              </Button>
                            </div>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  {langkahKunjungan === stepsKunjungan.length && (
                    <Paper square elevation={0} sx={{ p: 3, backgroundColor: "rgba(0,0,0,0)" }}>
                      <Typography sx={{fontSize: "14px"}}>Panduan absensi kunjungan telah selesai</Typography>
                      <Button onClick={()=>handleReset("kunjungan")} sx={{ mt: 1, mr: 1 }}>
                        Ulangi
                      </Button>
                    </Paper>
                  )}
                </Box>
              </Typography>
              <Typography align="justify" sx={{ fontSize: "14px", marginTop: "8px" }}>
                Setelah selesai berkunjung, pengguna melakukan absensi lagi untuk pencatatan keluar kunjungan.
                Berikut ini merupakan panduan keluar kunjungan :
                <Box>
                  <Stepper activeStep={langkahKeluar} orientation="vertical">
                    {stepsKeluar.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel
                          optional={
                            index === 2 ? (
                              <Typography variant="caption" sx={{fontSize: "14px", marginTop: "8px"}}>Last step</Typography>
                            ) : null
                          }
                        >
                          {step.label}
                        </StepLabel>
                        <StepContent>
                          <Typography align="justify"  sx={{fontSize: "14px", marginTop: "8px"}}>{step.description}</Typography>
                          <Box sx={{ mb: 2 }}>
                            <div>
                              <Button
                                variant="contained"
                                onClick={()=>handleNext("keluar")}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                {index === stepsKeluar.length - 1 ? 'Selesai' : 'Selanjutnya'}
                              </Button>
                              <Button
                                disabled={index === 0}
                                onClick={()=>handleBack("keluar")}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Kembali
                              </Button>
                            </div>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  {langkahKeluar === stepsKeluar.length && (
                    <Paper square elevation={0} sx={{ p: 3, backgroundColor: "rgba(0,0,0,0)" }}>
                      <Typography sx={{fontSize: "14px"}}>Panduan keluar kunjungan telah selesai</Typography>
                      <Button onClick={()=>handleReset("keluar")} sx={{ mt: 1, mr: 1 }}>
                        Ulangi
                      </Button>
                    </Paper>
                  )}
                </Box>
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Peminjaman Kunci loker
              </Typography>
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Setelah melakukan absensi, tentukan tujuan berkunjung dan
                peminjaman loker. Jika Anda membawa barang yang tidak
                diperkenankan untuk dibawa masuk ke dalam perpus, lakukanlah 
                peminjaman loker. Nomor kunci loker terlihat pada badge profil.
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Peminjaman Bahan Pustaka
              </Typography>
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Anggota perpustakaan dapat meminjam 3 jenis bahan perpustakaan,
                yaitu <b>buku, publikasi, dan kamus bahasa inggris</b>. Untuk
                selengkapnya dapat dilihat <Link href="/peminjaman">di sini</Link>.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Layanan"}
              submenu-is-active={"Pelayanan Sirkulasi"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default PelayananSirkulasi;
