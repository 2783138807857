import React, { useContext, useEffect, useState } from "react";
import { Box, CssBaseline, Stack, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import MessageContext from "../../context/MessageProvider";
import TokenContext from "../../context/TokenProvider";
import ProsedurLogin from "./ProsedurLogin";

const LoginPengunjung = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [qrcode, setQrcode] = useState(null);

  useEffect(() => {
    setNavTo("/a/login-pengunjung");
    refreshToken();
    const interval = setInterval(() => {
      getQrcode();
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getQrcode = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/qrcode-kunjungan`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setQrcode(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
        navigate("/");
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          paddingY: "5vh",
          display: "grid",
          placeItems: "center",
          backgroundImage: "url(./image/homepage_background.png)",
          background:
            "linear-gradient(rgb(2,62,138,0.8), rgb(0, 180, 216,0.8)), url(./image/homepage_background.png)",
        }}
      >
        <Box
          sx={{
            width: { sm: "90%", md: "80%" },
            height: "fit-content",
            padding: "40px",
            display: "grid",
            gridTemplateRows: "1fr 3fr",
            borderRadius: "40px",
            backgroundColor: "#ffffff",
            border: "1px solid rgba(128, 128, 128, 0.5)",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: "bolder", textAlign: "center" }}
          >
            Selamat Datang di Perpustakaan <br /> Politeknik Statistika STIS
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={3}
            >
              {qrcode !== null && (
                <QRCode
                  style={{
                    width: "fit-content",
                    height: "60%",
                    padding: "10px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                  }}
                  value={qrcode.token}
                />
              )}
              {qrcode !== null && (
                <Typography
                  variant="h6"
                  sx={{
                    paddingX: "10px",
                    paddingY: "5px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                    fontWeight: "bolder",
                  }}
                >
                  {qrcode.token}
                </Typography>
              )}
            </Stack>
            {/* <Box sx={{ backgroundColor: "red" }}>
              <ProsedurLogin />
            </Box> */}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LoginPengunjung;
