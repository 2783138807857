import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Skeleton } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { AdminLayout } from "../../../layout/admin";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import DraggableObject from "../../../components/admin/DraggableObject";

const ShowDenahPerpus = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [objekDenah, setObjekDenah] = useState(null);

  useEffect(() => {
    setNavTo("/a/denah");
    refreshToken();
    getAllObjekDenahPerpus();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const changePage = () => {
    navigate("/a/denah/create");
  };

  const getAllObjekDenahPerpus = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/denah`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setObjekDenah(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };
  return (
    <AdminLayout>
      <Box sx={{ width: "100%" }}>
        <Button variant="contained" onClick={changePage}>
          <ControlPointIcon sx={{ marginRight: "10px" }} />
          Tambah
        </Button>
        <Box
          sx={{
            width: "915px",
            height: "574px",
            marginTop: "20px",
            position: "relative",
            border: "2px solid black",
          }}
        >
          {objekDenah !== null &&
            objekDenah.rows.map((objek, key) => (
              <DraggableObject key={key} objek={objek} />
            ))}
          {objekDenah === null && <Skeleton width={915} height={574} />}
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default ShowDenahPerpus;
