import { useContext, useEffect, useState } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContext from "../../context/admin/DialogProvider";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import {
  Box,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UbahKunciDialog = () => {
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const { idPengunjung, ubahKunciDialogOpen, setUbahKunciDialogOpen } =
    useContext(DialogContext);
  const [pengunjung, setPengunjung] = useState(null);
  const [kunciLokerTersedia, setKunciLokerTersedia] = useState(null);
  const [kunciLoker, setKunciLoker] = useState("");

  useEffect(() => {
    if (idPengunjung) {
      getDetailPengunjung();
      getAllKunciLokerTersedia();
    }
  }, [ubahKunciDialogOpen]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailPengunjung = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/pengunjung/${idPengunjung}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setPengunjung(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        setUbahKunciDialogOpen(false);
      }
    }
  };

  const getAllKunciLokerTersedia = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/kunci?status=tersedia&page=0&size=200`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setKunciLokerTersedia(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        setUbahKunciDialogOpen(false);
      }
    }
  };

  const handleClose = () => {
    setUbahKunciDialogOpen(false);
  };

  const handleChangeKunciLoker = (event) => {
    setKunciLoker(event.target.value);
  };

  const handleCloseAndUbahKunciPengunjung = async () => {
    try {
      const response = await axiosJWT.put(
        `${process.env.REACT_APP_API_URL}/a/pengunjung/${idPengunjung}`,
        { uuidKunci: kunciLoker },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      setUbahKunciDialogOpen(false);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <Dialog
      open={ubahKunciDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" align="center">
        Ubah Kunci
      </DialogTitle>
      <DialogContent>
        {pengunjung !== null && (
          <>
            <Stack direction="row" columnGap={2} sx={{ marginBottom: "10px" }}>
              <img
                src={`https://stis.ac.id/sipadu/mahasiswa/foto_mhs/${pengunjung.username}.jpg`}
                alt={`${pengunjung.username}.jpg`}
                width="150"
              />
              <Box>
                <Typography>NIM : {pengunjung.username}</Typography>
                <Typography>NAMA : {pengunjung.nama}</Typography>
                <Typography>NO KUNCI: {pengunjung.noKunci}</Typography>
              </Box>
            </Stack>
            <FormControl fullWidth sx={{ width: "100%", marginTop: "10px" }}>
              <InputLabel id="demo-simple-select-label">Kunci Loker</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={kunciLoker}
                label="Jenis Pustaka"
                onChange={handleChangeKunciLoker}
                MenuProps={MenuProps}
              >
                {kunciLokerTersedia !== null &&
                  kunciLokerTersedia.rows.map((kunciLoker) => (
                    <MenuItem value={kunciLoker.uuid}>
                      {kunciLoker.nomor}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Typography>
              Apakah Anda yakin ingin melanjutkan aksi ini?
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Tidak
        </Button>
        <Button
          onClick={handleCloseAndUbahKunciPengunjung}
          variant="contained"
          autoFocus
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UbahKunciDialog;
