import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Stack } from "@mui/material";
import { useContext } from "react";
import MenuContext from "../context/MenuProvider";
import color from "../theme/Color";
import KoleksiContext from "../context/KoleksiProvider";

const KoleksiCard = (props) => {
  const { setStateKoleksi } = useContext(MenuContext);
  const { setIdKoleksi } = useContext(KoleksiContext);

  const toggleDrawer = (open, id) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIdKoleksi(id);
    setStateKoleksi(open);
  };

  return (
    <Card
      sx={{
        backgroundColor: color.light_gray,
        width: "100%",
        margin: "auto",
        marginBottom: "40px",
        boxShadow: "none",
        ...props.sx,
      }}
      onClick={toggleDrawer(true, props.id)}
    >
      <CardActionArea>
        <CardContent sx={{ padding: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <Typography color="InactiveCaptionText" sx={{ fontSize: "12px" }}>
                {props["namaJenisPustaka"]}, {props["namaKlasifikasi"]}{" "}
                { (props.namaJenisPustaka === "Buku" || props.namaJenisPustaka === "Publikasi") && (
                  <span style={{ color: "green" }}>
                    Dapat Dipinjam
                  </span>
                )}
                { props.isDibaca && (
                  <span style={{ color: "red" }}>
                    PDF
                  </span>
                )}
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>{props.judul}</Typography>
              <Typography sx={{ fontSize: "14px" }}>{props.penulis}</Typography>
            </Stack>
            <Stack textAlign="center">
              <Typography sx={{ fontSize: "12px" }}>
                {props["jumlahDibaca"]}
              </Typography>
              <Typography color="InactiveCaptionText" sx={{ fontSize: "12px" }}>
                dibaca
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {props["jumlahDisimpan"]}
              </Typography>
              <Typography color="InactiveCaptionText" sx={{ fontSize: "12px" }}>
                disimpan
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default KoleksiCard;
