import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import DialogContext from "../../../context/admin/DialogProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DataTablePermintaan = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setIdPeminjaman, setKonfirmasiPeminjamanDialogOpen } =
    useContext(DialogContext);
  const { message, setMessage } = useContext(MessageContext);
  const { setId, setType, setDeleteAlertDialogOpen } =
    useContext(DialogContext);
  const [permintaan, setPermintaan] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/a/peminjaman");
    refreshToken();
    getAllPermintaan();
  }, [message, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickDelete = (uuid) => {
    setDeleteAlertDialogOpen(true);
    setId(uuid);
    setType("peminjaman");
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const openDialogKonfirmasiPeminjaman = (uuid) => {
    setIdPeminjaman(uuid);
    setKonfirmasiPeminjamanDialogOpen(true);
  };

  const getAllPermintaan = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/permintaan?page=${page}&size=${rowsPerPage}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setPermintaan(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nama</StyledTableCell>
              <StyledTableCell>No Induk</StyledTableCell>
              <StyledTableCell>Judul</StyledTableCell>
              <StyledTableCell>No Eks</StyledTableCell>
              <StyledTableCell align="center">Aksi</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permintaan !== null &&
              permintaan.rows.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell>{row.nama}</StyledTableCell>
                  <StyledTableCell>{row.noInduk}</StyledTableCell>
                  <StyledTableCell>{row.judul}</StyledTableCell>
                  <StyledTableCell>{row.noEks}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => openDialogKonfirmasiPeminjaman(row.id)}
                    >
                      Konfirmasi
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleClickDelete(row.id)}
                    >
                      Tolak
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {permintaan === null && (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
          <TablePagination
            count={permintaan !== null ? permintaan.count : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTablePermintaan;
