import React from "react";
import {
  Box,
  ListItemText,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Drawer,
  Typography,
  Stack,
} from "@mui/material";
import "../../css/SideBar.css";
import { useContext } from "react";
import MenuContext from "../../context/MenuProvider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const menus = [
  {
    title: "Beranda",
    path: "/",
    anchor: "",
  },
  {
    title: "Tentang Kami",
    path: "",
    anchor: "subSideBarTentangKami",
  },
  {
    title: "Koleksi",
    path: "",
    anchor: "subSideBarKoleksi",
  },
  {
    title: "Bantuan",
    path: "",
    anchor: "subSideBarBantuan",
  },
];

const MySideBar = () => {
  const navigate = useNavigate();
  const { state, setState } = useContext(MenuContext);

  useEffect(() => {}, [state]);

  const toggleDrawer = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open, mainSideBar: false });
    if (path !== null) navigate(path);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <Typography sx={{ textAlign: "center", paddingY: "10px" }}>
        MENU
      </Typography>
      <Divider />
      <List>
        {menus.map((menu, key) => (
          <ListItem key={key} disablePadding>
            <ListItemButton
              onClick={
                menu.title === "Beranda"
                  ? toggleDrawer("mainSideBar", false, menu.path)
                  : toggleDrawer(menu.anchor, true, null)
              }
            >
              {menu.title === "Beranda" && (
                <ListItemText primary={menu.title} />
              )}
              {menu.title !== "Beranda" && (
                <>
                  <ListItemText primary={menu.title} />
                  <Stack>
                    <ArrowForwardIosIcon />
                  </Stack>
                </>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"left"}
      open={state["mainSideBar"]}
      onClose={toggleDrawer("mainSideBar", false, null)}
    >
      {list()}
    </Drawer>
  );
};

export default MySideBar;
