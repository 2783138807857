import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";
import { AdminLayout } from "../../../layout/admin";

const EditBahanPustaka = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [allKlasifikasi, setAllKlasifikasi] = useState(null);
  const [bahanPustaka, setBahanPustaka] = useState(null);
  const [jenisPustaka, setJenisPustaka] = useState("");
  const [noInduk, setNoInduk] = useState("");
  const [klasifikasi, setKlasifikasi] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [judul, setJudul] = useState("");
  const [penulis, setPenulis] = useState("");
  const [tahunTerbit, setTahunTerbit] = useState("");
  const [kolasi, setKolasi] = useState("");
  const [kodeRak, setKodeRak] = useState("");
  const [tanggalMasuk, setTanggalMasuk] = useState("");
  const [infoTambahan, setInfoTambahan] = useState({
    penerbit: "",
    sumber: "",
    cetakan: "",
    edisi: "",
    jilid: "",
    isbn: "",
    no_eks: "",
    harga: "",
    issn: "",
    volume: "",
    no_publikasi: "",
    katalog_publikasi: "",
    keterangan: "",
    bulan_terbit: "",
    kode_klasifikasi: "",
  });

  useEffect(() => {
    setNavTo("/a/koleksi");
    getDetailBahanPustaka();
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllKlasifikasiByJenisPustaka = async (idJenisPustaka) => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/klasifikasi?jenis-pustaka=${idJenisPustaka}`
      );
      setAllKlasifikasi(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleChangeJenisPustaka = (event) => {
    setJenisPustaka(event.target.value);
  };

  const handleChangeNoInduk = (event) => {
    setNoInduk(event.target.value);
  };

  const handleChangeQrcode = (event) => {
    setQrcode(event.target.value);
  };

  const handleChangeJudul = (event) => {
    setJudul(event.target.value);
  };

  const handleChangePenulis = (event) => {
    setPenulis(event.target.value);
  };

  const handleChangeKolasi = (event) => {
    setKolasi(event.target.value);
  };

  const handleChangeKodeRak = (event) => {
    setKodeRak(event.target.value);
  };

  const handleChangeTahunTerbit = (event) => {
    setTahunTerbit(event.target.value);
  };

  const handleChangeTanggalMasuk = (value) => {
    setTanggalMasuk(`${value.$y}-${value.$M + 1}-${value.$D}`);
  };

  const handleChangePenerbit = (event) => {
    setInfoTambahan({ ...infoTambahan, penerbit: event.target.value });
  };

  const handleChangeSumber = (event) => {
    setInfoTambahan({ ...infoTambahan, sumber: event.target.value });
  };

  const handleChangeCetakan = (event) => {
    setInfoTambahan({ ...infoTambahan, cetakan: event.target.value });
  };

  const handleChangeEdisi = (event) => {
    setInfoTambahan({ ...infoTambahan, edisi: event.target.value });
  };

  const handleChangeJilid = (event) => {
    setInfoTambahan({ ...infoTambahan, jilid: event.target.value });
  };

  const handleChangeIsbn = (event) => {
    setInfoTambahan({ ...infoTambahan, isbn: event.target.value });
  };

  const handleChangeNoEks = (event) => {
    setInfoTambahan({ ...infoTambahan, no_eks: event.target.value });
  };

  const handleChangeHarga = (event) => {
    setInfoTambahan({ ...infoTambahan, harga: event.target.value });
  };

  const handleChangeIssn = (event) => {
    setInfoTambahan({ ...infoTambahan, issn: event.target.value });
  };

  const handleChangeVolume = (event) => {
    setInfoTambahan({ ...infoTambahan, volume: event.target.value });
  };

  const handleChangeNoPublikasi = (event) => {
    setInfoTambahan({ ...infoTambahan, no_publikasi: event.target.value });
  };

  const handleChangeKatalogPublikasi = (event) => {
    setInfoTambahan({ ...infoTambahan, katalog_publikasi: event.target.value });
  };

  const handleChangeKeterangan = (event) => {
    setInfoTambahan({ ...infoTambahan, keterangan: event.target.value });
  };

  const handleChangeBulanTerbit = (event) => {
    setInfoTambahan({ ...infoTambahan, bulan_terbit: event.target.value });
  };

  const handleChangeKodeKlasifikasi = (event) => {
    setInfoTambahan({ ...infoTambahan, kode_klasifikasi: event.target.value });
  };

  const getDetailBahanPustaka = async () => {
    try {
      const url = window.location.pathname.split("/");
      const id = url[url.length - 1];
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/koleksi/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setBahanPustaka(response.data);
      setNoInduk(response.data.noInduk);
      setJenisPustaka(response.data.jenisPustaka);
      getAllKlasifikasiByJenisPustaka(response.data.jenisPustaka);
      setKlasifikasi(response.data.klasifikasi);
      setQrcode(response.data.qrcode);
      setJudul(response.data.judul);
      setPenulis(response.data.penulis);
      setTahunTerbit(dayjs(response.data.tahunTerbit));
      setKolasi(response.data.kolasi);
      setKodeRak(response.data.kodeRak);
      setTanggalMasuk(dayjs(response.data.tanggalMasuk));
      setInfoTambahan(response.data.infoTambahan);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
      }
    }
  };

  const handleSubmitBahanPustaka = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.put(
        `${process.env.REACT_APP_API_URL}/a/koleksi/${bahanPustaka.id}`,
        {
          noInduk: noInduk,
          jenisPustaka: jenisPustaka,
          klasifikasi: klasifikasi,
          qrcode: qrcode,
          judul: judul,
          penulis: penulis,
          tahunTerbit: tahunTerbit,
          kolasi: kolasi,
          kodeRak: kodeRak,
          tanggalMasuk: tanggalMasuk,
          infoTambahan: infoTambahan,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      console.log(klasifikasi);
      navigate("/a/koleksi");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: "100%" }}>
        <form onSubmit={handleSubmitBahanPustaka}>
          {bahanPustaka !== null ? (
            <FormControl fullWidth sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                Jenis Pustaka
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={bahanPustaka.jenisPustaka}
                label="Jenis Pustaka"
                onChange={handleChangeJenisPustaka}
              >
                <MenuItem value={"Skripsi"}>Skripsi</MenuItem>
                <MenuItem value={"Buku"}>Buku</MenuItem>
                <MenuItem value={"Jurnal"}>Jurnal</MenuItem>
                <MenuItem value={"Publikasi"}>Publikasi</MenuItem>
                <MenuItem value={"Multimedia"}>Multimedia</MenuItem>
                <MenuItem value={"Terbitan Berkala"}>Terbitan Berkala</MenuItem>
                <MenuItem value={"Karya Ilmiah"}>Karya Ilmiah</MenuItem>
                <MenuItem value={"Laporan"}>Laporan</MenuItem>
                <MenuItem value={"EBook"}>EBook</MenuItem>
                <MenuItem value={"EJurnal"}>EJurnal</MenuItem>
                <MenuItem value={"Jurnal Internasional"}>
                  Jurnal Internasional
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.noInduk}
              label="No Induk"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeNoInduk}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.judul}
              label="Judul"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeJudul}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.penulis}
              label="Penulis"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangePenulis}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.tahunTerbit}
              label="Tahun Terbit"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeTahunTerbit}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.kolasi}
              label="Kolasi"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeKolasi}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.infoTambahan.kode_klasifikasi}
              label="Kode Klasifikasi"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeKodeKlasifikasi}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allKlasifikasi !== null ? allKlasifikasi : []}
              sx={{ width: "100%", marginBottom: "20px" }}
              renderInput={(params) => (
                <TextField {...params} label="Klasifikasi" />
              )}
              value={klasifikasi}
              onInputChange={(event, newInputValue) => {
                setKlasifikasi(newInputValue);
              }}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.kodeRak}
              label="Kode Rak"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeKodeRak}
            />
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Tanggal Masuk"
                defaultValue={dayjs(bahanPustaka.tanggalMasuk)}
                sx={{ width: "100%", marginBottom: "20px" }}
                onChange={(newValue) => handleChangeTanggalMasuk(newValue)}
              />
            </LocalizationProvider>
          ) : (
            <Skeleton height={60} />
          )}

          {bahanPustaka !== null ? (
            <TextField
              id="outlined-basic"
              defaultValue={bahanPustaka.qrcode}
              label="QR Code"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeQrcode}
            />
          ) : (
            <Skeleton height={60} />
          )}
          <Typography sx={{ marginBottom: "20px" }}>Info Tambahan</Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: "20px",
              marginBottom: "20px",
            }}
          >
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.penerbit}
                label="Penerbit"
                variant="outlined"
                onChange={handleChangePenerbit}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.sumber}
                label="Sumber"
                variant="outlined"
                onChange={handleChangeSumber}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.cetakan}
                label="Cetakan"
                variant="outlined"
                onChange={handleChangeCetakan}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.edisi}
                label="Edisi"
                variant="outlined"
                onChange={handleChangeEdisi}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.jilid}
                label="Jilid"
                variant="outlined"
                onChange={handleChangeJilid}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.isbn}
                label="ISBN"
                variant="outlined"
                onChange={handleChangeIsbn}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.no_eks}
                label="No Eksemplar"
                variant="outlined"
                onChange={handleChangeNoEks}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.harga}
                label="Harga"
                variant="outlined"
                onChange={handleChangeHarga}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.issn}
                label="ISSN"
                variant="outlined"
                onChange={handleChangeIssn}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.volume}
                label="Volume"
                variant="outlined"
                onChange={handleChangeVolume}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.no_publikasi}
                label="No Publikasi"
                variant="outlined"
                onChange={handleChangeNoPublikasi}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.katalog_publikasi}
                label="Katalog Publikasi"
                variant="outlined"
                onChange={handleChangeKatalogPublikasi}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.keterangan}
                label="Keterangan"
                variant="outlined"
                onChange={handleChangeKeterangan}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {bahanPustaka !== null ? (
              <TextField
                id="outlined-basic"
                defaultValue={bahanPustaka.infoTambahan.bulan_terbit}
                label="Bulan Terbit"
                variant="outlined"
                onChange={handleChangeBulanTerbit}
              />
            ) : (
              <Skeleton height={60} />
            )}
          </Box>
          <Button type="submit" variant="contained">
            Edit
          </Button>
        </form>
      </Box>
    </AdminLayout>
  );
};

export default EditBahanPustaka;
