import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuContext from "../../../context/MenuProvider";
import color from "../../../theme/Color";
import ListItemFasilitas from "./ListItemFasilitas";
import ListItemLayanan from "./ListItemLayanan";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

const menus = [
  {
    title: "Fasilitas",
    path: null,
    anchor: "listItemFasilitas",
  },
  {
    title: "Layanan",
    path: null,
    anchor: "listItemLayanan",
  },
  {
    title: "Tata Tertib",
    path: "/tata-tertib",
    anchor: null,
  },
  {
    title: "Struktur Organisasi",
    path: "/str-org",
    anchor: null,
  },
];

const SubAppBarTentangKami = () => {
  const { stateAppBar, setStateAppBar, setStateListItem } =
    useContext(MenuContext);
  const navigate = useNavigate();

  const toggleSubAppBar = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateAppBar({ ...stateAppBar, [anchor]: open });
    if (path !== null) navigate(path);
  };

  const closeSubAppBar = () => {
    setStateAppBar({
      subAppBarTentangKami: false,
      subAppBarKoleksi: false,
      subAppBarBantuan: false,
    });
  };

  const toggleListItem = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateListItem({ [anchor]: open });
  };

  return (
    <Box
      sx={{
        paddingTop: "1em",
        paddingBottom: "1em",
        width: "100%",
        backgroundColor: color.white_transparent,
        backdropFilter: "blur(10px)",
        position: "fixed",
        top: "64px",
        right: "0",
        left: "0",
        zIndex: "10",
      }}
      onMouseOver={toggleSubAppBar("subAppBarTentangKami", true, null)}
      onMouseOut={closeSubAppBar}
    >
      <Container maxWidth="lg">
        <Stack direction="row" spacing={12}>
          <Typography sx={{ width: "300px", marginTop: "10px" }}>
            Berisi tentang berbagai informasi mengenai perpustakaan, seperti
            fasilitas dan layanan yang tersedia dan tata tertib
          </Typography>

          <Stack direction="row" spacing={3}>
            <List>
              {menus.map((menu, key) => (
                <ListItem key={key} disablePadding sx={{ width: "200px" }}>
                  <ListItemButton
                    path={menu.path}
                    onMouseOver={toggleListItem(menu.anchor, true)}
                    onClick={
                      menu.path !== null
                        ? toggleSubAppBar(
                            "subAppBarTentangKami",
                            false,
                            menu.path
                          )
                        : toggleSubAppBar("subAppBarTentangKami", true, null)
                    }
                    sx={{
                      borderBottom: "1px solid rgb(230,230,230)",
                      borderLeft: "1px solid rgb(230,230,230)",
                    }}
                  >
                    <ListItemText primary={menu.title} />
                    {menu.title !== "Tata Tertib" && menu.title !== "Struktur Organisasi"  && (
                      <Stack>
                        <KeyboardArrowRightOutlinedIcon />
                      </Stack>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <ListItemFasilitas />
            <ListItemLayanan />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default SubAppBarTentangKami;
