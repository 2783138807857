import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import color from "../theme/Color";

const HorizontalCard = (props) => {
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        margin: "auto",
        boxShadow: {
          xs: "0 1px 2px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1)",
          sm: "none",
        },
      }}
    >
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "120px auto",
          gap: "64px",
        }}
      >
        {props.gambar ? (
          <img
            style={{
              marginBottom: "20px",
              maxWidth: "120px",
              maxHeight: "120px",
            }}
            src={`${process.env.REACT_APP_API_URL}/img/pengumuman/${props.gambar}`}
            alt="Gambar Pengumuman"
          />
        ) : (
          <Box
            sx={{
              width: "120px",
              height: "120px",
              margin: "auto",
              backgroundColor: color.dark_blue,
            }}
          ></Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
          }}
        >
          <Typography variant="label" color="InactiveCaptionText">
            {props.date}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: color.dark_blue,
              // fontSize: "20px",
              fontWeight: "bold",
              marginTop: { xs: "16px", sm: "8px" },
            }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ display: { xs: "none", md: "block" }, marginTop: "8px" }}
          >
            {props.content}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default HorizontalCard;
