import { useContext } from "react";
import Draggable from "react-draggable";
import DialogContext from "../../context/admin/DialogProvider";
import { useNavigate } from "react-router";
import axios from "axios";
import MessageContext from "../../context/MessageProvider";
import TokenContext from "../../context/TokenProvider";
import jwtDecode from "jwt-decode";

const DraggableObject = ({ objek }) => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const { setId } = useContext(DialogContext);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleClickEdit = (id) => {
    setId(id);
    navigate(`/a/denah/${id}`);
  };

  const HandleStopDrag = async (event, data) => {
    try {
      if (objek.left !== data.lastX || objek.top !== data.lastY) {
        const response = await axiosJWT.put(
          `${process.env.REACT_APP_API_URL}/a/denah/posisi/${objek.id}`,
          { left: data.lastX, top: data.lastY },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setMessage({
          isOpen: true,
          content: response.data.message,
          severity: "success",
        });
      }
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };
  return (
    <Draggable
      key={objek.uuid}
      axis="both"
      handle=".handle"
      bounds="parent"
      defaultPosition={{ x: objek.left, y: objek.top }}
      position={null}
      grid={[15, 15]}
      scale={1}
      onStop={HandleStopDrag}
    >
      <div
        className="handle"
        id={objek.uuid}
        style={{
          position: "absolute",
          width: `${objek.lebar}px`,
          height: `${objek.tinggi}px`,
          backgroundColor: objek.warna,
          border: "1px solid black",
          display: "grid",
          placeItems: "center",
        }}
        onDoubleClick={() => handleClickEdit(objek.id)}
      >
        <p style={{ fontSize: "12px" }}>{objek.nama}</p>
      </div>
    </Draggable>
  );
};

export default DraggableObject;
