import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";
import { useEffect } from "react";

const CreateQrcodeBahanPustaka = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [urlQRCodePDF, setUrlQRCodePDF] = useState(null);
  const [qrcode, setQrcode] = useState("");
  const [ukuranKertas, setUkuranKertas] = useState("");
  const [orientasiKertas, setOrientasiKertas] = useState("");

  useEffect(() => {
    setNavTo("/a/qrcode");
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeQrcode = (event) => {
    setQrcode(event.target.value);
  };

  const handleChangeUkuranKertas = (event) => {
    setUkuranKertas(event.target.value);
  };

  const handleChangeOrientasiKertas = (event) => {
    setOrientasiKertas(event.target.value);
  };

  const handleSubmitQrcode = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/qrcode`,
        {
          kategori: "buku",
          qrcode: qrcode,
          ukuran: ukuranKertas,
          layout: orientasiKertas,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setUrlQRCodePDF(response.data.message);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { md: "1fr", lg: "1fr 3fr" },
        gap: "20px",
      }}
    >
      <form onSubmit={handleSubmitQrcode}>
        <Box sx={{ display: "grid", gap: "20px" }}>
          <FormControl>
            <InputLabel>QR Code</InputLabel>
            <OutlinedInput
              id="outlined-basic"
              label="QR Code"
              type="text"
              variant="outlined"
              placeholder={`Contoh 
Tunggal : QR000001 
Jamak berurutan : QR000004-QR000011 
Jamak tidak berurutan : QR000001,QR000004,QR000007`}
              multiline
              rows={7}
              onChange={handleChangeQrcode}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Ukuran Kertas</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ukuranKertas}
              label="Ukuran Kertas"
              onChange={handleChangeUkuranKertas}
            >
              <MenuItem value={"A4"}>A4</MenuItem>
              <MenuItem value={"FOLIO"}>F4</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Orientasi Kertas
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orientasiKertas}
              label="Orientasi Kertas"
              onChange={handleChangeOrientasiKertas}
            >
              <MenuItem value={"portrait"}>Portrait</MenuItem>
              <MenuItem value={"landscape"}>Landscape</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "fit-content" }}
          >
            Proses
          </Button>
        </Box>
      </form>
      <Box>
        <iframe
          src={urlQRCodePDF}
          title="title"
          style={{ width: "100%", height: "500px" }}
        ></iframe>
      </Box>
    </Box>
  );
};

export default CreateQrcodeBahanPustaka;
