import {
  CardMedia,
  Typography,
  Box,
  Link,
  Container,
  Stack,
} from "@mui/material";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";

const KoleksiCetak = () => {
  return (
    <MyLayout title={"Koleksi Cetak"} menu={"Tentang Kami"} submenu={"Koleksi Cetak"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Koleksi Buku Cetak
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/rak.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px", marginTop: "8px" }}>
                Kami menyediakan berbagai koleksi cetak, seperti berbagai macam
                buku (dari novel, agama, bahasa, sampai buku penunjang kuliah), 
                karya tulis, publikasi BPS, dan masih banyak lagi.
                Jika Anda ingin menjelajahi koleksi buku yang kami sediakan, anda 
                dapat menjelajahinya <Link href="/peminjaman">di sini</Link>.
              </Typography>
              <Typography align="justify" sx={{ fontSize: "14px", marginTop: "8px" }}>
                Ada beberapa buku yang dapat dipinjam dan yang tidak, informasi
                selengkapnya mengenai peminjaman bahan pustaka dapat dilihat 
                 <Link href="/peminjaman"> di sini</Link>.
              </Typography>
              {/* <Typography sx={{ fontSize: "14px", marginTop: "8px" }}>
                Berikut ini merupakan klasifikasi rak yang ada di perpustakaan STIS :
                <ul>
                  <li>Rak 1-7 : Publikasi</li>
                  <li>Rak 2 : </li>
                  <li>Rak 3 : </li>
                </ul> 
              </Typography> */}
            </Box>
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Fasilitas"}
              submenu-is-active={"Koleksi Cetak"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default KoleksiCetak;
