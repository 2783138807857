import { Grid, Stack, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import BookIcon from "@mui/icons-material/Book";
import KeyIcon from "@mui/icons-material/Key";
import { useContext, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AdminLayout } from "../../layout/admin";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";

const Dashboard = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const boxDashboard = [
    {
      title: "PENGUNJUNG",
      count: 0,
      colorIcon: "rgb(18,194,194)",
      icon: <LoginIcon sx={{ color: "white", fontSize: "40px" }} />,
    },
    {
      title: "PEMINJAMAN BUKU",
      count: 0,
      colorIcon: "rgb(38,191,148)",
      icon: <BookIcon sx={{ color: "white", fontSize: "40px" }} />,
    },
    {
      title: "PEMINJAMAN KUNCI",
      count: 0,
      colorIcon: "rgb(245,184,73)",
      icon: <KeyIcon sx={{ color: "white", fontSize: "40px" }} />,
    },
  ];

  useEffect(() => {
    setNavTo("/a/dashboard");
    refreshToken();
    getInformasiJumlah();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getInformasiJumlah = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/dashboard`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      boxDashboard[0].count = response.data.jumlahPengunjung;
      boxDashboard[1].count = response.data.jumlahPeminjamanBuku;
      boxDashboard[2].count = response.data.jumlahPeminjamanKunci;
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <AdminLayout>
      <Grid container spacing={4}>
        {boxDashboard.map((box) => (
          <Grid item xs={12} md={6} lg={4}>
            <Grid
              container
              columnSpacing={2}
              sx={{
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 4px rgb(200,200,200)",
              }}
            >
              <Grid item>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "60px",
                    height: "60px",
                    backgroundColor: box.colorIcon,
                    borderRadius: "10px",
                  }}
                >
                  {box.icon}
                </Stack>
              </Grid>
              <Grid item>
                <Typography
                  fontSize={14}
                  fontWeight="bolder"
                  sx={{ color: "rgb(180,180,180)" }}
                >
                  {box.title}
                </Typography>
                <Typography fontSize={26} fontWeight="bold">
                  {box.count}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </AdminLayout>
  );
};

export default Dashboard;
