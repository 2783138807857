import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jwtDecode from "jwt-decode";
import axios from "axios";
import fileDownload from "js-file-download";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import DialogContext from "../../../context/admin/DialogProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ShowDataBahanPustaka = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const {
    setId,
    setType,
    setFormSalinKoleksiDialogOpen,
    setDeleteAlertDialogOpen,
  } = useContext(DialogContext);
  const { setMessage } = useContext(MessageContext);
  const [jenisPustaka, setJenisPustaka] = useState("Semua");
  const [orderAscending, setOrderAscending] = useState(true);
  const [kataKunci, setKataKunci] = useState("");
  const [pustaka, setPustaka] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setNavTo("/a/koleksi");
    refreshToken();
    getAllPustaka();
  }, [orderAscending, jenisPustaka, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (id) => {
    navigate(`/a/koleksi/${id}`);
  };

  const handleClickSalin = (uuid) => {
    setFormSalinKoleksiDialogOpen(true);
    setId(uuid);
  };

  const handleClickDelete = (uuid) => {
    setDeleteAlertDialogOpen(true);
    setId(uuid);
    setType("koleksi");
  };

  const handleClickOrder = () => {
    setOrderAscending((prev) => !prev);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeKataKunci = (event) => {
    setKataKunci(event.target.value);
  };

  const getAllPustaka = async () => {
    try {
      let response;
      let query = `page=${page}&size=${rowsPerPage}`;
      if (jenisPustaka && jenisPustaka !== "Semua")
        query += `&jenis-pustaka=${jenisPustaka}`;

      if (orderAscending) query += `&order=ASC`;
      else query += `&order=DESC`;

      if (kataKunci !== null) query += `&kata-kunci=${kataKunci}`;

      response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/koleksi?${query}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setPustaka(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
      }
    }
  };

  const unduhFileExcelKoleksi = async (e) => {
    try {
      e.preventDefault();
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/koleksi/excel?jenis-pustaka=${jenisPustaka}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        }
      );

      fileDownload(response.data, `${jenisPustaka}.xlsx`);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
      }
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Stack direction="row">
          <FormControl fullWidth sx={{ width: "300px" }}>
            <InputLabel id="demo-simple-select-label">Jenis Pustaka</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Jenis Pustaka"
              onChange={(e) => setJenisPustaka(e.target.value)}
              MenuProps={MenuProps}
            >
              <MenuItem value={"Semua"}>Semua</MenuItem>
              <MenuItem value={"Skripsi"}>Skripsi</MenuItem>
              <MenuItem value={"Buku"}>Buku</MenuItem>
              <MenuItem value={"Jurnal"}>Jurnal</MenuItem>
              <MenuItem value={"Publikasi"}>Publikasi</MenuItem>
              <MenuItem value={"Multimedia"}>Multimedia</MenuItem>
              <MenuItem value={"Terbitan Berkala"}>Terbitan Berkala</MenuItem>
              <MenuItem value={"Karya Ilmiah"}>Karya Ilmiah</MenuItem>
              <MenuItem value={"Laporan"}>Laporan</MenuItem>
              <MenuItem value={"EBook"}>EBook</MenuItem>
              <MenuItem value={"EJurnal"}>EJurnal</MenuItem>
              <MenuItem value={"Jurnal Internasional"}>
                Jurnal Internasional
              </MenuItem>
            </Select>
          </FormControl>
          {jenisPustaka !== "Semua" && (
            <Button
              variant="contained"
              color="success"
              sx={{ width: "fit-content" }}
              onClick={unduhFileExcelKoleksi}
            >
              Unduh
            </Button>
          )}
        </Stack>
        <Stack direction="row" sx={{ marginTop: "10px" }}>
          <TextField
            id="outlined-basic"
            disabled={jenisPustaka === ""}
            label="Judul, No Induk, atau QRCode"
            variant="outlined"
            sx={{ width: "300px" }}
            onChange={handleChangeKataKunci}
          />
          <Button variant="contained" onClick={getAllPustaka}>
            Cari
          </Button>
        </Stack>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                onClick={handleClickOrder}
                sx={{
                  width: "200px",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {/* {!orderAscending && <ArrowDropDownIcon />}
                  {orderAscending && <ArrowDropUpIcon />} */}
                <Typography>No Induk</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "400px" }}>
                Judul
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "200px" }}>
                Penulis
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "150px" }}>
                Penerbit
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "150px" }}>
                Tahun Terbit
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "100px" }}>
                No Eks
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "150px" }}>
                Status File PDF
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "300px" }}>
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pustaka !== null &&
              pustaka.rows.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell align="center" sx={{ width: "200px" }}>
                    {row.noInduk}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "400px" }}>
                    {row.judul}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "200px" }}>
                    {row.penulis}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "150px" }}>
                    {row.penerbit}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "150px" }}>
                    {new Date(row.tahunTerbit).getFullYear()}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "100px" }}>
                    {row.noEks}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "150px" }}>
                    {row.filePDFIsExist && <PictureAsPdfIcon />}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleClickEdit(row.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => handleClickSalin(row.id)}
                    >
                      Salin
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleClickDelete(row.id)}
                    >
                      Hapus
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {pustaka === null && (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "400px" }}>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "200px" }}>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "150px" }}>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "150px" }}>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "100px" }}>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "150px" }}>
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
          <TablePagination
            count={pustaka !== null ? pustaka.count : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowDataBahanPustaka;
