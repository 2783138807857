import { createContext, useState } from "react";
import axios from "axios";

const KoleksiContext = createContext();

export const KoleksiProvider = ({ children }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [koleksi, setKoleksi] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [buttonIsActive, setButtonIsActive] = useState({
    relevan: true,
    terbaru: false,
    banyak_dibaca: false,
  });
  const [jenisPustakaSelected, setJenisPustakaSelected] = useState({
    id: "0",
    label: "Semua",
  });
  // const klasifikasiSelected = []; //Buat ngliat yang udah kepilih
  const [klasifikasiSelected, setKlasifikasiSelected] = useState([]);
  const [klasifikasiList, setKlasifikasiList] = useState(null); //Buat ngambil list nama
  const [idKoleksi, setIdKoleksi] = useState(0);
  const [noInduk, setNoInduk] = useState(null);
  const [rangeTahun, setRangeTahun] = useState([1960, currentYear]);

  const jenis_pustaka = [
    { id: "0", label: "Semua" },
    { id: "1", label: "Skripsi" },
    { id: "2", label: "Buku" },
    { id: "3", label: "Jurnal" },
    { id: "4", label: "Publikasi" },
    { id: "5", label: "Multimedia" },
    { id: "6", label: "Terbitan Berkala" },
    { id: "7", label: "Karya Ilmiah" },
    { id: "8", label: "Laporan" },
    { id: "9", label: "EBook" },
    { id: "10", label: "EJurnal" },
    { id: "11", label: "Jurnal Internasional" },
  ];

  const getKoleksi = async () => {
    let url = `${process.env.REACT_APP_API_URL}/koleksi?page=${page}&size=${rowsPerPage}`;
    if (jenisPustakaSelected !== null && jenisPustakaSelected.id !== "0") {
      url += `&jenis-pustaka=${jenisPustakaSelected.label}`;
    }
    if (klasifikasiSelected.length !== 0) {
      for (let i = 0; i < klasifikasiSelected.length; i++) {
        if (klasifikasiSelected[i] !== "") {
          url += `&klasifikasi=${klasifikasiSelected[i]}`;
        }
      }
    }
    if (keyword !== "") {
      url += `&kata-kunci=${keyword}`;
    }
    if (buttonIsActive.terbaru) {
      url += `&urutan=terbaru`;
    }
    if (buttonIsActive.banyak_dibaca) {
      url += `&urutan=dibaca`;
    }
    if (rangeTahun[0] !== null && rangeTahun[0] !== 1960) {
      url += `&tahun-bawah=${rangeTahun[0]}`;
    }
    if (rangeTahun[1] !== null && rangeTahun[1] !== currentYear) {
      url += `&tahun-atas=${rangeTahun[1]}`;
    }

    try {
      const response = await axios.get(url);
      setKoleksi(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <KoleksiContext.Provider
      value={{
        koleksi,
        setKoleksi,
        keyword,
        setKeyword,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        buttonIsActive,
        setButtonIsActive,
        jenis_pustaka,
        jenisPustakaSelected,
        setJenisPustakaSelected,
        klasifikasiSelected,
        setKlasifikasiSelected,
        klasifikasiList,
        setKlasifikasiList,
        idKoleksi,
        setIdKoleksi,
        noInduk,
        setNoInduk,
        rangeTahun,
        setRangeTahun,
        getKoleksi,
      }}
    >
      {children}
    </KoleksiContext.Provider>
  );
};

export default KoleksiContext;
