import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdminLayout } from "../../../layout/admin";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";
import { useEffect } from "react";

const CreateKunciLoker = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [nomorKunci, setNomorKunci] = useState("");
  const [statusKunci, setStatusKunci] = useState("");

  useEffect(() => {
    setNavTo("/a/kunci/create");
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeNomorKunci = (nomorKunci) => {
    setNomorKunci(nomorKunci);
  };

  const handleChangeStatusKunci = (event) => {
    setStatusKunci(event.target.value);
  };

  const handleSubmitHariLibur = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/kunci`,
        { nomor: nomorKunci, status: statusKunci },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/kunci");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Kunci Loker
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmitHariLibur}>
            <TextField
              id="outlined-basic"
              label="Nomor Kunci"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => handleChangeNomorKunci(e.currentTarget.value)}
            />
            <FormControl fullWidth sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusKunci}
                label="Status"
                onChange={handleChangeStatusKunci}
              >
                <MenuItem value={"0"}>Tidak Tersedia</MenuItem>
                <MenuItem value={"1"}>Tersedia</MenuItem>
                <MenuItem value={"2"}>Dipinjam</MenuItem>
              </Select>
            </FormControl>
            <Button type="submit" variant="contained">
              Tambah
            </Button>
          </form>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CreateKunciLoker;
