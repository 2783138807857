import { useContext, useEffect, useState } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContext from "../../context/admin/DialogProvider";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import {
  Autocomplete,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";

const KembalikanDanMemulangkanPengunjungSerentak = () => {
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const {
    idPengunjung,
    kembalikanDanMemulangkanPengunjungSerentakDialogOpen,
    setKembalikanDanMemulangkanPengunjungSerentakDialogOpen,
  } = useContext(DialogContext);
  const [kunciLoker, setKunciLoker] = useState(null);
  const [kunciLokerBelumDikembalikan, setKunciLokerBelumDikembalikan] =
    useState([]);

  useEffect(() => {
    getAllKunciLoker();
    console.log(kunciLokerBelumDikembalikan);
  }, [
    kunciLokerBelumDikembalikan,
    kembalikanDanMemulangkanPengunjungSerentakDialogOpen,
  ]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllKunciLoker = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/kunci?&page=0&size=200`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setKunciLoker(response.data.rows);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        setKembalikanDanMemulangkanPengunjungSerentakDialogOpen(false);
      }
    }
  };

  const handleClose = () => {
    setKembalikanDanMemulangkanPengunjungSerentakDialogOpen(false);
  };

  const handleCloseAndKembalikanKunciAndMemulangkanPengunjungSerentak =
    async () => {
      try {
        const response = await axiosJWT.post(
          `${process.env.REACT_APP_API_URL}/a/pengunjung`,
          { arrayUuidKunci: kunciLokerBelumDikembalikan },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setMessage({
          isOpen: true,
          content: response.data.message,
          severity: "success",
        });
        setKembalikanDanMemulangkanPengunjungSerentakDialogOpen(false);
      } catch (error) {
        if (error.response) {
          setMessage({
            isOpen: true,
            content: error.response.data.message,
            severity: "error",
          });
        }
      }
    };

  return (
    <Dialog
      open={kembalikanDanMemulangkanPengunjungSerentakDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" align="center">
        Pulangkan dan Kembalikan Kunci Pengunjung Serentak
      </DialogTitle>
      <DialogContent>
        {kunciLoker && (
          <Autocomplete
            sx={{ marginTop: "32px" }}
            onChange={(event, newValue) => {
              setKunciLokerBelumDikembalikan(newValue.map((el) => el.uuid));
            }}
            multiple
            id="tags-outlined"
            options={kunciLoker}
            getOptionLabel={(option) => option.nomor}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="No Kunci"
                placeholder="No Kunci yang belum dikembalikan"
              />
            )}
          />
        )}
        <Typography sx={{ marginTop: "16px" }}>
          Apakah Anda yakin ingin melanjutkan aksi ini?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Tidak
        </Button>
        <Button
          onClick={
            handleCloseAndKembalikanKunciAndMemulangkanPengunjungSerentak
          }
          variant="contained"
          autoFocus
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KembalikanDanMemulangkanPengunjungSerentak;
