import { MyLayout } from "../../layout";
import CustomizedAccordions from "../../components/CustomizedAccordions";
import {
  Typography,
  Box,
  Container,
  Stack,
} from "@mui/material";
import color from "../../theme/Color";
import RightSideBarBantuan from "../../components/RightSideBarBantuan";

const faq = [
  {
    id: 1,
    title: "Bagaimana cara berkunjung di perpustakaan?",
    content:
      "Pengguna dapat melakukan scan kode QR pada komputer yang ada di meja sirkulasi. Setelah itu pastikan pengguna tidak membawa barang selain gawai/gadget berserta adaptornya. Jika membawa barang yang tidak diperkenankan masuk, pengguna dapat meminjam kunci loker dan meletakkan barang tersebut di loker yang tersedia. Info selengkapnya dapat dilihat di sini.",
  },
  {
    id: 2,
    title:
      "Apa saja yang boleh dibawa ketika berkunjung ke dalam perpustakaan?",
    content:
      "Pengguna hanya diperkenankan membawa gawai/gadget beserta adaptornya, selain itu pengguna diharapkan untuk menitipkan barangnya di loker perpustakaan.",
  },
  {
    id: 3,
    title: "Apa sanksi yang dikenakan jika kehilangan kunci loker?",
    content:
      "Pengguna akan dikenakan sanksi berupa denda sebesar Rp 50.000 atau menjadi relawan perpustakaan selama sehari.",
  },
  {
    id: 4,
    title: "Bagaimana cara meminjam buku?",
    content: "Jawaban pertanyaan 4",
  },
  {
    id: 5,
    title: "Berapa denda yang dibayarkan jika telat mengembalikan buku?",
    content: "Jawaban pertanyaan 5",
  },
  {
    id: 6,
    title: "Apa itu surat bebas perpustakaan?",
    content: "Jawaban pertanyaan 6",
  },
  {
    id: 7,
    title:
      "Apakah ada penerimaan volunteer/relawan untuk membantu perpustakaan?",
    content: "Jawaban pertanyaan 7",
  },
];

const Faq = () => {
  return (
    <MyLayout title={"FAQ"} menu={"Bantuan"} submenu={"FAQ"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box sx={{ borderRadius: "10px", overflow: "hidden" }}>
            <CustomizedAccordions data={faq} type={"no-tata-tertib"} />
          </Box>
          {/* rightSideBarbutton */}
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Bantuan
              </Typography>
            </Stack>

            <RightSideBarBantuan
              menu-is-active={"Frequently Asked Question"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default Faq;
