import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../../../../context/TokenProvider";
import DialogContext from "../../../../../../context/admin/DialogProvider";
import MessageContext from "../../../../../../context/MessageProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ShowMatkulD3Semester4 = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const {
    setId,
    setType,
    setDeleteAlertDialogOpen,
    setCreateFormRekomendasiDialogOpen,
    setKodeMatkul,
    setIdMatkul,
    setFormAddBukuToMatkulDialogOpen,
  } = useContext(DialogContext);
  const { setMessage } = useContext(MessageContext);
  const [matkulD3Semester4, setMatkulD3Semester4] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    getAllMatkulD3Semester4();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickAddBuku = (idMatkul) => {
    setFormAddBukuToMatkulDialogOpen(true);
    setIdMatkul(idMatkul);
  };

  const handleClickDelete = (uuid) => {
    setDeleteAlertDialogOpen(true);
    setId(uuid);
    setType("rekomendasi");
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllMatkulD3Semester4 = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/rekomendasi?kode=02/D3/04/&page=${page}&size=${rowsPerPage}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMatkulD3Semester4(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleClickCreateRekomendasi = (kode) => {
    setKodeMatkul(kode);
    setCreateFormRekomendasiDialogOpen(true);
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <Typography variant="h6" textAlign="center">
        Semester 4
      </Typography>
      <Button
        variant="contained"
        onClick={() => handleClickCreateRekomendasi("02/D3/04/")}
      >
        <ControlPointIcon sx={{ marginRight: "10px" }} />
        Tambah
      </Button>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Kode</StyledTableCell>
              <StyledTableCell align="center">Matkul</StyledTableCell>
              <StyledTableCell align="center">Aksi</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matkulD3Semester4 !== null &&
              matkulD3Semester4.rows.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell align="center">
                    {row.kodeMatkul}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.matkul}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickAddBuku(row.id)}
                    >
                      Tambah Buku
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleClickDelete(row.id)}
                    >
                      Hapus
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {matkulD3Semester4 === null && (
              <>
                <StyledTableRow key={1}>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key={2}>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key={3}>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key={4}>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key={5}>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
          <TablePagination
            count={matkulD3Semester4 !== null ? matkulD3Semester4.count : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShowMatkulD3Semester4;
