import { 
  Box, 
  Container, 
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, 
  Paper
} from "@mui/material";
import color from "../../theme/Color";

const MyFooter = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: color.dark_blue,
          paddingTop: "56px",
          paddingBottom: "72px",
          color: color.light_white,
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { lg: "auto auto auto" },
            }}
          >
            <Box sx={{ marginBottom: "20px"}}>
              {/* Lokasi */}
              <Typography sx={{ marginBottom: "20px", fontSize: { xs: "20px", md: "24px" } }}>
                Lokasi
              </Typography>
              <Typography>Gedung 2 Lantai 3</Typography>
              <Typography sx={{ marginY: { xs: "5px", md: "10px" } }}>
                Politeknik Statistika STIS
              </Typography>
              <Typography>
                Jl. Otto Iskandardinata No. 64C Jakarta 13330
              </Typography>
              <iframe
                title="Peta Polstat STIS"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2439456813545!2d106.86440601476917!3d-6.231537795489182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f375d8d2442d%3A0x4087ed65c373adce!2sPoliteknik%20Statistika%20STIS!5e0!3m2!1sid!2sid!4v1676948198015!5m2!1sid!2sid"
                height="250"
                style={{ marginTop: "30px", width: "364", maxWidth: "100%" }}
                loading="lazy"
              />
            </Box>

            <Box sx={{ marginBottom: "20px"}}>
              {/* Kontak */}
              <Typography sx={{ marginBottom: "20px", fontSize: { xs: "20px", md: "24px" } }}>
                Kontak
              </Typography>
              <Typography>Telp. (021) 8191437, 8508812</Typography>
              <Typography sx={{ marginY: { xs: "5px", md: "10px" } }}>
                Fax. (021) 8197577
              </Typography>
              <Typography>E-mail perpus@stis.ac.id</Typography>

              {/* Jam Operasional */}
              <Typography
                sx={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  fontSize: { xs: "20px", md: "24px" },
                }}
              >
                Jam Operasional
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ boxShadow: "none", backgroundColor: "transparent", maxWidth: "300px" }}
              >
                <Table padding="none">
                  <TableBody>
                    <TableRow>
                      <TableCell 
                        size="21px"
                        sx={{ fontSize: "16px", border: "none",  maxWidth: "160px", color: color.light_white }}>
                        Senin-Kamis
                      </TableCell>
                      <TableCell
                        size="21px"
                        sx={{ fontSize: "16px", paddingBottom: "10px", border: "none", color: color.light_white}}
                      >
                        : 08.00-15.30 WIB
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontSize: "16px", border: "none",  maxWidth: "160px", color: color.light_white }}>
                        Jumat
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          border: "none",
                          color: color.light_white,
                          paddingBottom: "10px",
                        }}
                      >
                        : 08.00-16.00 WIB
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontSize: "16px", border: "none",  maxWidth: "160px", color: color.light_white }}>
                        Sabtu-Minggu
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px", border: "none", color: color.light_white }}>
                        : Libur
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box>
              {/* Pintasan */}
              <Typography sx={{ marginTop: { xs: "20px", md: "0" }, marginBottom: "20px", fontSize: { xs: "20px", md: "24px" } }}>
                Pintasan
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  component="a"
                  href="/tata-tertib"
                  sx={{
                    textDecoration: "none",
                    color: color.light_white,
                    ":hover": {
                      color: "rgb(200,200,200)",
                    },
                  }}
                >
                  Tata Tertib
                </Typography>
                <Typography
                  component="a"
                  href="/"
                  sx={{
                    marginTop: { xs: "5px", md: "10px" },
                    textDecoration: "none",
                    color: color.light_white,
                    ":hover": {
                      color: "rgb(200,200,200)",
                    },
                  }}
                >
                  Fasilitas
                </Typography>
                <Typography
                  component="a"
                  href="/"
                  sx={{
                    marginTop: { xs: "5px", md: "10px" },
                    textDecoration: "none",
                    color: color.light_white,
                    ":hover": {
                      color: "rgb(200,200,200)",
                    },
                  }}
                >
                  Layanan
                </Typography>
                <Typography
                  component="a"
                  href="/koleksi"
                  sx={{
                    marginTop: { xs: "5px", md: "10px" },
                    textDecoration: "none",
                    color: color.light_white,
                    ":hover": {
                      color: "rgb(200,200,200)",
                    },
                  }}
                >
                  Koleksi Kami
                </Typography>
                <Typography
                  component="a"
                  href="/saran"
                  sx={{
                    marginTop: { xs: "5px", md: "10px" },
                    textDecoration: "none",
                    color: color.light_white,
                    ":hover": {
                      color: "rgb(200,200,200)",
                    },
                  }}
                >
                  Kritik dan Saran
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#F9F9F9" }}>
        <Typography sx={{ textAlign: "center", padding: "12px", fontSize: { xs: "14px", md: "16px" } }}>
          2023 &copy; Perpustakaan Politeknik Statistika STIS
        </Typography>
      </Box>
    </>
  );
};

export default MyFooter;
