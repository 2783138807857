import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useContext } from "react";
import MenuContext from "../../context/MenuProvider";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const menus = [
  { title: "Beranda", path: "/", anchor: null },
  { title: "Tentang Kami", path: null, anchor: "subAppBarTentangKami" },
  { title: "Koleksi", path: null, anchor: "subAppBarKoleksi" },
  { title: "Bantuan", path: null, anchor: "subAppBarBantuan" },
];

const AppBarItemsCenter = () => {
  const navigate = useNavigate();
  const { setStateAppBar } = useContext(MenuContext);

  const toggleSubAppBar = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateAppBar({ [anchor]: open });
    if (path !== null) navigate(path);
  };

  const closeSubAppBar = () => {
    setStateAppBar({
      subAppBarTentangKami: false,
      subAppBarFasilitas: false,
      subAppBarLayanan: false,
      subAppBarKoleksi: false,
      subAppBarBantuan: false,
    });
  };

  return (
    <Box>
      <Stack
        component="a"
        href="/"
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ textDecoration: "none", display: { xs: "flex", md: "none" } }}
      >
        <img src="./image/Logo.png" alt="Logo SIMPus" width="40" height="40" />
        <Typography
          sx={{
            fontSize: "34px",
            color: "black",
            display: { xs: "none", md: "block" },
          }}
        >
          SIMPus
        </Typography>
      </Stack>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {menus.map((menu, key) => (
          <Button
            key={key}
            sx={{
              color: "black",
              display: "block",
              height: "64px",
            }}
            onClick={
              menu.path !== null
                ? toggleSubAppBar(menu.anchor, false, menu.path)
                : null
            }
            onMouseOver={
              menu.anchor !== null
                ? toggleSubAppBar(menu.anchor, true, menu.path)
                : null
            }
            onMouseOut={menu.anchor !== null ? closeSubAppBar : null}
          >
            {menu.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default AppBarItemsCenter;
