import { createContext, useState } from "react";

const AdminSidebarContext = createContext();

export const AdminSidebarProvider = ({ children }) => {
  const [adminSidebarOpen, setAdminSidebarOpen] = useState(true);

  return (
    <AdminSidebarContext.Provider
      value={{ adminSidebarOpen, setAdminSidebarOpen }}
    >
      {children}
    </AdminSidebarContext.Provider>
  );
};

export default AdminSidebarContext;
