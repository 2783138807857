import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";

const UploadFilePDFBahanPustaka = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [jenisPustaka, setJenisPustaka] = useState("");
  const [files, setFiles] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setNavTo("/a/koleksi/create");
    refreshToken();
  }, [fileName]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleDrag = function (event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (event) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFiles(event.dataTransfer.files);
      console.log(event.dataTransfer.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleChangeLoadFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFiles(event.target.files);
      console.log(event.target.files);
    }
  };

  const handleChangeJenisPustaka = (event) => {
    setJenisPustaka(event.target.value);
  };

  const handleSubmitBahanPustaka = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("jenisPustaka", jenisPustaka);
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
        }
      } else {
        formData.append("file", null);
      }
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/koleksi/pdf`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/koleksi");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form
        style={{
          height: "600px",
          width: "100%",
          textAlign: "center",
          position: "relative",
        }}
        onDragEnter={handleDrag}
        onSubmit={(event) => event.preventDefault()}
      >
        <FormControl fullWidth sx={{ width: "100%", marginBottom: "20px" }}>
          <InputLabel id="demo-simple-select-label">Jenis Pustaka</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jenisPustaka}
            label="Jenis Pustaka"
            onChange={handleChangeJenisPustaka}
          >
            <MenuItem value={"skripsi"}>Skripsi</MenuItem>
            <MenuItem value={"ebook"}>EBook</MenuItem>
            <MenuItem value={"ejurnal"}>EJurnal</MenuItem>
          </Select>
        </FormControl>
        <input
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          multiple={true}
          onChange={handleChangeLoadFile}
        />
        <label
          style={
            dragActive
              ? {
                  width: "100%",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: "2px",
                  borderRadius: "1rem",
                  borderStyle: "dashed",
                  borderColor: "#cbd5e1",
                  backgroundColor: "#f8fafc",
                }
              : {
                  width: "100%",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: "2px",
                  borderRadius: "1rem",
                  borderStyle: "dashed",
                  borderColor: "#cbd5e1",
                  backgroundColor: "#ffffff",
                }
          }
          htmlFor="input-file-upload"
        >
          {files ? (
            // <div
            //   style={{
            //     width: "100%",
            //     height: "100%",
            //     padding: "10px",
            //     display: "flex",
            //     flexWrap: "wrap",
            //     gap: "10px",
            //   }}
            // >
            //   <div
            //     style={{
            //       width: "150px",
            //       height: "150px",
            //       borderRadius: "20px",
            //       backgroundColor: "#f0f0f0",
            //       display: "flex",
            //       flexDirection: "column",
            //       alignItems: "center",
            //     }}
            //   >
            //     <PictureAsPdfIcon sx={{ fontSize: "100px" }} />
            //     <p>{}</p>
            //   </div>
            // </div>
            <div>
              <h2>File terupload sejumlah {files.length}</h2>
            </div>
          ) : (
            <div>
              <p>Drag and drop your file here or</p>
              <button
                style={{
                  cursor: "pointer",
                  padding: "0.25rem",
                  fontSize: "1rem",
                  border: "none",
                  fontFamily: "Oswald, sans-serif",
                  backgroundColor: "transparent",
                }}
                onClick={onButtonClick}
              >
                Upload a file
              </button>
            </div>
          )}
        </label>
        {dragActive && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: "1rem",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <Button
        sx={{ width: "fit-content", marginTop: "20px" }}
        type="submit"
        variant="contained"
        onClick={handleSubmitBahanPustaka}
      >
        Tambah
      </Button>
    </Box>
  );
};

export default UploadFilePDFBahanPustaka;
