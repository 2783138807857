import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { styled } from "@mui/material/styles";
import MessageContext from "../../../../context/MessageProvider";
import TokenContext from "../../../../context/TokenProvider";
import CustomPieChart from "../../../../components/admin/PieChart";
import fileDownload from "js-file-download";
import HistogramChart from "../../../../components/admin/HistogramChart";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const HariIni = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [dataByTujuan, setDataByTujuan] = useState(null);
  const [dataByTingkat, setDataByTingkat] = useState(null);
  
  const dataTrial = [
    { jam: "07", name: "total", jumlah: 0 },
    { jam: "08", name: "total", jumlah: 5 },
    { jam: "09", name: "total", jumlah: 7 },
    { jam: "10", name: "total", jumlah: 25 },
    { jam: "11", name: "total", jumlah: 20 },
    { jam: "12", name: "total", jumlah: 2 },
    { jam: "13", name: "total", jumlah: 3 },
    { jam: "14", name: "total", jumlah: 16 },
    { jam: "15", name: "total", jumlah: 7 },
    { jam: "16", name: "total", jumlah: 0 },
  ];

  // const dataTrial = [
  //   { jam: "07", name: "total", tingkat1:0, tingkat2:0, tingkat3:0, tingkat4:0, dosen:0, pengunjungluar:0, total: 0 },
  //   { jam: "08", name: "total", tingkat1:0, tingkat2:2, tingkat3:3, tingkat4:0, dosen:0, pengunjungluar:0, total: 5 },
  //   { jam: "09", name: "total", tingkat1:2, tingkat2:2, tingkat3:1, tingkat4:2, dosen:0, pengunjungluar:0, total: 7 },
  //   { jam: "10", name: "total", tingkat1:8, tingkat2:5, tingkat3:3, tingkat4:8, dosen:1, pengunjungluar:0, total: 25 },
  //   { jam: "11", name: "total", tingkat1:7, tingkat2:3, tingkat3:6, tingkat4:4, dosen:0, pengunjungluar:0, total: 20 },
  //   { jam: "12", name: "total", tingkat1:2, tingkat2:0, tingkat3:0, tingkat4:0, dosen:0, pengunjungluar:0, total: 2 },
  //   { jam: "13", name: "total", tingkat1:0, tingkat2:0, tingkat3:0, tingkat4:3, dosen:0, pengunjungluar:0, total: 3 },
  //   { jam: "14", name: "total", tingkat1:6, tingkat2:7, tingkat3:5, tingkat4:0, dosen:0, pengunjungluar:0, total: 16 },
  //   { jam: "15", name: "total", tingkat1:5, tingkat2:2, tingkat3:0, tingkat4:0, dosen:0, pengunjungluar:0, total: 7 },
  //   { jam: "16", name: "total", tingkat1:0, tingkat2:0, tingkat3:0, tingkat4:0, dosen:0, pengunjungluar:0, total: 0 },
  // ];
  
  const current = new Date();
  const year = current.getFullYear();
  const month = String(current.getMonth() + 1);
  const day = String(current.getDate());
  // const currentDate = `${year}-${month}-${day}`;
  const currentDate = `2023-7-20`;
  useEffect(() => {
    setNavTo("/a/koleksi/create");
    refreshToken();
    getAllData();
    console.log(currentDate);
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDataByTujuan = async (e) => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/harian/pie-chart?tanggal=${currentDate}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setDataByTujuan(response.data);
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  }
  
  const getDataByTingkat = async (e) => {
    try {
      const response = await axiosJWT.get(
        // `${process.env.REACT_APP_API_URL}/a/statistik/harian/tabel?tanggal=${currentDate}`,
        `${process.env.REACT_APP_API_URL}/a/statistik/tahunan/tabel?tahun=2023`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const newData = response.data.map(item => {
        return {
            name: item.kategori,
            value: item.jumlah
        };
      });
      setDataByTingkat(newData);
      console.log(newData);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  }

  const getDataByWaktu = async (e) => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/statistik/harian/bar?tanggal=${currentDate}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      // setDataByTujuan(responsePieChart.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  }

  const getAllData = () => {
    getDataByTujuan();
    getDataByTingkat();
    // getDataByWaktu();
  }

  return (
    <Box sx={{ width: "100%" }}>
        
        <>
          <Typography variant="h5">Visualisasi</Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "30px",
              marginY: "16px",
            }}
          >
            {dataByTujuan !== null && (
            <Box
              sx={{
                width: "100%",
                padding: "5px",
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgb(200,200,200)",
              }}
            >
              <Typography align="center">
                Jumlah Pengunjung berdasarkan Tujuan
              </Typography>
              <Box
                sx={{
                  width: "500px",
                  height: "350px",
                }}
              >
                <CustomPieChart data={dataByTujuan} />
              </Box>
            </Box>
            )}
            {dataByTingkat !== null && (
            <Box
              sx={{
                width: "100%",
                padding: "5px",
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgb(200,200,200)",
              }}
            >
              <Typography align="center">
                Jumlah Pengunjung berdasarkan Jenis Pengguna
              </Typography>
              <Box
                sx={{
                  width: "500px",
                  height: "350px",
                }}
              >
                <CustomPieChart data={dataByTingkat} />
              </Box>
            </Box>
            )}
          </Box>
          {dataByTujuan !== null && (
            <Box
              sx={{
                width: "100%",
                padding: "5px",
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgb(200,200,200)",
              }}
            >
              <Typography align="center">
                Jumlah Pengunjung berdasarkan Jam Kunjungan
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <HistogramChart data={dataTrial}/>
              </Box>
            </Box>
            )}
        </>
    </Box>
  );
};

export default HariIni;
