import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import { customRoutes, customRoutesAdmin } from "./routes";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={"/login"} element={<Login />} key={"Login"} />
        {customRoutes.map(({ path, element }, key) => (
          <Route path={path} element={element} key={key} />
        ))}
        {customRoutesAdmin.map(({ path, element }, key) => (
          <Route path={path} element={element} key={key} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
