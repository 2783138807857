import { Box, IconButton, Backdrop, CircularProgress, Typography } from "@mui/material";
import {aturan} from "../data/Loading"


const Loading = () => {
    // Generate random index to get a random rule from the aturan array
    const randomIndex = Math.floor(Math.random() * aturan.length);
    const randomAturan = aturan[randomIndex];

    return (
        <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
            padding:"16px",
        }}
        >
        <CircularProgress color="inherit" sx={{margin:"32px 0 24px"}}/>
        {randomAturan.image}
        <Typography align="center" sx={{fontWeight:"bold"}}>{randomAturan.message}</Typography>
        </Box>
    );
};
  
export default Loading;
