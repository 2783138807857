import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateBahanPustakaWithForm = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [allKlasifikasi, setAllKlasifikasi] = useState(null);
  const [jenisPustaka, setJenisPustaka] = useState("");
  const [noInduk, setNoInduk] = useState("");
  const [klasifikasi, setKlasifikasi] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [judul, setJudul] = useState("");
  const [penulis, setPenulis] = useState("");
  const [tahunTerbit, setTahunTerbit] = useState("");
  const [kolasi, setKolasi] = useState("");
  const [kodeRak, setKodeRak] = useState("");
  const [tanggalMasuk, setTanggalMasuk] = useState("");
  const [infoTambahan, setInfoTambahan] = useState({
    penerbit: "",
    sumber: "",
    cetakan: "",
    edisi: "",
    jilid: "",
    isbn: "",
    no_eks: "",
    harga: "",
    issn: "",
    volume: "",
    no_publikasi: "",
    katalog_publikasi: "",
    keterangan: "",
    bulan_terbit: "",
    kode_klasifikasi: "",
  });

  useEffect(() => {
    setNavTo("/a/koleksi/create");
    refreshToken();
    if (jenisPustaka !== "") getAllKlasifikasiByJenisPustaka();
  }, [jenisPustaka]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllKlasifikasiByJenisPustaka = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/klasifikasi?jenis-pustaka=${jenisPustaka}`
      );
      setAllKlasifikasi(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleChangeJenisPustaka = (event) => {
    setJenisPustaka(event.target.value);
  };

  const handleChangeNoInduk = (event) => {
    setNoInduk(event.target.value);
  };

  const handleChangeQrcode = (event) => {
    setQrcode(event.target.value);
  };

  const handleChangeJudul = (event) => {
    setJudul(event.target.value);
  };

  const handleChangePenulis = (event) => {
    setPenulis(event.target.value);
  };

  const handleChangeKolasi = (event) => {
    setKolasi(event.target.value);
  };

  const handleChangeKodeRak = (event) => {
    setKodeRak(event.target.value);
  };

  const handleChangeTahunTerbit = (event) => {
    setTahunTerbit(event.target.value);
  };

  const handleChangeTanggalMasuk = (value) => {
    setTanggalMasuk(`${value.$y}-${value.$M + 1}-${value.$D}`);
  };

  const handleChangePenerbit = (event) => {
    setInfoTambahan({ ...infoTambahan, penerbit: event.target.value });
  };

  const handleChangeSumber = (event) => {
    setInfoTambahan({ ...infoTambahan, sumber: event.target.value });
  };

  const handleChangeCetakan = (event) => {
    setInfoTambahan({ ...infoTambahan, cetakan: event.target.value });
  };

  const handleChangeEdisi = (event) => {
    setInfoTambahan({ ...infoTambahan, edisi: event.target.value });
  };

  const handleChangeJilid = (event) => {
    setInfoTambahan({ ...infoTambahan, jilid: event.target.value });
  };

  const handleChangeIsbn = (event) => {
    setInfoTambahan({ ...infoTambahan, isbn: event.target.value });
  };

  const handleChangeNoEks = (event) => {
    setInfoTambahan({ ...infoTambahan, no_eks: event.target.value });
  };

  const handleChangeHarga = (event) => {
    setInfoTambahan({ ...infoTambahan, harga: event.target.value });
  };

  const handleChangeIssn = (event) => {
    setInfoTambahan({ ...infoTambahan, issn: event.target.value });
  };

  const handleChangeVolume = (event) => {
    setInfoTambahan({ ...infoTambahan, volume: event.target.value });
  };

  const handleChangeNoPublikasi = (event) => {
    setInfoTambahan({ ...infoTambahan, no_publikasi: event.target.value });
  };

  const handleChangeKatalogPublikasi = (event) => {
    setInfoTambahan({ ...infoTambahan, katalog_publikasi: event.target.value });
  };

  const handleChangeKeterangan = (event) => {
    setInfoTambahan({ ...infoTambahan, keterangan: event.target.value });
  };

  const handleChangeBulanTerbit = (event) => {
    setInfoTambahan({ ...infoTambahan, bulan_terbit: event.target.value });
  };

  const handleChangeKodeKlasifikasi = (event) => {
    setInfoTambahan({ ...infoTambahan, kode_klasifikasi: event.target.value });
  };

  const handleSubmitBahanPustaka = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/koleksi`,
        {
          noInduk: noInduk,
          jenisPustaka: jenisPustaka,
          klasifikasi: klasifikasi,
          qrcode: qrcode,
          judul: judul,
          penulis: penulis,
          tahunTerbit: tahunTerbit,
          kolasi: kolasi,
          kodeRak: kodeRak,
          tanggalMasuk: tanggalMasuk,
          infoTambahan: infoTambahan,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/koleksi");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleSubmitBahanPustaka}>
        <FormControl fullWidth sx={{ width: "100%", marginBottom: "20px" }}>
          <InputLabel id="demo-simple-select-label">Jenis Pustaka</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jenisPustaka}
            label="Jenis Pustaka"
            onChange={handleChangeJenisPustaka}
            MenuProps={MenuProps}
          >
            <MenuItem value={"Skripsi"}>Skripsi</MenuItem>
            <MenuItem value={"Buku"}>Buku</MenuItem>
            <MenuItem value={"Jurnal"}>Jurnal</MenuItem>
            <MenuItem value={"Publikasi"}>Publikasi</MenuItem>
            <MenuItem value={"Multimedia"}>Multimedia</MenuItem>
            <MenuItem value={"Terbitan Berkala"}>Terbitan Berkala</MenuItem>
            <MenuItem value={"Karya Ilmiah"}>Karya Ilmiah</MenuItem>
            <MenuItem value={"Laporan"}>Laporan</MenuItem>
            <MenuItem value={"EBook"}>EBook</MenuItem>
            <MenuItem value={"EJurnal"}>EJurnal</MenuItem>
            <MenuItem value={"Jurnal Internasional"}>
              Jurnal Internasional
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          disabled={jenisPustaka === ""}
          label="No Induk"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangeNoInduk}
        />
        <TextField
          id="outlined-basic"
          label="Judul"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangeJudul}
        />

        <TextField
          id="outlined-basic"
          label="Penulis"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangePenulis}
        />

        <TextField
          id="outlined-basic"
          label="Tahun Terbit"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangeTahunTerbit}
        />

        <TextField
          id="outlined-basic"
          label="Kolasi"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangeKolasi}
        />

        <TextField
          id="outlined-basic"
          label="Kode Klasifikasi"
          variant="outlined"
          onChange={handleChangeKodeKlasifikasi}
          sx={{ width: "100%", marginBottom: "20px" }}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={allKlasifikasi !== null ? allKlasifikasi : []}
          sx={{ width: "100%", marginBottom: "20px" }}
          renderInput={(params) => (
            <TextField {...params} label="Klasifikasi" />
          )}
          onInputChange={(event, newInputValue) => {
            setKlasifikasi(newInputValue);
          }}
        />

        <TextField
          id="outlined-basic"
          label="Kode Rak"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangeKodeRak}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Tanggal Masuk"
            sx={{ width: "100%", marginBottom: "20px" }}
            onChange={(newValue) => handleChangeTanggalMasuk(newValue)}
          />
        </LocalizationProvider>

        <TextField
          id="outlined-basic"
          label="QR Code"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleChangeQrcode}
        />
        
        <Typography sx={{ marginBottom: "20px" }}>Info Tambahan</Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Penerbit"
            variant="outlined"
            onChange={handleChangePenerbit}
          />
          <TextField
            id="outlined-basic"
            label="Sumber"
            variant="outlined"
            onChange={handleChangeSumber}
          />
          <TextField
            id="outlined-basic"
            label="Cetakan"
            variant="outlined"
            onChange={handleChangeCetakan}
          />
          <TextField
            id="outlined-basic"
            label="Edisi"
            variant="outlined"
            onChange={handleChangeEdisi}
          />
          <TextField
            id="outlined-basic"
            label="Jilid"
            variant="outlined"
            onChange={handleChangeJilid}
          />
          <TextField
            id="outlined-basic"
            label="ISBN"
            variant="outlined"
            onChange={handleChangeIsbn}
          />
          <TextField
            id="outlined-basic"
            label="No Eksemplar"
            variant="outlined"
            onChange={handleChangeNoEks}
          />
          <TextField
            id="outlined-basic"
            label="Harga"
            variant="outlined"
            onChange={handleChangeHarga}
          />
          <TextField
            id="outlined-basic"
            label="ISSN"
            variant="outlined"
            onChange={handleChangeIssn}
          />
          <TextField
            id="outlined-basic"
            label="Volume"
            variant="outlined"
            onChange={handleChangeVolume}
          />
          <TextField
            id="outlined-basic"
            label="No Publikasi"
            variant="outlined"
            onChange={handleChangeNoPublikasi}
          />
          <TextField
            id="outlined-basic"
            label="Katalog Publikasi"
            variant="outlined"
            onChange={handleChangeKatalogPublikasi}
          />
          <TextField
            id="outlined-basic"
            label="Keterangan"
            variant="outlined"
            onChange={handleChangeKeterangan}
          />
          <TextField
            id="outlined-basic"
            label="Bulan Terbit"
            variant="outlined"
            onChange={handleChangeBulanTerbit}
          />
        </Box>
        <Button type="submit" variant="contained">
          Tambah
        </Button>
      </form>
    </Box>
  );
};

export default CreateBahanPustakaWithForm;
