import {
  CardMedia,
  Typography,
  Box,
  Link,
  Container,
  Stack,
} from "@mui/material";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";

const MejaBaca = () => {
  return (
    <MyLayout title={"Meja Baca"} menu={"Tentang Kami"} submenu={"Meja Baca"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Meja Baca Bersekat
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/meja_bersekat.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Jika Anda ingin membaca sendiri lebih tenang dan fokus, Anda
                dapat memanfaaatkan meja baca bersekat ini. Di bawah meja ini
                tersedia stopkontak, sehingga Anda dapat membaca sambil mengisi
                daya baterai gawai yang Anda bawa.
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Meja Baca Lesehan
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/meja_lesehan.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Unit perpustakaan juga menyediakan meja baca lesehan bagi Anda.
                Meja baca ini juga nyaman untuk disinggahi karena berada di
                samping jendela yang lebar. Anda dapat sesekali memandang
                birunya langit sembari membaca buku yang digenggam. Tempat ini
                mudah sekali ditemukan setelah Anda memasuki pintu perpustakaan
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Saung Baca
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/saung_baca.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Unit perpustakaan menyediakan saung baca ini untuk Anda yang
                ingin diskusi dengan kelompok belajar Anda. Saung baca ini
                berada di samping perpustakaan, agar ketika diskusi tidak
                mengganggu pengunjung lain yang berada di dalam. Tempat ini
                nyaman bagi Anda yang ingin merasakan dinginnya angin dari luar.
                Di tempat ini juga terdapat mural - mural karya mahasiswa STIS.
                Jika Anda membawa laptop untuk keperluan diskusi, jangan
                khawatir baterai laptop Anda habis karena di setiap saung
                tersedia stopkontak untuk keperluan tersebut.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Fasilitas"}
              submenu-is-active={"Meja Baca"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default MejaBaca;
