import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuContext from "../../../context/MenuProvider";

const menus = [
  {
    title: "Meja Baca",
    path: "/baca",
  },
  {
    title: "Koleksi Cetak",
    path: "/koleksi-cetak",
  },
  {
    title: "Koleksi Elektronik",
    path: "/koleksi-elektronik",
  },
  {
    title: "Fasilitas Lainnya",
    path: "/fasilitas-lainnya",
  },
];

const ListItemFasilitas = () => {
  const { stateListItem, setStateListItem, setStateAppBar } =
    useContext(MenuContext);
  const navigate = useNavigate();

  const handleChangePage = (path) => {
    navigate(path);
    setStateAppBar({
      subAppBarTentangKami: false,
    });
    setStateListItem({
      listItemFasilitas: false,
      listItemLayanan: false,
    });
  };

  return (
    <List
      sx={
        stateListItem.listItemFasilitas
          ? { display: "block" }
          : { display: "none" }
      }
    >
      {menus.map((menu, key) => (
        <ListItem key={key} disablePadding>
          <ListItemButton
            path={menu.path}
            onClick={(e) =>
              handleChangePage(e.currentTarget.attributes.path.value)
            }
            sx={{
              borderBottom: "1px solid rgb(230,230,230)",
              borderLeft: "1px solid rgb(230,230,230)",
            }}
          >
            <ListItemText primary={menu.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ListItemFasilitas;
