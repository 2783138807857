export const jenis_pustaka = [
  { id: 1, nama: "Skripsi" },
  { id: 2, nama: "Buku" },
  { id: 3, nama: "Jurnal" },
  { id: 4, nama: "Publikasi" },
  { id: 5, nama: "Multimedia" },
  { id: 6, nama: "Terbitan Berkala" },
  { id: 7, nama: "Karya Ilmiah" },
  { id: 8, nama: "Laporan" },
  { id: 9, nama: "EBook" },
  { id: 10, nama: "EJurnal" },
  { id: 11, nama: "Jurnal Internasional" },
];

export const klasifikasi_skripsi = [
  { id: 1, nama: "D4 Komputasi Statistik" },
  { id: 2, nama: "D4 Statistika" },
  { id: 3, nama: "D3 Statistika" },
  { id: 4, nama: "Sistem Informasi" },
  { id: 5, nama: "Sains Data" },
  { id: 6, nama: "Sosial Kependudukan" },
  { id: 7, nama: "Sosial Ekonomi" },
];

export const klasifikasi_buku = [
  { id: 1, nama: "ABS" },
  { id: 2, nama: "Agama" },
  { id: 3, nama: "Bahasa" },
  { id: 4, nama: "BI" },
  { id: 5, nama: "Filsafat & Psikologi" },
  { id: 6, nama: "Geografi & Sejarah" },
  { id: 7, nama: "Ilmu Murni" },
  { id: 8, nama: "Ilmu Sosial" },
  { id: 9, nama: "Ilmu Terapan (Teknologi)" },
  { id: 10, nama: "Kesenian" },
  { id: 11, nama: "Kesusasteraan" },
  { id: 12, nama: "Komputer & Penelitian" },
];
