import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { jenis_pustaka } from "../data/Koleksi";
import { useEffect } from "react";
import { useContext } from "react";
import KoleksiContext from "../context/KoleksiProvider";
import MenuContext from "../context/MenuProvider";
import { useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";

const IndeterminateCheckbox = (props) => {
  const {
    jenisPustakaSelected,
    klasifikasiSelected,
    setKlasifikasiSelected,
    klasifikasiList,
    setKlasifikasiList,
    resetCheckboxTrigger,
  } = useContext(KoleksiContext);
  const { stateFilterKoleksi, setStateFilterKoleksi } = useContext(MenuContext);
  const [checked, setChecked] = React.useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
  });

  useEffect(() => {
    getChecked();
    console.log("stateFilter : ", stateFilterKoleksi);
    console.log("klasifikasi : ", klasifikasiSelected);
    console.log("checked : ", checked);
  }, [klasifikasiSelected, stateFilterKoleksi]);

  useEffect(() => {
    getKlasifikasiKoleksi();
    setChecked({
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
      14: false,
    });
  }, [jenisPustakaSelected]);

  const getKlasifikasiKoleksi = async () => {
    try {
      if (jenisPustakaSelected !== null) {
        if (jenisPustakaSelected.label !== "Semua") {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/klasifikasi?jenis-pustaka=${jenisPustakaSelected.label}`
          );
          setKlasifikasiList(response.data);
        } else {
          setKlasifikasiList(jenis_pustaka);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChangeChild = (event, id) => {
    setChecked({ ...checked, [id]: event.target.checked });
    if (event.target.checked) {
      setKlasifikasiSelected((prevKlasifikasiSelected) => {
        const newKlasifikasiSelected = [...prevKlasifikasiSelected];
        newKlasifikasiSelected[id] = event.target.value;
        return newKlasifikasiSelected;
      });
    } else {
      setKlasifikasiSelected((prevKlasifikasiSelected) => {
        const newKlasifikasiSelected = [...prevKlasifikasiSelected];
        newKlasifikasiSelected[id] = "";
        return newKlasifikasiSelected;
      });
    }
  };

  const getChecked = () => {
    if (klasifikasiList !== null && klasifikasiSelected.length !== 0) {
      klasifikasiSelected.map((item, key) => {
        if (klasifikasiSelected[key] == "") {
          handleToggleCheckbox(key, false);
        } else {
          handleToggleCheckbox(key, true);
        }
      });
    }
  };

  const handleToggleCheckbox = (id, state) => {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [id]: state,
    }));
  };

  return (
    <div>
      {jenisPustakaSelected !== null &&
        jenisPustakaSelected.label !== "Semua" && (
          <>
            <Typography>{jenisPustakaSelected.label}</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              {klasifikasiList !== null &&
                klasifikasiList.map((item, key) => (
                  <FormControlLabel
                    key={key}
                    label={item.nama}
                    control={
                      <Checkbox
                        value={item.id}
                        checked={checked[key]}
                        onChange={(e) => handleChangeChild(e, key)}
                      />
                    }
                  />
                ))}
            </Box>
          </>
        )}
    </div>
  );
};

export default IndeterminateCheckbox;
