import NoMealsIcon from '@mui/icons-material/NoMeals';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import NoBackpackIcon from '@mui/icons-material/NoBackpack';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

export const aturan = [
  { 
    image: <NoMealsIcon sx={{ fontSize: 40 }}/>,
    message: "Dilarang membawa makan dan minum ke dalam perpustakaan", 
  },
  { 
    image: <VoiceOverOffIcon sx={{ fontSize: 40 }}/>,
    message: "Dilarang berisik. Berdiskusi hanya diperbolehkan di saung", 
  },
  {
    image: <NoBackpackIcon sx={{ fontSize: 40 }}/>,
    message: "Tas, jaket, topi, dan sejenisnya disimpan di dalam loker",
  },
  {
    image: <ShoppingCartCheckoutIcon sx={{ fontSize: 40 }}/>,
    message: "Setelah membaca, taruh buku pada troli jika lupa asal bukunya",
  },
  { 
    image: <InsertPageBreakIcon sx={{ fontSize: 40 }}/>,
    message: "Jangan merusak buku, seperti melipat, mencoret, menyobek, dsb",
  },
  { 
    image: <NoPhotographyIcon sx={{ fontSize: 40 }}/>,
    message: "Dilarang memotret/merekam, kecuali diizinkan petugas perpustakaan",
  },
];