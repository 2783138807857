import { useContext, useEffect, useState } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContext from "../../context/admin/DialogProvider";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import { Box, DialogContent, Stack, Typography } from "@mui/material";

const KembalikanPeminjamanDialog = () => {
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const {
    idPeminjaman,
    kembalikanPeminjamanDialogOpen,
    setKembalikanPeminjamanDialogOpen,
  } = useContext(DialogContext);
  const [peminjaman, setPeminjaman] = useState(null);

  useEffect(() => {
    if (idPeminjaman) getDetailPeminjaman();
  }, [kembalikanPeminjamanDialogOpen]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailPeminjaman = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/peminjaman/${idPeminjaman}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setPeminjaman(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        setKembalikanPeminjamanDialogOpen(false);
      }
    }
  };

  const handleClose = () => {
    setKembalikanPeminjamanDialogOpen(false);
  };

  const handleCloseAndPerpanjangPeminjaman = async () => {
    try {
      const response = await axiosJWT.put(
        `${process.env.REACT_APP_API_URL}/a/peminjaman/kembali/${idPeminjaman}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      setKembalikanPeminjamanDialogOpen(false);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        setKembalikanPeminjamanDialogOpen(false);
      }
    }
  };

  return (
    <Dialog
      open={kembalikanPeminjamanDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" align="center">
        Konfirmasi Pengembalian
      </DialogTitle>
      <DialogContent>
        {peminjaman !== null && (
          <>
            <Stack direction="row" columnGap={2} sx={{ marginBottom: "10px" }}>
              <img
                src={`https://stis.ac.id/sipadu/mahasiswa/foto_mhs/${peminjaman.username}.jpg`}
                alt={`${peminjaman.username}.jpg`}
                width="150"
              />
              <Box>
                <Typography>NIM : {peminjaman.username}</Typography>
                <Typography>NAMA : {peminjaman.nama}</Typography>
                <Typography>JUDUL: {peminjaman.judul}</Typography>
              </Box>
            </Stack>
            <Typography>
              Apakah Anda yakin ingin melanjutkan aksi ini?
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Tidak
        </Button>
        <Button
          onClick={handleCloseAndPerpanjangPeminjaman}
          variant="contained"
          autoFocus
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KembalikanPeminjamanDialog;
