import {
	Avatar,
	Box,
	Typography,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Tooltip,
	IconButton,
} from '@mui/material';
import '../css/Profil.css';
import { useContext } from 'react';
import MenuContext from '../context/MenuProvider';
import TokenContext from '../context/TokenProvider';
import MessageContext from '../context/MessageProvider';
import color from '../theme/Color';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}));

const ProfilScreenMedium = () => {
	const navigate = useNavigate();
	const { stateUserMenu, setStateUserMenu } = useContext(MenuContext);
	const { accessToken, setAccessToken, decodedData, setDecodedData } =
		useContext(TokenContext);
	const [profil, setProfil] = useState(null);
	const [foto, setFoto] = useState('');
	const { setMessage } = useContext(MessageContext);

	const getProfil = async () => {
		try {
			const response = await axiosJWT.get(
				`${process.env.REACT_APP_API_URL}/auth/profil`,
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				}
			);
			setProfil(response.data);
			console.log(response.data);
		} catch (error) {
			console.log(error.response);
		}
	};

	const getFotoProfil = async () => {
		try {
			const response = await axiosJWT.get(
				`${process.env.REACT_APP_API_URL}/auth/foto`,
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				}
			);
			setFoto(response.data.gambar);
		} catch (error) {
			console.log(error.response);
		}
	};

	useEffect(() => {
		getProfil();
		getFotoProfil();
	}, []);

	const axiosJWT = axios.create();

	axiosJWT.interceptors.request.use(
		async (config) => {
			const currentDate = new Date();
			if (decodedData.exp * 1000 < currentDate.getTime()) {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/auth/refresh-token`
				);
				config.headers.Authorization = `Bearer ${response.data.accessToken}`;
				setAccessToken(response.data.accessToken);
				setDecodedData(jwtDecode(response.data.accessToken));
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setStateUserMenu({ [anchor]: open });
	};

	const handleLogout = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.delete(
				`${process.env.REACT_APP_API_URL}/auth/logout`
			);
			setMessage({ isOpen: true, content: response.data.message });
			setAccessToken(null);
			navigate('/');
		} catch (error) {
			if (error.response) {
				console.log(error.response);
			}
		}
	};

	const handleAdmin = () => {
		navigate('/a/dashboard');
	};

	return (
		<Box
			sx={
				stateUserMenu.profil
					? {
							width: '385px',
							height: 'fit-content',
							maxHeight: '75vh',
							position: 'fixed',
							zIndex: '1000',
							right: '0',
							top: '64px',
							backgroundColor: color.white_transparent,
							backdropFilter: 'blur(10px)',
							overflow: 'auto',
							borderRadius: '0 0 10px 10px',
							boxShadow: '0 0 15px #ADB5BD',
					  }
					: { display: 'none' }
			}
			onMouseOver={toggleDrawer('profil', true)}
			onMouseOut={toggleDrawer('profil', false)}
		>
			<Box>
				<Avatar
					sx={{
						margin: 'auto',
						marginTop: '2em',
						width: '100px',
						height: '100px',
					}}
					alt="Remy Sharp"
					src={foto}
				/>
				<Typography
					sx={{
						marginTop: '22px',
						textAlign: 'center',
						fontSize: '20px',
						fontWeight: 'bold',
					}}
				>
					{profil !== null && profil.nama}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{profil !== null && profil.email}
				</Typography>
				<Box sx={{ width: '328px', margin: '16px auto' }}>
					<Typography sx={{ fontWeight: 'bold', margin: '18px 0 8px' }}>
						Profil
					</Typography>
					<TableContainer
						component={Paper}
						sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}
					>
						<Table padding="none">
							<TableBody>
								<TableRow>
									<TableCell sx={{ fontSize: '16px', border: 'none' }}>
										Username
									</TableCell>
									<TableCell
										sx={{ fontSize: '16px', border: 'none', minWidth: '160px' }}
									>
										: {profil !== null && profil.username}
									</TableCell>
								</TableRow>
							</TableBody>
							{profil && profil.inPerpus && (
								<TableBody>
									<TableRow>
										<TableCell sx={{ fontSize: '16px', border: 'none' }}>
											Kunci Loker
										</TableCell>
										<TableCell
											sx={{
												fontSize: '16px',
												border: 'none',
												minWidth: '160px',
											}}
										>
											:{' '}
											{profil.noKunci !== null
												? profil.noKunci
												: 'Tidak meminjam'}
										</TableCell>
									</TableRow>
								</TableBody>
							)}
							<TableBody>
								<TableRow>
									<TableCell sx={{ fontSize: '16px', border: 'none' }}>
										Kuota Unduh
									</TableCell>
									<TableCell sx={{ fontSize: '16px', border: 'none' }}>
										: {profil !== null && profil.kuotaUnduh}
										<BootstrapTooltip title="Kuota unduh merupakan batas baca E-Book dalam sehari">
											<IconButton>
												<InfoIcon />
											</IconButton>
										</BootstrapTooltip>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Box sx={{ textAlign: 'center' }}>
					{decodedData.role === 'admin' && (
						<Button
							variant="contained"
							onClick={handleAdmin}
							sx={{ margin: '8px 8px 24px' }}
						>
							Dashboard Administrasi
						</Button>
					)}
					<Button
						variant="outlined"
						color="error"
						onClick={handleLogout}
						sx={{ margin: '8px 8px 24px' }}
					>
						Log Out
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ProfilScreenMedium;
