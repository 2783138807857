import {
  Box,
  IconButton,
  Typography,
  Divider,
  Drawer,
  Stack,
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import "../css/Profil.css";
import { useContext, useEffect, useState } from "react";
import MenuContext from "../context/MenuProvider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import KoleksiContext from "../context/KoleksiProvider";
import axios from "axios";
import TokenContext from "../context/TokenProvider";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import MessageContext from "../context/MessageProvider";
import InfoIcon from "@mui/icons-material/Info";
import RoomIcon from "@mui/icons-material/Room";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const KoleksiDetail = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("sm"));
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const { stateKoleksi, setStateKoleksi } = useContext(MenuContext);
  const { setStateQRScanner, setStateShareKoleksiDialog } =
    useContext(MenuContext);
  const { idKoleksi, setNoInduk } = useContext(KoleksiContext);
  const { message, setMessage } = useContext(MessageContext);
  const navigate = useNavigate();
  const [koleksi, setKoleksi] = useState(null);
  const [isSimpan, setIsSimpan] = useState(false);
  const [profil, setProfil] = useState(null);

  useEffect(() => {
    getDetailKoleksi();
    console.log("koleksiDetail ", koleksi);
    if (accessToken !== null) {
      getDetailPenandaKoleksi();
    }
    getProfil();
  }, [idKoleksi]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateKoleksi(open);
  };

  const handlePinjam = () => {
    setStateKoleksi(false);
    setStateQRScanner(true);
  };

  const handleBaca = (noInduk) => {
    setNoInduk(noInduk);
    navigate("/koleksi/baca");
  };

  const handleDenah = (rak) => {
    setStateKoleksi(false);
    navigate(`/denah/${rak}`);
  };

  const handleShare = () => {
    setStateShareKoleksiDialog(true);
  };

  const getProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/profil`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setProfil(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getDetailKoleksi = async () => {
    try {
      if (idKoleksi !== 0) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/koleksi/${idKoleksi}`
        );
        setKoleksi(response.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const getDetailPenandaKoleksi = async () => {
    try {
      if (idKoleksi !== 0) {
        const response = await axiosJWT.get(
          `${process.env.REACT_APP_API_URL}/penanda-koleksi/${idKoleksi}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsSimpan(response.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const simpanPenandaKoleksi = async () => {
    try {
      if (idKoleksi !== 0 && accessToken !== null) {
        const response = await axiosJWT.post(
          `${process.env.REACT_APP_API_URL}/penanda-koleksi`,
          {
            uuidKoleksi: idKoleksi,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsSimpan(true);
        setMessage({ isOpen: true, content: response.data.message });
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const hapusPenandaKoleksi = async () => {
    try {
      if (idKoleksi !== 0 && accessToken !== null) {
        const response = await axiosJWT.delete(
          `${process.env.REACT_APP_API_URL}/penanda-koleksi/${idKoleksi}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsSimpan(false);
        setMessage({ isOpen: true, content: response.data.message });
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Drawer
      anchor={md ? "right" : "top"}
      open={stateKoleksi}
      onClose={toggleDrawer(false)}
    >
      <Box
        sx={
          md
            ? { backgroundColor: "white", height: "100vh", width: "428px" }
            : { backgroundColor: "white", height: "100vh" }
        }
      >
        {koleksi !== null && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "65px" }}
            >
              <IconButton>
                <ShareIcon onClick={handleShare} />
              </IconButton>
              <Typography variant="h6">KOLEKSI</Typography>
              <Stack direction="row">
                {isSimpan ? (
                  <IconButton onClick={hapusPenandaKoleksi}>
                    <BookmarkIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={simpanPenandaKoleksi}>
                    <BookmarkBorderIcon />
                  </IconButton>
                )}
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>

            <Divider />

            <Box
              sx={{
                paddingY: "30px",
              }}
            >
              <Container maxWidth="lg">
                <Typography
                  sx={{ fontSize: "14px" }}
                  color="InactiveCaptionText"
                >
                  {koleksi.namaJenisPustaka}, {koleksi.namaKlasifikasi}{" "}
                  {(koleksi.namaJenisPustaka === "Buku" ||
                    koleksi.namaJenisPustaka === "Publikasi") && (
                    <span style={{ color: "green" }}>Dapat Dipinjam</span>
                  )}
                  {koleksi.isDibaca && (
                    <span style={{ color: "red" }}>PDF</span>
                  )}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {koleksi.judul}
                </Typography>
                <Typography>{koleksi.penulis}</Typography>

                <Stack
                  sx={{ marginTop: "10px", textAlign: "center" }}
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box>
                    <Typography>{koleksi.jumlahDibaca}</Typography>
                    <Typography color="InactiveCaptionText">dibaca</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "3px",
                      backgroundColor: "rgb(180,180,180)",
                    }}
                  ></Box>
                  <Box>
                    <Typography>{koleksi.jumlahDisimpan}</Typography>
                    <Typography color="InactiveCaptionText">
                      disimpan
                    </Typography>
                  </Box>
                </Stack>

                <Typography
                  sx={{ fontWeight: "bolder", margin: "20px 0 10px" }}
                >
                  Metadata
                </Typography>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table padding="none">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            border: "none",
                            verticalAlign: "top",
                            minWidth: "112px",
                          }}
                        >
                          Penerbit
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px", border: "none" }}>
                          : {koleksi.infoTambahan.penerbit}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {!["Skripsi", "EJurnal", "EBook"].includes(
                      koleksi.namaJenisPustaka
                    ) && (
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px", border: "none" }}>
                            Kode Rak
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "16px",
                              border: "none",
                              height: "23px",
                            }}
                          >
                            : {koleksi.kodeRak}
                            <IconButton
                              onClick={(e) => handleDenah(koleksi.kodeRak)}
                            >
                              <RoomIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px", border: "none" }}>
                          Nomor Induk
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px", border: "none" }}>
                          : {koleksi.noInduk}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {koleksi.namaJenisPustaka === "Skripsi" && (
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px", border: "none" }}>
                            Pembimbing
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px", border: "none" }}>
                            : {koleksi.infoTambahan.pembimbing}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {profil &&
                      ["Skripsi", "EJurnal", "EBook"].includes(
                        koleksi.namaJenisPustaka
                      ) && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{ fontSize: "16px", border: "none" }}
                            >
                              Kuota unduh
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "16px", border: "none" }}
                            >
                              : {profil.kuotaUnduh}
                              <BootstrapTooltip title="Kuota unduh merupakan batas baca E-Book dalam sehari">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </BootstrapTooltip>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    {/* <TableBody>
                        <TableRow>
                          <TableCell sx={{fontSize: "16px", border: "none"}}>Harga</TableCell>
                          <TableCell sx={{fontSize: "16px", border: "none"}}>: Rp. {koleksi.infoTambahan.harga}</TableCell>
                        </TableRow>
                    </TableBody> */}
                  </Table>
                </TableContainer>
                {koleksi.namaJenisPustaka === "EBook" && (
                  <>
                    <Typography
                      sx={{ fontWeight: "bolder", margin: "20px 0 10px" }}
                    >
                      Sinopsis
                    </Typography>
                    <Typography align="justify">
                      {koleksi.infoTambahan.abstrak}
                    </Typography>
                    <Typography sx={{ marginTop: "10px" }}>
                      Kata kunci : {koleksi.infoTambahan.keyword}
                    </Typography>
                  </>
                )}
                {(koleksi.namaJenisPustaka === "EJurnal" ||
                  koleksi.namaJenisPustaka === "Skripsi") && (
                  <>
                    <Typography
                      sx={{ fontWeight: "bolder", margin: "20px 0 10px" }}
                    >
                      Abstrak
                    </Typography>
                    <Typography align="justify">
                      {koleksi.infoTambahan.abstrak}
                    </Typography>
                    <Typography sx={{ marginTop: "10px" }}>
                      Kata kunci : {koleksi.infoTambahan.keyword}
                    </Typography>
                  </>
                )}
                {(koleksi.namaJenisPustaka === "Buku" ||
                  koleksi.namaJenisPustaka === "Publikasi") && (
                  <Button
                    variant="contained"
                    sx={{ marginTop: "10px" }}
                    size="small"
                    onClick={handlePinjam}
                  >
                    Pinjam
                  </Button>
                )}
                {koleksi.isDibaca && (
                  <Button
                    variant="contained"
                    sx={{ marginTop: "10px" }}
                    size="small"
                    onClick={() => handleBaca(koleksi.noInduk)}
                  >
                    Baca
                  </Button>
                )}
              </Container>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default KoleksiDetail;
