import { Typography } from "@mui/material";
import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";

const COLORS = ["#EDA40C", "#F7430C", "#E000C2", "#2D05FA", "#00AEF4", "#00ac3e"];

const renderCustomizedLabel = ({ percent }) => {
  return `${(percent * 100).toFixed(0)}%`;
};

const renderColorfulLegendText = (value, entry) => {
  return <span style={{ color: "black" }}>{value}</span>;
};

export default class CustomPieChart extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={100}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label={renderCustomizedLabel}
            // label
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend formatter={renderColorfulLegendText} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
