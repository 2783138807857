import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../css/Profil.css";
import { useContext, useState, useEffect } from "react";
import MenuContext from "../context/MenuProvider";
import TokenContext from "../context/TokenProvider";
import NotificationCard from "../components/NotificationCard";
import axios from "axios";
import jwtDecode from "jwt-decode";

const notifications = [
  {
    type: "Pengingat",
    date: "12-02-2023",
    title: "Pengembalian Kunci",
    content: "Terima kasih telah berkunjung, jangan lupa kembalikan kunci ya.",
  },
  {
    type: "Info",
    date: "12-02-2023",
    title: "Pengadaan Buku",
    content:
      "Halo teman-teman, kini skripsi terbaru tahun ajaran 2020-2021 sudah tersedia di SIMPus. Untuk membacanya pastikan install aplikasi SIMPus e-Reader di Playstore.",
  },
  {
    type: "Pengingat",
    date: "12-02-2023",
    title: "Pengembalian Buku",
    content:
      "Ini hari terakhir pengembalian buku yang kamu pinjam. Ayo segera kembalikan buku di perpustakaan.",
  },
];
const NotifikasiScreenSmall = () => {
  const { stateUserMenu, setStateUserMenu } = useContext(MenuContext);

  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const [notifikasi, setNotifikasi] = useState(null);

  useEffect(() => {
    getNotifikasi();
  }, []);

  const getNotifikasi = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/notifikasi?role=intern`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setNotifikasi(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateUserMenu({ [anchor]: open });
  };
  return (
    <Drawer
      anchor={"top"}
      open={stateUserMenu["notifikasi"]}
      onClose={toggleDrawer("notifikasi", false)}
    >
      <Box sx={{ backgroundColor: "white", height: "100vh" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "65px" }}
        >
          <Box sx={{ width: "40px", height: "40px" }}></Box>
          <Typography
            variant="h6"
            sx={{ marginTop: "5px", textAlign: "center" }}
          >
            NOTIFIKASI
          </Typography>
          <IconButton onClick={toggleDrawer("notifikasi", false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <Box sx={{ padding: "20px" }}>
          {/* <Typography sx={{ marginBottom: "10px" }}>Hari Ini</Typography> */}
          {notifikasi !== null &&
            notifikasi.map((element, key) => (
              <NotificationCard
                key={key}
                tipe={element.tipe}
                tanggal={element.tanggal}
                judul={element.judul}
                konten={element.konten}
              />
            ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default NotifikasiScreenSmall;
