import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import { AdminLayout } from "../../../layout/admin";
import axios from "axios";
import jwtDecode from "jwt-decode";

const CreatePengumuman = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [judul, setJudul] = useState("");
  const [konten, setKonten] = useState("");
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState("");

  useEffect(() => {
    setNavTo("/a/pengumuman/create");
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeJudul = (judul) => {
    setJudul(judul);
  };

  const handleChangeKonten = (konten) => {
    setKonten(konten);
  };

  const handleChangeLoadFile = (event) => {
    setFile(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmitPengumuman = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("judul", judul);
      formData.append("konten", konten);
      formData.append("file", file);
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/pengumuman`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/pengumuman");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Pengumuman
        </Typography>
        <form onSubmit={handleSubmitPengumuman}>
          <Box
            sx={{ marginTop: "30px", display: "flex", flexDirection: "column" }}
          >
            <TextField
              id="outlined-basic"
              label="Judul"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => handleChangeJudul(e.currentTarget.value)}
            />
            <TextField
              id="outlined-basic"
              label="Konten"
              variant="outlined"
              multiline
              rows={7}
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => handleChangeKonten(e.currentTarget.value)}
            />
            <Button
              sx={{ width: "fit-content", marginBottom: "20px" }}
              variant="contained"
              component="label"
            >
              Upload File Gambar
              <input hidden type="file" onChange={handleChangeLoadFile} />
            </Button>
            {preview ? (
              <img
                style={{ marginBottom: "20px", width: "200px" }}
                src={preview}
                alt="Gambar Preview"
              />
            ) : (
              ""
            )}
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "fit-content" }}
            >
              Tambah
            </Button>
          </Box>
        </form>
      </Box>
    </AdminLayout>
  );
};

export default CreatePengumuman;
