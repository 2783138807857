import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import { AdminLayout } from "../../../layout/admin";
import axios from "axios";
import jwtDecode from "jwt-decode";

const objekDenah = [
  { type: "Admin", warna: "rgb(124, 233, 255)" },
  { type: "Dinding", warna: "rgb(161, 152, 127)" },
  { type: "Komputer", warna: "rgb(244, 105, 32)" },
  { type: "Lesehan", warna: "rgb(255, 133, 103)" },
  { type: "Lift", warna: "rgb(164, 113, 48)" },
  { type: "Meja", warna: "rgb(246, 180, 50)" },
  { type: "Pintu", warna: "rgb(254, 95, 95)" },
  { type: "RakBuku", warna: "rgb(232, 199, 20)" },
  { type: "Resepsionis", warna: "rgb(98, 247, 217)" },
  { type: "Saung", warna: "rgb(236, 254, 95)" },
  { type: "Sofa", warna: "rgb(255, 251, 245)" },
];

const CreateDenahPerpus = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [classObjek, setClassObjek] = useState("");
  const [warna, setWarna] = useState("");
  const [nama, setNama] = useState("");
  const [tinggi, setTinggi] = useState(0);
  const [lebar, setLebar] = useState(0);
  const [deskripsi, setDeskripsi] = useState("");

  useEffect(() => {
    setNavTo("/a/denah/create");
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeClassObjek = (event) => {
    setClassObjek(event.target.value);
    for (let objek of objekDenah) {
      if (objek.type === event.target.value) setWarna(objek.warna);
    }
  };

  const handleChangeNama = (event) => {
    setNama(event.target.value);
  };

  const handleChangeTinggi = (event) => {
    setTinggi(event.target.value * 15);
  };

  const handleChangeLebar = (event) => {
    setLebar(event.target.value * 15);
  };

  const handleChangeDeskripsi = (event) => {
    setDeskripsi(event.target.value);
  };

  const handleSubmitObjekDenahPerpus = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/denah`,
        {
          classObjek: classObjek,
          nama: nama,
          tinggi: tinggi,
          lebar: lebar,
          deskripsi: deskripsi,
          warna: warna,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/denah");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Objek Denah Perpus
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmitObjekDenahPerpus}>
            <Box sx={{ display: "grid", gap: "20px" }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Tipe</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={classObjek}
                  label="Tipe"
                  onChange={handleChangeClassObjek}
                >
                  <MenuItem value={"RakBuku"}>RakBuku</MenuItem>
                  <MenuItem value={"Meja"}>Meja</MenuItem>
                  <MenuItem value={"Komputer"}>Komputer</MenuItem>
                  <MenuItem value={"Saung"}>Saung</MenuItem>
                  <MenuItem value={"Pintu"}>Pintu</MenuItem>
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  <MenuItem value={"Resepsionis"}>Resepsionis</MenuItem>
                  <MenuItem value={"Lift"}>Lift</MenuItem>
                  <MenuItem value={"Sofa"}>Sofa</MenuItem>
                  <MenuItem value={"Lesehan"}>Lesehan</MenuItem>
                  <MenuItem value={"Dinding"}>Dinding</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Nama Objek"
                variant="outlined"
                onChange={handleChangeNama}
              />
              <FormControl>
                <InputLabel>Tinggi</InputLabel>
                <OutlinedInput
                  id="outlined-basic"
                  label="Tinggi"
                  type="number"
                  variant="outlined"
                  onChange={handleChangeTinggi}
                />
              </FormControl>
              <FormControl>
                <InputLabel>Lebar</InputLabel>
                <OutlinedInput
                  id="outlined-basic"
                  label="Lebar"
                  type="number"
                  variant="outlined"
                  onChange={handleChangeLebar}
                />
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Deskripsi"
                variant="outlined"
                multiline
                rows={5}
                onChange={handleChangeDeskripsi}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "fit-content" }}
              >
                Tambah
              </Button>
            </Box>
          </form>
          <Typography variant="h6" sx={{ marginTop: "30px" }}>
            Preview
          </Typography>
          <Box sx={{ marginTop: "10px", position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: `${lebar}px`,
                height: `${tinggi}px`,
                backgroundColor: warna,
                border: "1px solid black",
                display: "grid",
                placeItems: "center",
              }}
            >
              <p style={{ fontSize: "12px" }}>{nama}</p>
            </div>
          </Box>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CreateDenahPerpus;
