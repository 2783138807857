import { Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import { AdminLayout } from "../../../layout/admin";
import axios from "axios";
import jwtDecode from "jwt-decode";

const EditPengumuman = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [pengumuman, setPengumuman] = useState(null);
  const [judul, setJudul] = useState("");
  const [konten, setKonten] = useState("");
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState("");

  useEffect(() => {
    setNavTo("/a/pengumuman");
    refreshToken();
    getDetailPengumuman();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeJudul = (judul) => {
    setJudul(judul);
  };

  const handleChangeKonten = (konten) => {
    setKonten(konten);
  };

  const handleChangeLoadFile = (event) => {
    setFile(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  const getDetailPengumuman = async () => {
    try {
      const url = window.location.pathname.split("/");
      const id = url[url.length - 1];
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/pengumuman/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setPengumuman(response.data);
      setJudul(response.data.judul);
      setKonten(response.data.konten);
      setPreview(
        `${process.env.REACT_APP_API_URL}/img/pengumuman/${response.data.gambar}`
      );
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleSubmitPengumuman = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("judul", judul);
      formData.append("konten", konten);
      formData.append("file", file);
      const response = await axiosJWT.put(
        `${process.env.REACT_APP_API_URL}/a/pengumuman/${pengumuman.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/pengumuman");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Edit Pengumuman
        </Typography>
        <form onSubmit={handleSubmitPengumuman}>
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {pengumuman !== null ? (
              <TextField
                id="outlined-basic"
                label="Judul"
                defaultValue={pengumuman.judul}
                variant="outlined"
                sx={{ width: "100%", marginBottom: "20px" }}
                onChange={(e) => handleChangeJudul(e.currentTarget.value)}
              />
            ) : (
              <Skeleton height={60} />
            )}
            {pengumuman !== null ? (
              <TextField
                id="outlined-basic"
                label="Konten"
                defaultValue={pengumuman.konten}
                variant="outlined"
                multiline
                rows={7}
                sx={{ width: "100%", marginBottom: "20px" }}
                onChange={(e) => handleChangeKonten(e.currentTarget.value)}
              />
            ) : (
              <Skeleton height={200} />
            )}
            <Button
              sx={{ width: "fit-content", marginBottom: "20px" }}
              variant="contained"
              component="label"
            >
              Upload File Gambar
              <input hidden type="file" onChange={handleChangeLoadFile} />
            </Button>
            {preview ? (
              <img
                style={{ marginBottom: "20px", width: "200px" }}
                src={preview}
                alt="Gambar Preview"
              />
            ) : (
              <Skeleton width={200} height={150} />
            )}
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "fit-content" }}
            >
              Edit
            </Button>
          </Box>
        </form>
      </Box>
    </AdminLayout>
  );
};

export default EditPengumuman;
