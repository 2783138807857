import {
  CardMedia,
  Typography,
  Box,
  Link,
  Container,
  Stack,
} from "@mui/material";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";

const KoleksiElektronik = () => {
  return (
    <MyLayout title={"Koleksi Elektronik"} menu={"Tentang Kami"} submenu={"Koleksi Elektronik"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold"}}>
                Koleksi Elektronik
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/ebook.jpg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px", marginTop: "8px" }}>
                Koleksi elektronik merupakan fasilitas yang sangat berharga dalam perpustakaan modern. 
                Fasilitas koleksi elektronik yang disediakan perpustakaan Polstat STIS meliputi e-book
                , jurnal elektronik, dan skripsi mahasiswa sumber daya digital lainnya. 
              </Typography>
              <Typography align="justify" sx={{ fontSize: "14px", marginTop: "8px" }}>
                Koleksi ini menawarkan akses yang lebih luas dan fleksibel. Dengan sumber daya digital, 
                pengunjung dapat mengakses berbagai bahan pustaka dari mana saja dan kapan saja. Mereka 
                dapat membaca e-book atau mengakses jurnal elektronik melalui perangkat elektronik seperti 
                komputer, tablet, atau smartphone. Jika Anda ingin menjelajahi koleksi buku yang kami sediakan, 
                Anda dapat menjelajahinya <Link href="/koleksi">di sini</Link>.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Fasilitas"}
              submenu-is-active={"Koleksi Elektronik"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default KoleksiElektronik;
