import { MyLayout } from "../../layout";
import { useContext, useState, useEffect } from "react";
import { Typography, Box, Container, Stack, Skeleton } from "@mui/material";
import color from "../../theme/Color";
import RightSideBarBantuan from "../../components/RightSideBarBantuan";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import DenahMap from "../../components/DenahMap";

function numberOnly(string) {
  var pattern = /^\d+$/;
  return pattern.test(string);
}

const Denah = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [denah, setDenah] = useState(null);
  const url = window.location.pathname.split("/");
  const [highlight, setHighlight] = useState(url[url.length - 1]);

  useEffect(() => {
    setNavTo("/denah");
    getDenahPerpus();
    if (highlight === "denah") {
      setHighlight(null);
    } else if (highlight === "BI") {
      setHighlight("BI Corner");
    } else {
      const noRak = highlight.match(/\d+/g)?.join("");
      const intNoRak = parseInt(noRak, 10);
      if (!isNaN(intNoRak)) {
        setHighlight(intNoRak.toString());
      }
    }
    // console.log("Highlight :",numberOnly(highlight))
  }, []);

  const getDenahPerpus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/denah`
      );
      setDenah(response.data);
      if (numberOnly(highlight)) {
        denah.forEach(function (objek) {
          objek.color = "#e0e0e0";
        });
      }
      console.log("denah : ", response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <MyLayout title={"Denah"} menu={"Bantuan"} submenu={"Denah"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
            height: "570px",
          }}
        >
          <Box sx={{ borderRadius: "10px", overflowX: "auto" }}>
            {denah !== null &&
              denah.map((objek, key) => (
                <DenahMap key={key} objek={objek} highlight={highlight} />
              ))}
            {denah === null && <Skeleton width={800} height={500} />}
          </Box>
          {/* rightSideBarbutton */}
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Bantuan
              </Typography>
            </Stack>

            <RightSideBarBantuan menu-is-active={"Denah"} />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default Denah;
