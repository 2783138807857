import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Input, Stack } from "@mui/material";
import { useContext } from "react";
import KoleksiContext from "../context/KoleksiProvider";


const RangeSlider = (props) => {
  const {rangeTahun,setRangeTahun} = useContext(KoleksiContext);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  
  // const [value, setValue] = React.useState([props.tahun-bawah, props.tahun-atas]);
  const minDistance = 1;

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRangeTahun([Math.min(newValue[0], rangeTahun[1] - minDistance), rangeTahun[1]]);
    } else {
      setRangeTahun([rangeTahun[0], Math.max(newValue[1], rangeTahun[0] + minDistance)]);
    }
  };

  const valuetext = (value) => {
    return `${value}`;
  };

  const handleInputChange = (event, index) => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value);
    const newValues = [...rangeTahun];
    newValues[index] = newValue;

    if (index === 0) {
      if (newValue > rangeTahun[1] - minDistance) {
        newValues[0] = rangeTahun[1] - minDistance;
      }
    } else {
      if (newValue < rangeTahun[0] + minDistance) {
        newValues[1] = rangeTahun[0] + minDistance;
      }
    }

    setRangeTahun(newValues);
  };

  const handleBlur = (index) => {
    if (rangeTahun[index] < 1960) {
      setRangeTahun((prevValue) => [1960, prevValue[1]]);
    } else if (rangeTahun[index] > currentYear) {
      setRangeTahun((prevValue) => [prevValue[0], currentYear]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        step={1} 
        marks={true}
        defaultValue={rangeTahun}
        min={1960} 
        max={currentYear}
        getAriaLabel={() => "Tahun"}
        value={rangeTahun}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      <Stack direction="row" justifyContent="space-between">
      <Input
          sx={{ m: 1, width: 55 }}
          value={rangeTahun[0]}
          onChange={(event) => handleInputChange(event, 0)}
          onBlur={() => handleBlur(0)}
          inputProps={{
            step: 1,
            min: 1960,
            max: currentYear,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
        <Input
          sx={{ m: 1, width: 55 }}
          value={rangeTahun[1]}
          onChange={(event) => handleInputChange(event, 1)}
          onBlur={() => handleBlur(1)}
          inputProps={{
            step: 1,
            min: 1960,
            max: currentYear,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
      </Stack>
    </Box>
  );
};

export default RangeSlider;
