import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TokenContext from "../context/TokenProvider";

const GoogleLoginSuccess = () => {
  const navigate = useNavigate();
  const { navTo } = useContext(TokenContext);
  useEffect(() => {
    setTimeout(() => {
      navigate(navTo);
    }, 1000);
  }, []);
  return (
    <Dialog open={true} maxWidth="xs" sx={{ textAlign: "center" }}>
      <DialogTitle>
        <CheckCircleOutlineIcon
          color="success"
          sx={{
            fontSize: "80px",
          }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="h4">Login Sukses</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default GoogleLoginSuccess;
