import React from "react";
import { useContext } from "react";
import {
  Box,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuContext from "../../../context/MenuProvider";
import "../../../css/SideBar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const menus = [
  {
    title: "Fasilitas",
    path: "",
    anchor: "subSideBarFasilitas",
  },
  {
    title: "Layanan",
    path: "",
    anchor: "subSideBarLayanan",
  },
  {
    title: "Tata Tertib",
    path: "/tata-tertib",
    anchor: "",
  },
  {
    title: "Struktur Organisasi",
    path: "/str-org",
    anchor: "",
  },
];

const SubSideBarTentangKami = () => {
  const navigate = useNavigate();
  const { state, setState } = useContext(MenuContext);

  useEffect(() => {}, [state]);

  const toggleDrawerBack = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open, mainSideBar: true });
  };

  const toggleDrawer = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open, subSideBarTentangKami: false });
    if (path !== null) navigate(path);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={toggleDrawerBack("subSideBarTentangKami", false)}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography sx={{ textAlign: "center", paddingY: "10px" }}>
          TENTANG KAMI
        </Typography>
        <IconButton
          onClick={toggleDrawer("subSideBarTentangKami", false, null)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menus.map((menu, key) => (
          <ListItem key={key} disablePadding>
            <ListItemButton
              onClick={
                (menu.title === "Tata Tertib" || menu.title === "Struktur Organisasi")
                  ? toggleDrawer("subSideBarTentangKami", false, menu.path)
                  : toggleDrawer(menu.anchor, true, null)
              }
            >
              {(menu.title === "Tata Tertib" || menu.title === "Struktur Organisasi") && (
                <ListItemText primary={menu.title} />
              )}
              {menu.title !== "Tata Tertib" && menu.title !== "Struktur Organisasi" && (
                <>
                  <ListItemText primary={menu.title} />
                  <Stack>
                    <ArrowForwardIosIcon />
                  </Stack>
                </>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"left"}
      open={state["subSideBarTentangKami"]}
      onClose={toggleDrawer("subSideBarTentangKami", false, null)}
    >
      {list()}
    </Drawer>
  );
};

export default SubSideBarTentangKami;
