import React from "react";
import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Typography,
  Container,
  Button,
  Autocomplete,
  TextField,
  TablePagination,
  Chip,
  Skeleton,
  Alert,
} from "@mui/material";
import { MyLayout } from "../../layout";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KoleksiCard from "../../components/KoleksiCard";
import color from "../../theme/Color";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import RangeSlider from "../../components/RangeSlider";
import { useContext, useState } from "react";
import { useEffect } from "react";
import KoleksiContext from "../../context/KoleksiProvider";
import MenuContext from "../../context/MenuProvider";
import axios from "axios";
import TokenContext from "../../context/TokenProvider";

// const jenis_pustaka = [
//   { id: "0", label: "Semua" },
//   { id: "1", label: "Skripsi" },
//   { id: "2", label: "Buku" },
//   { id: "3", label: "Jurnal" },
//   { id: "4", label: "Publikasi" },
//   { id: "5", label: "Multimedia" },
//   { id: "6", label: "Terbitan Berkala" },
//   { id: "7", label: "Karya Ilmiah" },
//   { id: "8", label: "Laporan" },
//   { id: "9", label: "EBook" },
//   { id: "10", label: "EJurnal" },
//   { id: "11", label: "Jurnal Internasional" },
// ];

const KoleksiBahanPustaka = () => {
  const { setNavTo } = useContext(TokenContext);
  const {
    koleksi,
    setKoleksi,
    keyword,
    setKeyword,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    buttonIsActive,
    setButtonIsActive,
    jenis_pustaka,
    jenisPustakaSelected,
    setJenisPustakaSelected,
    klasifikasiSelected,
    setKlasifikasiSelected,
    klasifikasiList,
    getKoleksi,
  } = useContext(KoleksiContext);
  // const [klasifikasi, setKlasifikasi] = useState([]);//sama kaya klasifikasiSelected tp tipenya string
  const { rangeTahun, setRangeTahun, setIdKoleksi } =
    useContext(KoleksiContext);
  const { setStateFilterKoleksi, setStateKoleksi } = useContext(MenuContext);
  const toggleFilterKoleksi = () => {
    setStateFilterKoleksi(true);
  };
  const [getKoleksiTrigger, setGetKoleksiTrigger] = useState(false);
  const url = window.location.pathname.split("/");
  const idSharedKoleksi = url[url.length - 1];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    setNavTo("/koleksi");
    getKoleksi();
    // console.log("koleksi :", koleksi);
  }, [page, rowsPerPage, getKoleksiTrigger]);

  useEffect(() => {
    if (idSharedKoleksi !== "koleksi") {
      console.log("idsharedkoleksi", idSharedKoleksi);
      setIdKoleksi(idSharedKoleksi);
      setStateKoleksi(true);
    }
  }, []);
  // useEffect(() => {

  // },[
  //   buttonIsActive,
  //   setButtonIsActive,
  //   jenis_pustaka,
  //   jenisPustakaSelected,
  //   setJenisPustakaSelected,
  //   klasifikasiSelected,
  //   setKlasifikasiSelected,
  //   klasifikasiList,
  //   rangeTahun,
  //   setRangeTahun,
  // ]);

  // const getKoleksi = async () => {
  //   let url = `${process.env.REACT_APP_API_URL}/koleksi?page=${page}&size=${rowsPerPage}`;
  //   if (jenisPustakaSelected !== null && jenisPustakaSelected.id !== "0") {
  //     url += `&jenis-pustaka=${jenisPustakaSelected.label}`;
  //   }
  //   if (klasifikasiSelected.length !== 0) {
  //     for (let i = 0; i < klasifikasiSelected.length; i++) {
  //       if (klasifikasiSelected[i] !== "") {
  //         url += `&klasifikasi=${klasifikasiSelected[i]}`;
  //       }
  //     }
  //   }
  //   if (keyword !== "") {
  //     url += `&kata-kunci=${keyword}`;
  //   }
  //   if (buttonIsActive.terbaru) {
  //     url += `&urutan=terbaru`;
  //   }
  //   if (buttonIsActive.banyak_dibaca) {
  //     url += `&urutan=dibaca`;
  //   }
  //   if (rangeTahun[0] !== null && rangeTahun[0] !== 1960) {
  //     url += `&tahun-bawah=${rangeTahun[0]}`;
  //   }
  //   if (rangeTahun[1] !== null && rangeTahun[1] !== currentYear) {
  //     url += `&tahun-atas=${rangeTahun[1]}`;
  //   }

  //   try {
  //     const response = await axios.get(url);
  //     setKoleksi(response.data);
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };

  const skeletonCard = () => {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Skeleton variant="text" sx={{ fontSize: "12px" }} />
          <Skeleton variant="text" sx={{ fontSize: "16px" }} />
          <Skeleton variant="text" sx={{ fontSize: "14px" }} />
        </Stack>
        <Stack textAlign="center">
          <Skeleton variant="text" sx={{ fontSize: "12px" }} />
          <Skeleton variant="text" sx={{ fontSize: "12px" }} />
          <Skeleton variant="text" sx={{ fontSize: "12px" }} />
          <Skeleton variant="text" sx={{ fontSize: "12px" }} />
        </Stack>
      </Stack>
    );
  };

  const handleEnterSubmit = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      setNavTo("/koleksi");
      getKoleksi();
    }
  };

  const handleClickSubmit = () => {
    setNavTo("/koleksi");
    getKoleksi();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickUrutan = (value) => {
    setButtonIsActive({ [value]: true });
  };

  const handleDeleteUrutanChip = () => {
    setButtonIsActive({ ["relevan"]: true });
    setGetKoleksiTrigger((prevChipTrigger) => !prevChipTrigger);
  };

  const handleDeleteBPChip = () => {
    setJenisPustakaSelected(jenis_pustaka[0]);
    setKlasifikasiSelected([]);
    setGetKoleksiTrigger((prevChipTrigger) => !prevChipTrigger);
  };

  const handleDeleteKlasifikasiChip = (event, id) => {
    console.log("id : ", id);
    setKlasifikasiSelected((prevKlasifikasiSelected) => {
      const newKlasifikasiSelected = [...prevKlasifikasiSelected];
      newKlasifikasiSelected[id] = "";
      return newKlasifikasiSelected;
    });
    setGetKoleksiTrigger((prevChipTrigger) => !prevChipTrigger);
    // console.log("klasifikasiSelectedChip : ", klasifikasiSelected);
  };

  const handleDeleteTahunChip = () => {
    setRangeTahun([1960, currentYear]);
    setGetKoleksiTrigger((prevChipTrigger) => !prevChipTrigger);
  };

  return (
    <MyLayout
      title={"Koleksi Bahan Pustaka"}
      menu={"Koleksi"}
      submenu={"Koleksi Bahan Pustaka"}
    >
      <Box
        sx={{
          backgroundColor: color.dark_white,
          marginTop: "-20px",
          paddingY: "20px",
        }}
      >
        <Container maxWidth="lg">
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Telusuri"
              size="small"
              color="primary"
              value={keyword}
              onKeyPress={handleEnterSubmit}
              onChange={(e) => setKeyword(e.currentTarget.value)}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                },
                "&.Mui-focused": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickSubmit}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Chip
              label="Tambah Filter"
              // component="a"
              // href="#basic-chip"
              variant="filled"
              icon={<AddCircleIcon />}
              onClick={toggleFilterKoleksi}
              clickable
              sx={{ marginTop: "10px", marginRight: "5px" }}
            />
            {buttonIsActive.terbaru && (
              <Chip
                label="Terbaru"
                // component="a"
                // href="#basic-chip"
                variant="filled"
                color="primary"
                onDelete={handleDeleteUrutanChip}
                onClick={handleDeleteUrutanChip}
                clickable
                sx={{ marginTop: "10px", marginRight: "5px" }}
              />
            )}
            {buttonIsActive.banyak_dibaca && (
              <Chip
                label="Paling Banyak Dibaca"
                // component="a"
                // href="#basic-chip"
                variant="filled"
                color="primary"
                onDelete={handleDeleteUrutanChip}
                onClick={handleDeleteUrutanChip}
                clickable
                sx={{ marginTop: "10px", marginRight: "5px" }}
              />
            )}
            {jenisPustakaSelected !== null &&
              jenisPustakaSelected.label !== "Semua" && (
                <Chip
                  label={jenisPustakaSelected.label}
                  // component="a"
                  // href="#basic-chip"
                  variant="filled"
                  color="primary"
                  onDelete={handleDeleteBPChip}
                  onClick={handleDeleteBPChip}
                  clickable
                  sx={{ marginTop: "10px", marginRight: "5px" }}
                />
              )}
            {klasifikasiList !== null && klasifikasiSelected.length !== 0 && (
              <>
                {klasifikasiSelected.map(
                  (item, key) =>
                    klasifikasiSelected[key] !== "" && (
                      <Chip
                        id={key}
                        label={klasifikasiList[key].nama}
                        variant="filled"
                        color="primary"
                        onDelete={(e) => handleDeleteKlasifikasiChip(e, key)}
                        onClick={(e) => handleDeleteKlasifikasiChip(e, key)}
                        clickable
                        sx={{ marginTop: "10px", marginRight: "5px" }}
                      />
                    )
                )}
              </>
            )}
            {(rangeTahun[0] > 1960 || rangeTahun[1] < currentYear) && (
              <Chip
                label={rangeTahun[0] + "-" + rangeTahun[1]}
                // component="a"
                // href="#basic-chip"
                variant="filled"
                color="primary"
                onDelete={handleDeleteTahunChip}
                onClick={handleDeleteTahunChip}
                clickable
                sx={{ marginTop: "10px", marginRight: "5px" }}
              />
            )}
          </Box>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: color.light_gray }}>
        <Container
          maxWidth="lg"
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "341px auto" },
            paddingY: "40px",
            gap: "32px",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Typography sx={{ fontSize: "12px", marginBottom: "3px" }}>
              Urutkan Berdasarkan
            </Typography>
            <Box
              sx={{
                borderRadius: "10px",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                // backgroundColor: color.grey,
              }}
            >
              <Button
                size="small"
                color={buttonIsActive.relevan ? "primary" : "inherit"}
                variant="contained"
                urutan={"relevan"}
                onClick={(e) =>
                  handleClickUrutan(e.currentTarget.attributes.urutan.value)
                }
                sx={{
                  borderRadius: "10px 0 0 10px",
                }}
              >
                Relevansi
              </Button>
              <Button
                size="small"
                color={buttonIsActive.terbaru ? "primary" : "inherit"}
                variant="contained"
                urutan={"terbaru"}
                onClick={(e) =>
                  handleClickUrutan(e.currentTarget.attributes.urutan.value)
                }
                sx={{
                  borderRadius: "0",
                }}
              >
                Terbaru
              </Button>
              <Button
                size="small"
                color={buttonIsActive.banyak_dibaca ? "primary" : "inherit"}
                variant="contained"
                urutan={"banyak_dibaca"}
                onClick={(e) =>
                  handleClickUrutan(e.currentTarget.attributes.urutan.value)
                }
                sx={{
                  borderRadius: "0 10px 10px 0",
                }}
              >
                Paling Banyak Dibaca
              </Button>
            </Box>
            <Typography
              sx={{ fontSize: "12px", marginTop: "24px", marginBottom: "3px" }}
            >
              Kategori Bahan Pustaka
            </Typography>
            <Autocomplete
              value={jenisPustakaSelected}
              onChange={(event, newValue) => {
                setJenisPustakaSelected(newValue);
              }}
              size="small"
              disablePortal
              id="combo-box-demo"
              options={jenis_pustaka}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Box sx={{ marginTop: "24px" }}>
              <IndeterminateCheckbox />
            </Box>
            <Typography sx={{ fontSize: "12px", marginTop: "24px" }}>
              Tahun
            </Typography>
            <RangeSlider />
            <Button
              size="small"
              color="primary"
              variant="contained"
              sx={{ marginTop: "24px" }}
              onClick={(e) =>
                setGetKoleksiTrigger((prevChipTrigger) => !prevChipTrigger)
              }
            >
              Terapkan
            </Button>
          </Box>
          <Box
            onLoad={(e) =>
              setGetKoleksiTrigger((prevChipTrigger) => !prevChipTrigger)
            }
          >
            {/* {(koleksi.namaJenisPustaka === "Skripsi" || 
              koleksi.namaJenisPustaka === "Ejurnal" ||
              koleksi.namaJenisPustaka === "EBook") && ( */}
            {/* )} */}
            {koleksi !== null &&
              koleksi.rows.map((element, key) => (
                <KoleksiCard
                  key={key}
                  id={element.id}
                  namaJenisPustaka={element.namaJenisPustaka}
                  namaKlasifikasi={element.namaKlasifikasi}
                  judul={element.judul}
                  penulis={element.penulis}
                  jumlahDibaca={element.jumlahDibaca}
                  jumlahDisimpan={element.jumlahDisimpan}
                  isDapatDipinjam={element.isDapatDipinjam}
                  isDibaca={element.isDibaca}
                />
              ))}
            {koleksi === null && (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: "40px" }}
                  >
                    <Stack>
                      <Skeleton
                        variant="text"
                        width={250}
                        sx={{ fontSize: "12px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={261}
                        sx={{ fontSize: "16px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={220}
                        sx={{ fontSize: "14px" }}
                      />
                    </Stack>
                    <Stack alignItems="center">
                      <Skeleton
                        variant="text"
                        width={20}
                        sx={{ fontSize: "12px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={60}
                        sx={{ fontSize: "12px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={20}
                        sx={{ fontSize: "12px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={60}
                        sx={{ fontSize: "12px" }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </>
            )}
            <Stack direction="row" justifyContent="center">
              <TablePagination
                component="div"
                count={koleksi !== null ? koleksi.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default KoleksiBahanPustaka;
