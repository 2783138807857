import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";

const CreateBahanPustakaWithUploadFile = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    setNavTo("/a/koleksi/create");
    refreshToken();
  }, [fileName]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeLoadFile = (event) => {
    setFileName(event.target.files[0].name);
    setFile(event.target.files[0]);
  };

  const handleSubmitBahanPustaka = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/koleksi/excel`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/koleksi");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" columnGap={3}>
        <form onSubmit={handleSubmitBahanPustaka} style={{ width: "300px" }}>
          <Box sx={{ display: "grid", gap: "20px" }}>
            <Link
              to="/files/template.xlsx"
              download="Template-Form-Input"
              target="_blank"
              rel="noreferrer"
            >
              Unduh File Template
            </Link>
            <Button
              sx={{ width: "fit-content" }}
              variant="contained"
              component="label"
            >
              {fileName === null ? "Unggah File Excel" : fileName}
              <input hidden type="file" onChange={handleChangeLoadFile} />
            </Button>
            <Button
              sx={{ width: "fit-content" }}
              type="submit"
              variant="contained"
            >
              Tambah
            </Button>
          </Box>
        </form>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
            Prosedur
          </Typography>
          <ol>
            <li>
              Unduh file template terlebih dahulu agar sesuai format yang
              ditentukan.
            </li>
            <li>
              Isikan data bahan pustaka pada kolom di file. Kolom yang{" "}
              <strong>wajib</strong> diisi (<strong>No Induk</strong>,{" "}
              <strong>Jenis Pustaka</strong>, <strong>Klasifikasi</strong>,{" "}
              <strong>Judul</strong>, dan <strong>Tahun Terbit</strong>), selain
              itu opsional.
            </li>
            <li>
              Jika semua data sudah masuk, kemudian klik tombol{" "}
              <strong>Unggah File Excel</strong> dan pilih file Excel yang telah
              berisi data koleksi.
            </li>
            <li>
              Kemudian klik tombol <strong>Tambah</strong> untuk mengunggahnya.
            </li>
          </ol>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateBahanPustakaWithUploadFile;
