import { 
  Box, 
  Container, 
  Stack, 
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, 
  Paper
} from "@mui/material";
import CustomizedAccordions from "../../components/CustomizedAccordions";
import RightSideBarTentangKami from "../../components/RightSideBarTentangKami";
import { MyLayout } from "../../layout";
import color from "../../theme/Color";

const tata_tertib = [
  {
    id: 1,
    title: "Tata Tertib Pengunjung",
    content: (
      <ol>
        <li>
          Bagi mahasiswa Polstat STIS <b>diharuskan</b> memakai seragam PDA
          lengkap atau baju batik (pada hari rabu) beserta sepatu pantofel
          hitam. Selain itu tidak diperkenankan masuk ke perpustakaan.
        </li>
        <li>
          <b>Tas, jaket, topi, dan sejenisnya</b> disimpan di dalam loker.
        </li>
        <li>
          Tidak diperkenankan membawa buku (selain buku perpustakaan yang akan
          dikembalikan) ke dalam perpustakaan.
        </li>
        <li>
          Diperbolehkan membawa laptop beserta adaptornya,{" "}
          <b>softcase/hardcase tidak boleh dibawa masuk.</b>
        </li>
        <li>
          Dilarang membawa <b>makanan dan minuman</b>.
        </li>
        <li>
          Dilarang melakukan hal-hal yang dapat merusak bahan pustaka, seperti
          mencoret, melipat, menyobek, dan sejenisnya.
        </li>
        <li>
          Buku atau bahan pustaka lain <b>yang telah dibaca</b> harap{" "}
          <b>diletakkan di meja baca</b>, tidak perlu dikembalikan ke dalam rak
          buku.
        </li>
        <li>
          Menjaga ketenangan dan kenyamanan di dalam ruangan perpustakaan,
          seperti{" "}
          <b>
            HP di-<i>silent</i>, tidak berbicara/menerima telepon, tidak
            berbicara dengan suara keras, tidak bercanda, dan sejenisnya
          </b>
          .
        </li>
        <li>
          Untuk kegiatan{" "}
          <b>
            diskusi/belajar kelompok disediakan ruang <i>outdoor</i>
          </b>
          , dengan tetap menjaga ketenangan bagi pengunjung yang lain.
        </li>
        <li>
          Bagi yang ingin mengambil gambar, melakukan pemotretan, dan/atau video
          menggunakan kamera, harap memberitahukan ke pegawai perpustakaan.
        </li>
      </ol>
    ),
  },
  {
    id: 2,
    title: "Tata Tertib Penggunaan Loker",
    content: (
      <ol>
        <li>
          Loker yang disediakan bagi pengunjung perpustakaan adalah loker yang
          berada di <b>depan ruangan perpustakaan</b>. Untuk penggunaan loker di
          depan ruang perpustakaan, peminjam harus mengambil kunci loker ke
          pegawai perpustakaan dengan melakukan <i>scan</i> kode QR SIMPus.
        </li>
        <li>
          Pengunjung perpustakaan dapat menggunakan loker tersebut ketika akan
          berkunjung ke dalam perpustakaan,{" "}
          <b>
            bukan untuk menitipkan barang saat akan melakukan keperluan lain
          </b>
          seperti kuliah, sholat, dan lain-lain.
        </li>
        <li>
          Pengunjung perpustakaan yang membawa kunci loker bukan untuk keperluan
          di dalam perpustakaan seperti pada poin 2, <b>akan dikenakan denda</b>{" "}
          sesuai ketentuan dan{" "}
          <b>barang yang berada di dalam loker akan disita sementara</b> oleh
          pegawai perpustakaan.
        </li>
      </ol>
    ),
  },
  { 
    id: 3, 
    title: "Jam Buka dan Pelayanan", 
    content: (
      <>
        <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", backgroundColor: "transparent", maxWidth: "300px" }}
          >
            <Table padding="none">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", border: "none",  maxWidth: "160px" }}>
                    Senin-Kamis
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "16px", border: "none"}}
                  >
                    : 08.00-15.30 WIB
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", border: "none",  maxWidth: "160px" }}>
                    Jumat
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      border: "none",
                    }}
                  >
                    : 08.00-16.00 WIB
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", border: "none",  maxWidth: "160px" }}>
                    Sabtu-Minggu
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px", border: "none" }}>
                    : Libur
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
      </>
    )},
  { 
    id: 4, 
    title: "Peraturan Peminjaman Bahan Pustaka", 
    content: 
    <ol>
      <li>
        Bahan pustaka yang tersedia di perpustakaan Politeknik Statistika STIS terdiri
        dari Buku, Publikasi, Kamus, Skripsi, Koran, Majalah, Jurnal dan Buletin.
        <ol type="a">
          <li>
            Buku yang tersedia meliputi buku penunjang mata kuliah, buku agama, novel, 
            dan buku umum.
            <ol type="i">
              <li>
                Batas waktu peminjaman adalah 3 (tiga) hari kerja, selanjutnya dapat diperpanjang
                sebanyak 1 (satu) kali perpanjangan. Untuk setiap 1 (satu) hari keterlambatan
                pengembalian buku akan dikenakan denda.
              </li>
              <li>
                Jumlah buku yang dapat dipinjam adalah 2 (dua) eksemplar.
              </li>
            </ol>
          </li>
          <li>
            Publikasi yang tersedia antara lain publikasi terbitan BPS yang berisi data hasil kegiatan BPS.
            maupun publikasi terbitan instansi lain.
            <ol type="i">
              <li>
                Batas waktu peminjaman adalah 5 (lima) hari kerja, selanjutnya dapat diperpanjang
                sebanyak 1 (satu) kali perpanjangan. Untuk setiap 1 (satu) hari keterlambatan
                pengembalian buku akan dikenakan denda.
              </li>
              <li>
                Jumlah buku yang dapat dipinjam adalah 2 (dua) eksemplar.
              </li>
            </ol>
          </li>
          <li>
            Kamus, terdiri dari kamus Bahasa Indonesia, kamus Bahasa Inggris, dan kamus Bahasa Jepang.
            Kamus Bahasa Inggris diperkenankan untuk dipinjam dengan ketentuan peminjaman sama dengan
             buku. Sedangkan untuk kamus besar Bahasa Indonesia dan Bahasa Jepang tidak dipinjamkan tetapi
             hanya untuk dibaca di dalam perpustakaan. 
          </li>
          <li>
            Skripsi, koran, majalah, jurnal, dan buletin hanya diperbolehkan untuk dibaca di perpustakaan, 
            tidak untuk dipinjamkan kepada anggota perpustakaan.
          </li>
        </ol>
      </li>
      <li>
        Dalam waktu bersamaan, mahasiswa dapat meminjam 1 (satu) buku dan 1 (satu) publikasi.
      </li>
      <li>
        Jika peminjam menghilangkan atau merusakkan bahan pustaka maka harus mengganti dengan bahan pustaka
         yang sama (judul, pengarang) dengan melapor ke petugas perpustakaan terlebih dahulu.
      </li>
      <li>
        Tidak diperbolehkan melakukan peminjaman atas nama orang lain.
      </li>
      <li>
        Anggota perpustakaan dapat meminjam bahan pustaka apabila telah menyelesaikan administrasi perpustakaan,
         seperti: membayar denda, mengganti buku yang dihilangkan/dirusakkan peminjam, dan lain-lain.
      </li>
    </ol>
  },
  { 
    id: 5, 
    title: "Pengenaan Sanksi/Denda", 
    content: 
      <>
        Berdasarkan keputusan Kepala Unit Perpustakaan POLSTAT STIS Tahun 2023 
        Nomor 002/perpus/03/2023 tentang pemberlakuan denda keterlambatan buku dan penggunaan kunci
         loker adalah sebagai berikut:
          <ol>
            <li>Sanksi terkait bahan pustaka</li>
              <ol type="a">
                <li>
                  Jika pengembalian buku melebihi waktu yang ditentukan (tidak terhitung sabtu/minggu),
                    maka biaya keterlambatan dikenakan sebesar Rp. 1.000 Per hari Per Eksemplar.
                </li>
                <li>
                  Apabila terjadi kehilangan buku maka bersangkutan mengganti dengan buku yang sama.
                </li>
              </ol>
              <li>Sanksi terkait kunci loker</li>
              <ol type="a">
                <li>
                  Untuk penggunaan kunci loker yang melebihi jam pelayanan perpustakaan akan dikenakan
                    denda sebesar Rp. 5.000/hari.
                </li>
                <li>
                  Apabila Kunci loker hilang/rusak maka pengguna dikenakan denda sebesar Rp. 50.000.
                </li>
              </ol>
          </ol>
      </>
  },
  { 
    id: 6, 
    title: "Ketentuan Pengunjung Luar", 
    content: 
      <ol>
        <li>Pakaian rapi, memakai kemeja (bukan dari bahan kaos), tidak memakai rok/celana jeans</li>
        <li>Bersepatu</li>
        <li>Memakai badge tamu</li>
        <li>Pengunjung luar hanya diperkenankan membaca bahan pustaka di tempat (Tidak
            diperkenankan untuk dibawa/dipinjam)
        </li>
      </ol>
  },
];

const TataTertib = () => {
  return (
    <MyLayout
      title={"Tata Tertib"}
      menu={"Tentang Kami"}
      submenu={"Tata Tertib"}
    >
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box sx={{ borderRadius: "10px", overflow: "hidden" }}>
            <CustomizedAccordions data={tata_tertib} type={"tata-tertib"} />
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Tata Tertib"}
              submenu-is-active={""}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default TataTertib;
