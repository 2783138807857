import { Box, Stack, Typography } from "@mui/material";
import color from "../theme/Color";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const menus = [
  {
    title: "Fasilitas",
    path: null,
    submenus: [
      {
        title: "Meja Baca",
        path: "/baca",
      },
      {
        title: "Koleksi Cetak",
        path: "/koleksi-cetak",
      },
      {
        title: "Koleksi Elektronik",
        path: "/koleksi-elektronik",
      },
      {
        title: "Fasilitas Lainnya",
        path: "/fasilitas-lainnya",
      },
    ],
  },
  {
    title: "Layanan",
    path: null,
    submenus: [
      {
        title: "Pelayanan Sirkulasi",
        path: "/pelayanan-sirkulasi",
      },
      {
        title: "Peminjaman Bahan Pustaka",
        path: "/peminjaman",
      },
      {
        title: "Surat Bebas Perpustakaan",
        path: "/sbp",
      },
    ],
  },
  {
    title: "Tata Tertib",
    path: "/tata-tertib",
    submenus: null,
  },
  {
    title: "Struktur Organisasi",
    path: "/str-org",
    submenus: null,
  },
];

const RightSideBarTentangKami = (props) => {
  const navigate = useNavigate();

  const handleChangePageTentangKami = (path) => {
    if (path !== null) navigate(path);
  };

  return (
    <>
      {menus.map((menu, key_menu) => (
        <Box
          key={key_menu}
          sx={
            props["menu-is-active"] === menu.title
              ? { borderLeft: "5px solid #0077B6", boxSizing: "border-box" }
              : {}
          }
        >
          <Stack
            key={key_menu}
            path={menu.path}
            onClick={(e) =>
              handleChangePageTentangKami(e.currentTarget.attributes.path.value)
            }
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={
              props["menu-is-active"] === menu.title
                ? {
                    color: color.dark_blue,
                    backgroundColor: color.dark_blue_30p,
                    height: "56px",
                    paddingX: "18px",
                  }
                : {
                    color: "black",
                    backgroundColor: "white",
                    height: "56px",
                    paddingX: "18px",
                    ":hover": {
                      cursor: "pointer",
                      backgroundColor: color.dark_blue_30p,
                      color: color.dark_blue,
                    },
                  }
            }
          >
            <Typography>{menu.title}</Typography>
            {menu.submenus !== null && <ExpandMoreIcon />}
          </Stack>
          {menu.submenus !== null &&
            menu.submenus.map((submenu, key_submenu) => (
              <Stack
                key={key_submenu}
                path={submenu.path}
                onClick={(e) =>
                  handleChangePageTentangKami(
                    e.currentTarget.attributes.path.value
                  )
                }
                direction="row"
                alignItems="center"
                sx={
                  props["submenu-is-active"] === submenu.title
                    ? {
                        height: "56px",
                        backgroundColor: color.dark_blue_30p,
                        color: color.dark_blue,
                        paddingX: "36px",
                      }
                    : {
                        height: "56px",
                        backgroundColor: "white",
                        paddingX: "36px",
                        ":hover": {
                          cursor: "pointer",
                          backgroundColor: color.dark_blue_30p,
                          color: color.dark_blue,
                        },
                      }
                }
              >
                <Typography>{submenu.title}</Typography>
              </Stack>
            ))}
        </Box>
      ))}
    </>
  );
};

export default RightSideBarTentangKami;
