import React from "react";
import { useContext } from "react";
import {
  List,
  Box,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuContext from "../../../context/MenuProvider";
import TokenContext from "../../../context/TokenProvider";
import "../../../css/SideBar.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const menus = [
  {
    title: "Koleksi Bahan Pustaka",
    path: "/koleksi",
  },
  {
    title: "Rekomendasi Anda",
    path: "/rekomendasi",
  },
  {
    title: "Tambah Pinjaman",
    path: "/pinjam",
  },
  {
    title: "Riwayat Pinjaman",
    path: "/riwayat-pinjaman",
  },
  {
    title: "Riwayat Baca",
    path: "/riwayat-baca",
  },
  {
    title: "Penanda Koleksi",
    path: "/penanda-koleksi",
  },
];

const SubSideBarKoleksi = () => {
  const navigate = useNavigate();
  const { state, setState } = useContext(MenuContext);
  const { stateQRScanner, setStateQRScanner } = useContext(MenuContext);
  const { accessToken } = useContext(TokenContext);

  useEffect(() => {}, [state]);

  const toggleDrawerBack = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open, mainSideBar: true });
  };

  const toggleDrawer = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    if (path === "/pinjam") {
      if (accessToken !== null) {
        setStateQRScanner(true);
      } else {
        navigate("/login");
      }
    } else if (path !== null) navigate(path);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={toggleDrawerBack("subSideBarKoleksi", false)}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography sx={{ textAlign: "center", paddingY: "10px" }}>
          KOLEKSI
        </Typography>
        <IconButton onClick={toggleDrawer("subSideBarKoleksi", false, null)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menus.map((menu, key) => (
          <ListItem key={key} disablePadding>
            <ListItemButton
              onClick={toggleDrawer("subSideBarKoleksi", false, menu.path)}
            >
              <ListItemText primary={menu.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"left"}
      open={state["subSideBarKoleksi"]}
      onClose={toggleDrawer("subSideBarKoleksi", false, null)}
    >
      {list()}
    </Drawer>
  );
};

export default SubSideBarKoleksi;
