import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { AdminLayout } from "../../../layout/admin";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";
import { useEffect } from "react";

const CreateAdmin = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [konfirmasiPassword, setKonfirmasiPassword] = useState("");

  useEffect(() => {
    setNavTo("/a/pengguna/create");
    refreshToken();
  }, [accessToken]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeKonfirmasiPassword = (event) => {
    setKonfirmasiPassword(event.target.value);
  };

  const handleSubmitPengguna = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/pengguna`,
        {
          username: username,
          email: email,
          password: password,
          konfirmasiPassword: konfirmasiPassword,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/pengguna");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Admin
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmitPengguna}>
            <Box sx={{ display: "grid", gap: "20px" }}>
              <FormControl>
                <InputLabel>Username</InputLabel>
                <OutlinedInput
                  id="outlined-basic"
                  label="Username"
                  type="text"
                  variant="outlined"
                  onChange={handleChangeUsername}
                />
              </FormControl>
              <FormControl>
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  id="outlined-basic"
                  label="Email"
                  type="text"
                  variant="outlined"
                  onChange={handleChangeEmail}
                />
              </FormControl>
              <FormControl>
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  id="outlined-basic"
                  label="Password"
                  type="password"
                  variant="outlined"
                  onChange={handleChangePassword}
                />
              </FormControl>
              <FormControl>
                <InputLabel>Konfirmasi Password</InputLabel>
                <OutlinedInput
                  id="outlined-basic"
                  label="Konfirmasi Password"
                  type="password"
                  variant="outlined"
                  onChange={handleChangeKonfirmasiPassword}
                />
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "fit-content" }}
              >
                Tambah
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CreateAdmin;
