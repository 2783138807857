import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuContext from "../../../context/MenuProvider";
import TokenContext from "../../../context/TokenProvider";
import color from "../../../theme/Color";

const menus = [
  {
    title: "Koleksi Bahan Pustaka",
    path: "/koleksi",
  },
  {
    title: "Rekomendasi Anda",
    path: "/rekomendasi",
  },
  {
    title: "Tambah Pinjaman",
    path: "/pinjam",
  },
  {
    title: "Riwayat Pinjaman",
    path: "/riwayat-pinjaman",
  },
  {
    title: "Riwayat Baca",
    path: "/riwayat-baca",
  },
  {
    title: "Penanda Koleksi",
    path: "/penanda-koleksi",
  },
];

const SubAppBarKoleksi = () => {
  const { accessToken } = useContext(TokenContext);
  const { stateQRScanner, setStateQRScanner } = useContext(MenuContext);
  const { stateAppBar, setStateAppBar } = useContext(MenuContext);
  const navigate = useNavigate();

  const toggleSubAppBar = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateAppBar({ ...stateAppBar, [anchor]: open });
    if (path === "/pinjam") {
      if (accessToken !== null) {
        setStateQRScanner(true);
      } else {
        navigate("/login");
      }
    } else if (path !== null) navigate(path);
  };

  const closeSubAppBar = () => {
    setStateAppBar({
      subAppBarTentangKami: false,
      subAppBarKoleksi: false,
      subAppBarBantuan: false,
    });
  };

  return (
    <Box
      sx={{
        paddingTop: "1em",
        paddingBottom: "1em",
        width: "100%",
        backgroundColor: color.white_transparent,
        backdropFilter: "blur(10px)",
        position: "fixed",
        top: "64px",
        right: "0",
        left: "0",
        zIndex: "10",
      }}
      onMouseOver={toggleSubAppBar("subAppBarKoleksi", true, null)}
      onMouseOut={closeSubAppBar}
    >
      <Container maxWidth="lg">
        <Stack direction="row" spacing={12}>
          <Typography sx={{ width: "300px", marginTop: "10px" }}>
            Berbagai informasi dan layanan seputar koleksi dapat diakses disini.
          </Typography>

          <List>
            {menus.map((menu, key) => (
              <ListItem key={key} disablePadding>
                <ListItemButton
                  onClick={toggleSubAppBar(
                    "subAppBarKoleksi",
                    false,
                    menu.path
                  )}
                  sx={{
                    borderBottom: "1px solid rgb(230,230,230)",
                    borderLeft: "1px solid rgb(230,230,230)",
                  }}
                >
                  <ListItemText primary={menu.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Container>
    </Box>
  );
};

export default SubAppBarKoleksi;
