import { createContext, useState } from "react";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [id, setId] = useState("");
  const [idPeminjaman, setIdPeminjaman] = useState("");
  const [idPengunjung, setIdPengunjung] = useState("");
  const [type, setType] = useState("");
  const [deleteAlertDialogOpen, setDeleteAlertDialogOpen] = useState(false);
  const [konfirmasiPeminjamanDialogOpen, setKonfirmasiPeminjamanDialogOpen] =
    useState(false);
  const [perpanjangPeminjamanDialogOpen, setPerpanjangPeminjamanDialogOpen] =
    useState(false);
  const [kembalikanPeminjamanDialogOpen, setKembalikanPeminjamanDialogOpen] =
    useState(false);
  const [ubahKunciDialogOpen, setUbahKunciDialogOpen] = useState(false);
  const [
    kembalikanDanMemulangkanPengunjungSerentakDialogOpen,
    setKembalikanDanMemulangkanPengunjungSerentakDialogOpen,
  ] = useState(false);
  const [kembalikanKunciDialogOpen, setKembalikanKunciDialogOpen] =
    useState(false);
  const [memulangkanPengunjungDialogOpen, setMemulangkanPengunjungDialogOpen] =
    useState(false);
  const [createFormRekomendasiDialogOpen, setCreateFormRekomendasiDialogOpen] =
    useState(false);
  const [formSalinKoleksiDialogOpen, setFormSalinKoleksiDialogOpen] =
    useState(false);
  const [kodeMatkul, setKodeMatkul] = useState("");
  const [idMatkul, setIdMatkul] = useState("");
  const [formAddBukuToMatkulDialogOpen, setFormAddBukuToMatkulDialogOpen] =
    useState(false);

  return (
    <DialogContext.Provider
      value={{
        id,
        setId,
        idPeminjaman,
        setIdPeminjaman,
        idPengunjung,
        setIdPengunjung,
        type,
        setType,
        deleteAlertDialogOpen,
        setDeleteAlertDialogOpen,
        konfirmasiPeminjamanDialogOpen,
        setKonfirmasiPeminjamanDialogOpen,
        perpanjangPeminjamanDialogOpen,
        setPerpanjangPeminjamanDialogOpen,
        kembalikanPeminjamanDialogOpen,
        setKembalikanPeminjamanDialogOpen,
        kembalikanDanMemulangkanPengunjungSerentakDialogOpen,
        setKembalikanDanMemulangkanPengunjungSerentakDialogOpen,
        ubahKunciDialogOpen,
        setUbahKunciDialogOpen,
        kembalikanKunciDialogOpen,
        setKembalikanKunciDialogOpen,
        memulangkanPengunjungDialogOpen,
        setMemulangkanPengunjungDialogOpen,
        createFormRekomendasiDialogOpen,
        setCreateFormRekomendasiDialogOpen,
        formSalinKoleksiDialogOpen,
        setFormSalinKoleksiDialogOpen,
        kodeMatkul,
        setKodeMatkul,
        idMatkul,
        setIdMatkul,
        formAddBukuToMatkulDialogOpen,
        setFormAddBukuToMatkulDialogOpen,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export default DialogContext;
