import { Box, Button, TextField, Typography } from "@mui/material";
import { AdminLayout } from "../../../layout/admin";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../../../context/MessageProvider";
import { useEffect } from "react";

const CreateLinkPerpustakaan = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [nama, setNama] = useState("");
  const [linkWebsite, setLinkWebsite] = useState("");

  useEffect(() => {
    setNavTo("/a/link/create");
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleChangeNama = (nama) => {
    setNama(nama);
  };

  const handleChangeLinkWebsite = (linkWebsite) => {
    setLinkWebsite(linkWebsite);
  };

  const handleSubmitLinkPerpustakaan = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/link`,
        { nama: nama, link: linkWebsite },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/link");
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate(navTo);
      }
    }
  };

  return (
    <AdminLayout>
      <Box sx={{ width: { sm: "100%", md: "70%" } }}>
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          Tambah Link Website Perpustakaan
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmitLinkPerpustakaan}>
            <TextField
              id="outlined-basic"
              label="Nama"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => handleChangeNama(e.currentTarget.value)}
            />
            <TextField
              id="outlined-basic"
              label="Link Website"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => handleChangeLinkWebsite(e.currentTarget.value)}
            />
            <Button type="submit" variant="contained">
              Tambah
            </Button>
          </form>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CreateLinkPerpustakaan;
