import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import MenuContext from "../../context/MenuProvider";
import TokenContext from "../../context/TokenProvider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";
import jwtDecode from "jwt-decode";

const NoKunciDialog = (props) => {
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const { stateNoKunciDialog, setStateNoKunciDialog } = useContext(MenuContext);
  const [profil, setProfil] = useState(null);

  useEffect(() => {
    getProfil();
  }, [stateNoKunciDialog]);

  const handleClose = () => {
    setStateNoKunciDialog(false);
  };

  const handleClickOpen = () => {
    setStateNoKunciDialog(true);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/profil`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setProfil(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div>
      <Dialog
        open={stateNoKunciDialog}
        onClose={handleClose}
        maxWidth="xs"
        sx={{ textAlign: "center" }}
      >
        <DialogTitle>
          <CheckCircleOutlineIcon
            color="success"
            sx={{
              fontSize: "56px",
            }}
          />
          <div>Peminjaman Kunci Berhasil</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Nomor kunci Anda adalah
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "120px",
              height: "120px",
              backgroundColor: "#2E7D32",
              borderRadius: "50%",
              margin: "auto",
            }}
          >
            <Typography sx={{ color: "#FFFFFF", fontSize: "48px" }}>
              {profil && profil.noKunci}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: "auto" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleClose}
            autoFocus
          >
            Mengerti
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NoKunciDialog;
