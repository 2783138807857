import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext } from "react";
import MenuContext from "../../context/MenuProvider";
import { Stack } from "@mui/material";

const AppBarItemsLeft = () => {
  const { state, setState } = useContext(MenuContext);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <Stack
        component="a"
        href="/"
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ textDecoration: "none", display: { xs: "none", md: "flex" } }}
      >
        <img src="./image/Logo.png" alt="Logo SIMPus" width="40" height="40" />
        <Typography
          noWrap
          sx={{
            mr: 2,
            color: "black",
            fontSize: "34px",
          }}
        >
          SIMPus
        </Typography>
      </Stack>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={toggleDrawer("mainSideBar", true)}
          color="black"
        >
          <MenuIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default AppBarItemsLeft;
