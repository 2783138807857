import { useEffect, useRef } from "react";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import axios from "axios";
import "../App.css";
import "../index.css";
import CryptoJS from "crypto-js";
import { useContext } from "react";
import TokenContext from "../context/TokenProvider";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import KoleksiContext from "../context/KoleksiProvider";
import MessageContext from "../context/MessageProvider";
import { Button } from "@mui/material";

const Reader = () => {
  const viewer = useRef(null);
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const { noInduk } = useContext(KoleksiContext);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const base64ToBlob = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: "application/pdf" });
  };

  const decryptKey = (key, sharedKey) => {
    const bytes = CryptoJS.AES.decrypt(key, sharedKey);
    const decrypted_key = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted_key;
  };

  const getFile = async () => {
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/baca`,
        { noInduk: noInduk },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      const decrypted_key_1 = decryptKey(
        response.data.key,
        process.env.REACT_APP_KEY_2
      );
      const decrypted_key_2 = decryptKey(
        decrypted_key_1,
        process.env.REACT_APP_KEY_1
      );

      var bytes = CryptoJS.AES.decrypt(response.data.file, decrypted_key_2);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      WebViewer(
        {
          path: "/webviewer/lib",
          initialDoc: response.data.url,
          licenseKey: process.env.REACT_APP_LICENSE_KEY_PDF_VIEWER,
        },
        viewer.current
      ).then((instance) => {
        const path = "/image/logo_stis.png";

        // Promise resolves with options object
        const promise = new Promise((resolve) => {
          const img = new Image();
          const options = {
            custom: (ctx, pageNumber, pageWidth, pageHeight) => {
              // the pageNumber is also passed in so you could have
              // a different watermark for each page
              ctx.drawImage(
                img,
                pageWidth / 2 - img.width / 2,
                pageHeight / 2 - img.height / 2
              );
            },
          };
          img.onload = () => {
            return resolve(options);
          };
          img.src = path;
        });

        // now you can access APIs through the WebViewer instance
        const { Core, UI } = instance;

        instance.UI.loadDocument(base64ToBlob(originalText), {
          // filename: "myfile.pdf",
        });

        // remove left panel and left panel button from the DOM
        if (
          response.data.jenisPustaka === "skripsi" ||
          response.data.role === "ekstern" ||
          response.data.jenisPustaka === "ebook"
        ) {
          instance.UI.disableElements([
            "panToolButton",
            "selectToolButton",
            "menuButton",
            "contextMenuPopup",
            "textPopup",
          ]);

          instance.UI.disableFeatures([instance.UI.Feature.Print]);
          instance.UI.disableFeatures([instance.UI.Feature.Copy]);
          instance.UI.disableFeatures([instance.UI.Feature.Download]);
          instance.UI.disableFeatures([instance.UI.Feature.TextSelection]);
        } else {
          instance.UI.disableElements([
            "panToolButton",
            "selectToolButton",
            "contextMenuPopup",
            "textPopup",
          ]);
        }

        instance.UI.setFitMode(instance.UI.FitMode.FitWidth);

        Core.documentViewer.setWatermark(promise);
      });
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
        navigate("/koleksi");
      }
    }
  };

  useEffect(() => {
    if (noInduk) {
      getFile();
    }
    refreshToken();
  }, [noInduk]);

  return (
    <div className="App">
      <div className="webviewer" ref={viewer}></div>
    </div>
  );
};

export default Reader;
