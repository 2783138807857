import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

export default class HistogramChart extends PureComponent {
  render() {
    const { data } = this.props;
    console.log("data = ", data);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={data}>
          <Tooltip scale="band" viewBox={{ x: 0, y: 0, width: 800, height: 40 }}/>
          <XAxis
            dataKey={"jam"}
            interval={"preserveStart"}
            className="axis-legend"
            scale="band"
          >
            <Label
              value="Jam kunjungan"
              angle={0}
              fill="#666"
              dy={16}
            />
          </XAxis>
          <YAxis allowDecimals={false} className="axis-legend">
            <Label
              value="Jumlah pengunjung"
              angle={-90}
              fill="#666"
              dx={-16}
            />
          </YAxis>
          <Bar dataKey="jumlah" fill="#023E8A" />
          {/* <Bar dataKey="Tingkat 1" fill="#ffaa15" stackId="a" />
          <Bar dataKey="Tingkat 2" fill="#ffaa15" stackId="a" />
          <Bar dataKey="Tingkat 3" fill="#ffaa15" stackId="a" />
          <Bar dataKey="Tingkat 4" fill="#ffaa15" stackId="a" />
          <Bar dataKey="Dosen" fill="#ffaa15" stackId="a" />
          <Bar dataKey="Pengunjung Luar" fill="#ffaa15" stackId="a" /> */}
        </BarChart>
        {/* <BarChart width={150} height={40} data={data}>
          <XAxis dataKey="bulan" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="jumlah" fill="#8884d8" />
        </BarChart> */}
      </ResponsiveContainer>
    );
  }
}
