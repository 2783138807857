// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useContext, useState, useEffect } from "react";

function numberOnly (string) {
  var pattern = /^\d+$/;
  return pattern.test(string);
}

function highlightedDiv (highlight, nama) {
  if (highlight===nama){
    return true;
  } else {
    return false;
  }
}

const DenahMap = (props) => {
  // const BootstrapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: theme.palette.common.black,
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.black,
  //   },
  // }));

  // const scale = 0.75;
  // useEffect(() => {
  //   if("Highlight :",numberOnly(props.highlight));
  // }, []);
  
  return (
    // <BootstrapTooltip 
      // title={props.objek.deskripsi}
    //   title="Penjelasan"
    //   sx={{
    //     '.MuiTooltip-tooltipPlacementBottom': {
    //       position: "absolute",
    //       left: `${props.objek.left*scale+props.objek.lebar*scale/2}px`,
    //       top: `${props.objek.top*scale+props.objek.tinggi*scale}px`,
    //       marginTop: "0",
    //       transformOrigin:"left top",
    //       transform: `scale(${scale})`
    //     },
    //   }}
    // >
      <div
        style={{
          position: "relative",
          transform: "scale(0.75)",
          transformOrigin: "top left",
          // width: "100%"
        }}
      >
        {props.highlight ? (
          <div
            className="handle"
            id={props.objek.uuid}
            style={{
              position: "absolute",
              left: props.objek.left,
              top: props.objek.top,
              width: `${props.objek.lebar}px`,
              height: `${props.objek.tinggi}px`,
              backgroundColor: props.highlight === props.objek.nama ? "#f44336" : "#e0e0e0",
              border: "1px solid black",
              display: "grid",
              placeItems: "center",
            }}
          >
            <p style={{ fontSize: "12px" }}>{props.objek.nama}</p>
          </div>
        ) : (
          <div
            className="handle"
            id={props.objek.uuid}
            style={{
              position: "absolute",
              left: props.objek.left,
              top: props.objek.top,
              width: `${props.objek.lebar}px`,
              height: `${props.objek.tinggi}px`,
              backgroundColor: props.objek.warna,
              border: "1px solid black",
              display: "grid",
              placeItems: "center",
            }}
          >
            <p style={{ fontSize: "12px" }}>{props.objek.nama}</p>
          </div>
        )}
      </div>
    // </BootstrapTooltip>
  );
};

export default DenahMap;