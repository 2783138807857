import {
  CardMedia,
  Typography,
  Box,
  Link,
  Container,
  Stack,
  Button,
} from "@mui/material";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";
import { useContext } from "react";
import MenuContext from "../../../context/MenuProvider";

const FasilitasLainnya = () => {
  const { setStateTujuanBerkunjungDialog } = useContext(MenuContext);
  const handleClick = () => {
    setStateTujuanBerkunjungDialog(true); 
  }
  return (
    <MyLayout title={"Fasilitas Lainnya"} menu={"Tentang Kami"} submenu={"Fasilitas Lainnya"}>
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box>
              {/* Bagian ini buat tester dialog <Button onClick={handleClick}>
                  Click Me
              </Button> */}
            </Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Loker Penyimpanan
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/loker.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Pengunjung perpustakaan tidak diperkenankan membawa tas, jaket, dan topi,
                serta berbagai makanan dan minuman. Jika membawa barang tersebut, pengguna
                perlu menggunakan loker untuk menyimpannya. Peraturan selengkapnya 
                ada <Link href="/tata-tertib">di sini</Link>
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                BI Corner
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/bi.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                BI Corner merupakan pojok baca sebagai sarana edukasi sekaligus sebagai upaya
                 untuk mengenalkan kebijakan BI kepada publik. BI Corner menyediakan berbagai buku 
                 dalam Bahasa Indonesia dan Bahasa Inggris terkait keuangan dan ekonomi yang dapat
                  diakses oleh pengunjung. Jenis bacaan terdiri dari berbagai jenis, mulai dari 
                  perbankan, ekonomi, keuangan, sampai perpajakan.
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography sx={{ fontSize: "20px", color: color.dark_blue, fontWeight: "bold" }}>
                Headphone
              </Typography>
              <CardMedia
                image={"./image/tentang_kami/headphone.jpeg"}
                sx={{ height: "300px", marginY: "15px" }}
                alt={"Meja"}
              />
              <Typography align="justify" sx={{ fontSize: "14px" }}>
                Penyediaan headphone berguna untuk menciptakan suasana
                yang nyaman, karena dapat mengurangi gangguan dari luar
                dan dapat mendengarkan suara, lagu, video pembelajaran
                maupun audiolib tanpa mengganggu orang lain.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Fasilitas"}
              submenu-is-active={"Fasilitas Lainnya"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default FasilitasLainnya;
