import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Skeleton,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import fileDownload from "js-file-download";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DataTablePeminjamanSelesai = () => {
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [peminjaman, setPeminjaman] = useState(null);
  const [kataKunci, setKataKunci] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/a/peminjaman");
    refreshToken();
    getAllPeminjamanSelesai();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPeminjamanSelesai = async () => {
    try {
      let response;

      if (kataKunci === "") {
        response = await axiosJWT.get(
          `${process.env.REACT_APP_API_URL}/a/peminjaman-selesai?page=${page}&size=${rowsPerPage}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
      } else {
        response = await axiosJWT.get(
          `${process.env.REACT_APP_API_URL}/a/peminjaman?kategori=selesai&kata-kunci=${kataKunci}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      }
      setPeminjaman(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const unduhFileExcelRekapPeminjamanSelesai = async (e) => {
    try {
      e.preventDefault();
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/peminjaman/excel?kategori=selesai`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        }
      );

      fileDownload(response.data, `Rekap-Peminjaman-Selesai.xlsx`);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleChangeKataKunci = (event) => {
    setKataKunci(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginBottom: "10px" }}
      >
        <Button
          variant="contained"
          onClick={unduhFileExcelRekapPeminjamanSelesai}
          color="success"
        >
          Unduh Rekap
        </Button>
        <Stack direction="row">
          <TextField
            size="small"
            id="outlined-basic"
            label="Nama"
            variant="outlined"
            sx={{ width: "300px" }}
            onChange={handleChangeKataKunci}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={getAllPeminjamanSelesai}
          >
            Cari
          </Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nama</StyledTableCell>
              <StyledTableCell>Judul</StyledTableCell>
              <StyledTableCell>Tanggal Pinjam</StyledTableCell>
              <StyledTableCell>Tanggal Kembali</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {peminjaman !== null &&
              peminjaman.rows.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell>
                    {row.nama}{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      ({row.username})
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>{row.judul}</StyledTableCell>
                  <StyledTableCell>{row.tanggalPinjam}</StyledTableCell>
                  <StyledTableCell>{row.tanggalKembali}</StyledTableCell>
                </StyledTableRow>
              ))}
            {peminjaman === null && (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
          <TablePagination
            count={peminjaman !== null ? peminjaman.count : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTablePeminjamanSelesai;
