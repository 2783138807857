import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuContext from "../../context/MenuProvider";
import { Badge, Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../context/TokenProvider";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";

const AppBarItemsRight = () => {
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const { setStateUserMenu } = useContext(MenuContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const md = useMediaQuery(theme.breakpoints.up("sm"));
  const [jumlahNotifikasi, setJumlahNotifikasi] = useState(0);
  const [foto, setFoto] = useState("");
  const [profil, setProfil] = useState(null);

  useEffect(() => {
    if (accessToken !== null) {
      getNotifikasi();
      getFotoProfil();
      getProfil();
    }
  }, [accessToken]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getNotifikasi = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/notifikasi?role=intern`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setJumlahNotifikasi(response.data.length);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getFotoProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/foto`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setFoto(response.data.gambar);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getProfil = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/auth/profil`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setProfil(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateUserMenu({ [anchor]: open });
  };

  const handleClickToLoginPage = () => {
    navigate("/login");
  };

  return (
    <Stack direction="row">
      {accessToken !== null && (
        <>
          <Box
            sx={{
              display: "grid",
              margin: "auto",
              height: "48px",
              width: "48px",
            }}
            onMouseOver={md ? toggleDrawer("notifikasi", true) : null}
            onMouseOut={md ? toggleDrawer("notifikasi", false) : null}
          >
            <IconButton
              onClick={toggleDrawer("notifikasi", true)}
              // sx={{ marginRight: "15px" }}
            >
              <Badge color="primary" badgeContent={jumlahNotifikasi}>
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "grid",
              margin: "auto",
              height: "48px",
              width: "48px",
            }}
            onMouseOver={md && toggleDrawer("profil", true)}
            onMouseOut={md && toggleDrawer("profil", false)}
          >
            <IconButton onClick={toggleDrawer("profil", true)} sx={{ p: 0 }}>
              <Badge
                color={profil?.inPerpus ? "success" : "error"}
                overlap="circular"
                badgeContent={
                  profil?.inPerpus && profil.noKunci !== null
                    ? profil.noKunci
                    : ""
                }
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                max={999}
              >
                <Avatar alt="Remy Sharp" src={foto} />
              </Badge>
            </IconButton>
          </Box>
        </>
      )}
      {accessToken === null && (
        <Button variant="contained" onClick={handleClickToLoginPage}>
          Masuk
        </Button>
      )}
    </Stack>
  );
};

export default AppBarItemsRight;
