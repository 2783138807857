import { 
    Box, 
    Container, 
    Stack, 
    Typography,
    CardMedia,
    Divider,
    Avatar
} from "@mui/material";
import RightSideBarTentangKami from "../../components/RightSideBarTentangKami";
import { MyLayout } from "../../layout";
import color from "../../theme/Color";

const OrgCard = (props) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="left"
      spacing={5}
      alignItems="center"
      sx={{ margin : "32px"}}
    >
      <Avatar
        alt={props.jabatan}
        src={`./image/petugas/${props.src}`}
        sx={{ width: 100, height: 100 }}
      />
      <Box>
        <Typography 
          sx={{ 
            fontSize: "20px", 
            color: color.dark_blue, 
            fontWeight: "bold",
            textAlign: { xs: 'center', sm: 'left' }
        }}>
          {props.nama}
        </Typography>
        <Typography sx={{ textAlign: { xs: 'center', sm: 'left' } }}> {props.jabatan} </Typography>
      </Box>
    </Stack>
  )
}

  const StrukturOrganisasi = () => {
    return (
      <MyLayout
        title={"Struktur Organisasi"}
        menu={"Tentang Kami"}
        submenu={"Struktur Organisasi"}
      >
        <Box sx={{ backgroundColor: color.dark_white }}>
          <Container
            maxWidth="lg"
            sx={{
              paddingY: "40px",
              display: "grid",
              gridTemplateColumns: { xs: "auto", md: "auto 373px" },
              gap: "114px",
            }}
          >
            <Box sx={{ borderRadius: "10px", overflow: "auto" }}>
                <CardMedia
                    image={"./image/tentang_kami/struktur_organisasi.png"}
                    sx={{ backgroundSize: "contain", height: "300px",minWidth:"620px" }}
                />
                {/* <Tree
                    lineWidth={'2px'}
                    lineColor={color.ut_orange}
                    lineBorderRadius={'10px'}
                    label={<OrgTree nama='Achmad Prasetyo, S.Si. MM.' jabatan='Ketua Unit Perpustakaan'/>}
                >
                  <TreeNode label={<OrgTree nama='-' jabatan='Otomasi Perpustakaan'/>} />
                  <TreeNode label={<SkipTree/>}>
                    <TreeNode label={<OrgTree nama='Yatno' jabatan='Pelaksana'/>} />
                  </TreeNode>
                  <TreeNode label={<OrgTree nama={<>Farashintha Julhija Karim, S.E., M.Si.<br/>Maya Hayuningtyas, S.E.</>} jabatan='Pelayanan Perpustakaan'/>} />  
                </Tree>  */}
                <Box>
                  <OrgCard nama='Achmad Prasetyo, S.Si. MM.' jabatan='Ketua Unit Perpustakaan' src='pak_pras_2.jpeg'/>
                  <Divider />
                  <OrgCard nama="Farashintha Julhija Karim, S.E., M.Si." jabatan='Pelayanan Perpustakaan' src='bu_fara_2.jpg'/>
                  <Divider />
                  <OrgCard nama="Maya Hayuningtyas, S.E." jabatan='Pelayanan Perpustakaan' src='bu_maya.jpeg'/>
                  <Divider />
                  <OrgCard nama='Yatno' jabatan='Pelaksana' src='pak_yatno_2.jpeg'/>
                </Box>

            </Box>

            <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ height: "62px", backgroundColor: color.dark_blue }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    color: color.light_white,
                    fontSize: "20px",
                  }}
                >
                  Tentang Kami
                </Typography>
              </Stack>
  
              <RightSideBarTentangKami
                menu-is-active={"Struktur Organisasi"}
                submenu-is-active={""}
              />
            </Box>
          </Container>
        </Box>
      </MyLayout>
    );
  };
  
  export default StrukturOrganisasi;
  