import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from 'react';
import MenuContext from '../../context/MenuProvider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AlertDialog = (props) => {
    const { stateAlertDialog, setStateAlertDialog } = useContext(MenuContext);

    const handleClose = () => {
        setStateAlertDialog(prevState => ({
            ...prevState, // Menyalin semua properti dari state sebelumnya
            isOpen: false // Memperbarui nilai properti isOpen
        }));          
    };

    const handleClickOpen = () => {
        setStateAlertDialog(prevState => ({
            ...prevState, // Menyalin semua properti dari state sebelumnya
            isOpen: true // Memperbarui nilai properti isOpen
        }));
    };

    return(
        <div>
            <Dialog
                open={stateAlertDialog.isOpen}
                onClose={handleClose}
                maxWidth="xs"
                sx={{ textAlign: "center" }}
            >
                <DialogTitle>
                    {stateAlertDialog.severity==="success" && (
                        <CheckCircleOutlineIcon
                            color={stateAlertDialog.severity}
                            sx={{
                                fontSize: "56px"
                            }}
                        />
                    )}
                    {stateAlertDialog.severity==="warning" && (
                        <WarningAmberIcon
                            color={stateAlertDialog.severity}
                            sx={{
                                fontSize: "56px"
                            }}
                        />
                    )}
                    {stateAlertDialog.severity==="error" && (
                        <ErrorOutlineIcon
                            color={stateAlertDialog.severity}
                            sx={{
                                fontSize: "56px"
                            }}
                        />
                    )}
                    {stateAlertDialog.severity==="info" && (
                        <ErrorOutlineIcon
                            color={stateAlertDialog.severity}
                            sx={{
                                fontSize: "56px"
                            }}
                        />
                    )}
                    <div>{stateAlertDialog.title}</div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {stateAlertDialog.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ margin: "auto" }}>
                    <Button variant ="contained" color = {stateAlertDialog.severity} onClick={handleClose} autoFocus>
                        Mengerti
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default AlertDialog;