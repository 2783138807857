import React from "react";
import { useContext } from "react";
import {
  List,
  Box,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuContext from "../../../context/MenuProvider";
import "../../../css/SideBar.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const menus = [
  {
    title: "Koleksi Cetak",
    path: "/koleksi-cetak",
  },
  {
    title: "Koleksi Elektronik",
    path: "/koleksi-elektronik",
  },
  {
    title: "Meja Baca",
    path: "/baca",
  },
  {
    title: "Fasilitas Lainnya",
    path: "/fasilitas-lainnya",
  },
];

const SubSideBarFasilitas = () => {
  const navigate = useNavigate();
  const { state, setState } = useContext(MenuContext);

  useEffect(() => {}, [state]);

  const toggleDrawerBack = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open, subSideBarTentangKami: true });
  };

  const toggleDrawer = (anchor, open, path) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    if (path !== null) navigate(path);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={toggleDrawerBack("subSideBarFasilitas", false)}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography sx={{ textAlign: "center", paddingY: "10px" }}>
          FASILITAS
        </Typography>
        <IconButton onClick={toggleDrawer("subSideBarFasilitas", false, null)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menus.map((menu, key) => (
          <ListItem key={key} disablePadding>
            <ListItemButton
              onClick={toggleDrawer("subSideBarFasilitas", false, menu.path)}
            >
              <ListItemText primary={menu.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"left"}
      open={state["subSideBarFasilitas"]}
      onClose={toggleDrawer("subSideBarFasilitas", false, null)}
    >
      {list()}
    </Drawer>
  );
};

export default SubSideBarFasilitas;
