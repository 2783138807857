import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import {
  MenuProvider,
  KoleksiProvider,
  TokenProvider,
  MessageProvider,
} from "./context/";
import axios from "axios";
import { ThemeProvider } from "@mui/material";
import { globalTheme } from "./theme/globalTheme";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFaceAngry,
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
  faFaceGrinBeam,
} from "@fortawesome/free-solid-svg-icons";
import { AdminSidebarProvider } from "./context/admin/SidebarProvider";
import { DialogProvider } from "./context/admin/DialogProvider";

axios.defaults.withCredentials = true;

library.add(faFaceAngry, faFaceFrown, faFaceMeh, faFaceSmile, faFaceGrinBeam);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="275683425719-ej44vpqml60pr5nun69447sjmrov09l4.apps.googleusercontent.com">
    <React.StrictMode>
      <AdminSidebarProvider>
        <DialogProvider>
          <ThemeProvider theme={globalTheme}>
            <MenuProvider>
              <KoleksiProvider>
                <TokenProvider>
                  <MessageProvider>
                    <App />
                  </MessageProvider>
                </TokenProvider>
              </KoleksiProvider>
            </MenuProvider>
          </ThemeProvider>
        </DialogProvider>
      </AdminSidebarProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
