import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import color from "../theme/Color";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CustomizedAccordions = (props) => {
  const [expanded, setExpanded] = React.useState("");
  const data = props.data;
  const type = props.type;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {data.map((element, key) => (
        <Accordion
          key={key}
          expanded={expanded === "panel" + key}
          onChange={handleChange("panel" + key)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: color.light_white }} />}
            aria-controls={`panel${key}d-content`}
            id={`panel${key}d-header`}
            sx={{ backgroundColor: color.dark_blue, color: color.light_white }}
          >
            {type === "faq" ? (
              <Typography>{element.pertanyaan}</Typography>
            ) : (
              <Typography>{element.title}</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {type === "faq" ? (
              <Typography>{element.jawaban}</Typography>
            ) : (
              <Typography>{element.content}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CustomizedAccordions;
