import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputBase,
  IconButton,
  Stack,
  Typography,
  Container,
} from "@mui/material";
// import Container from "@mui/material/Container";
import { styled, alpha } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { MyLayout } from "../layout";
// import { Typography, InputBase, Stack } from "@mui/material";
import VerticalCard from "../components/VerticalCard";
import HorizontalCard from "../components/HorizontalCard";
import CustomizedAccordions from "../components/CustomizedAccordions";
import color from "../theme/Color";
import { faq, layanan } from "../data/Beranda";
import { useEffect, useState } from "react";
import axios from "axios";
import { useContext } from "react";
import TokenContext from "../context/TokenProvider";
import KoleksiContext from "../context/KoleksiProvider";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";

const Beranda = () => {
  const { setNavTo } = useContext(TokenContext);
  const [FAQ, setFAQ] = useState(null);
  const [pengumuman, setPengumuman] = useState(null);
  const { keyword, setKeyword } = useContext(KoleksiContext);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/");
    getPengumuman();
    getFAQ();
  }, []);

  const getPengumuman = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/pengumuman`
    );
    setPengumuman(response.data);
  };

  const getFAQ = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/faq`);
    setFAQ(response.data);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Daftar nama bulan
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} ${months[monthIndex]} ${year}`;
    return formattedDate;
  }

  const handleEnterSubmit = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      navigate("/koleksi");
    }
  };

  const handleClickSubmit = () => {
    navigate("/koleksi");
  };

  return (
    <MyLayout title={"Beranda"}>

    <Popup />

      {/* Header */}
      <Box
        sx={{
          paddingY: { xs: "3em", md: "0" },
          background:
            "linear-gradient(rgb(2,62,138,0.8), rgb(0, 180, 216,0.8)), url(./image/homepage_background.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              // background:color.blue,
              paddingTop: "2em",
              minHeight: { xs: "fit-content", md: "100vh" },
              width: "100%",
              display: { xs: "block", md: "flex" },
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
            // sx={{ backgroundColor:color.dark_blue}}
            >
              <Box
                sx={{
                  width: { xs: "100%" },
                  margin: "auto",
                }}
              >
                <Box sx={{ width: { xs: "100%" }, margin: "auto" }}>
                  <Typography
                    sx={{
                      marginTop: "30px",
                      textAlign: "center",
                      fontSize: { xs: "32px", md: "48px" },
                      fontWeight: "bold",
                      color: color.white,
                    }}
                  >
                    Berbagai Kebutuhan Perpustakaan STIS <br /> dalam Satu Akses
                  </Typography>

                  <Typography
                    sx={{
                      marginTop: "20px",
                      textAlign: "center",
                      color: color.white,
                    }}
                  >
                    Dapatkan akses mudah ke layanan perpustakaan dan berbagai
                    informasi seputar perpustakaan, <br /> serta berikan
                    masukkan agar kami dapat memberikan layanan yang lebih baik
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: "10px",
                    marginTop: { xs: "100px", md: "160px" },
                    backgroundColor: "rgba(217, 217, 217, 0.5)",
                    borderRadius: "10px",
                    width: { xs: "100%", sm: "560px" },
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: color.light_white }}>
                    Telusuri koleksi kami
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      placeholder="Telusuri"
                      size="small"
                      color="primary"
                      autoFocus="true"
                      value={keyword}
                      onKeyPress={handleEnterSubmit}
                      onChange={(e) => setKeyword(e.currentTarget.value)}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 1)",
                        },
                        "&.Mui-focused": {
                          backgroundColor: "rgba(255, 255, 255, 1)",
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickSubmit}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Layanan */}
      <Box
        sx={{
          backgroundColor: color.dark_white,
          paddingTop: "56px",
          paddingBottom: "72px",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="center"
          >
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "100px",
                height: "5px",
                backgroundColor: color.blue,
              }}
            ></Box>
            <Typography
              sx={{
                zIndex: "1",
                margin: { xs: "0", md: "auto" },
                width: "fit-content",
                textAlign: {
                  xs: "left",
                  md: "center",
                },
                fontSize: "34px",
                fontWeight: "bolder",
                color: color.dark_blue,
              }}
            >
              Fasilitas & Layanan
            </Typography>
            <Box
              sx={{
                width: { xs: "100%", md: "100px" },
                height: "5px",
                backgroundColor: color.blue,
              }}
            ></Box>
          </Stack>

          <Box
            sx={{
              marginTop: "36px",
              display: "grid",
              gridTemplateColumns: {
                xs: "auto auto",
                md: "auto auto auto",
                lg: "auto auto auto auto auto auto",
              },
              gap: "20px",
            }}
          >
            {layanan.map((element, key) => (
              <VerticalCard
                key={key}
                title={element.title}
                image={element.image}
                path={element.path}
              />
            ))}
          </Box>
        </Container>
      </Box>

      {/* Pengumuman */}
      <Box
        sx={{
          backgroundColor: color.light_white,
          paddingTop: "56px",
          paddingBottom: "72px",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="center"
          >
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "100px",
                height: "5px",
                backgroundColor: color.blue,
              }}
            ></Box>
            <Typography
              variant="h4"
              sx={{
                zIndex: "1",
                margin: { xs: "0", md: "auto" },
                width: "fit-content",
                textAlign: {
                  xs: "left",
                  md: "center",
                },
                fontSize: "34px",
                fontWeight: "bolder",
                color: color.dark_blue,
              }}
            >
              Pengumuman
            </Typography>
            <Box
              sx={{
                width: { xs: "100%", md: "100px" },
                height: "5px",
                backgroundColor: color.blue,
              }}
            ></Box>
          </Stack>

          <Box
            sx={{
              marginTop: "36px",
              display: "grid",
              gap: "20px",
            }}
          >
            {pengumuman !== null &&
              pengumuman.map((element, key) => (
                <HorizontalCard
                  key={key}
                  date={formatDate(element.tanggal)}
                  title={element.judul}
                  content={element.konten}
                  gambar={element.gambar}
                />
              ))}
          </Box>
        </Container>
      </Box>

      {/* FAQ */}
      <Box
        sx={{
          backgroundColor: color.dark_white,
          paddingTop: "56px",
          paddingBottom: "72px",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="center"
          >
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "100px",
                height: "5px",
                backgroundColor: color.blue,
              }}
            ></Box>
            <Typography
              variant="h4"
              sx={{
                display: { xs: "block", md: "none" },
                zIndex: "1",
                margin: { xs: "0", md: "auto" },
                width: "fit-content",
                textAlign: {
                  xs: "left",
                  md: "center",
                },
                fontSize: "34px",
                fontWeight: "bolder",
                color: color.dark_blue,
              }}
            >
              FAQ
            </Typography>
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
                zIndex: "1",
                margin: { xs: "0", md: "auto" },
                width: "fit-content",
                textAlign: {
                  xs: "left",
                  md: "center",
                },
                fontSize: "34px",
                fontWeight: "bolder",
                color: color.dark_blue,
              }}
            >
              Frequently Asked Question
            </Typography>
            <Box
              sx={{
                width: { xs: "100%", md: "100px" },
                height: "5px",
                backgroundColor: color.blue,
              }}
            ></Box>
          </Stack>
          <Box
            sx={{ marginTop: "36px", borderRadius: "10px", overflow: "hidden" }}
          >
            {FAQ !== null && <CustomizedAccordions data={FAQ} type={"faq"} />}
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default Beranda;
