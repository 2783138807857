import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import DialogContext from "../../context/admin/DialogProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ShowTableMatkulBuku from "./TableMatkulBuku";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormAddBukuToMatkulDialog = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const {
    idMatkul,
    formAddBukuToMatkulDialogOpen,
    setFormAddBukuToMatkulDialogOpen,
  } = useContext(DialogContext);
  const [kataKunci, setKataKunci] = useState("");
  const [koleksiTerpilih, setKoleksiTerpilih] = useState(null);
  const [allKoleksi, setAllKoleksi] = useState(null);

  useEffect(() => {
    if (kataKunci !== "") {
      getAllKoleksiLiveSearch();
    }
  }, [formAddBukuToMatkulDialogOpen]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleClose = () => {
    setFormAddBukuToMatkulDialogOpen(false);
  };

  const getAllKoleksiLiveSearch = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/koleksi/live?kata-kunci=${kataKunci}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setAllKoleksi(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleSubmitAddBukuToMatkul = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/rekomendasi/buku`,
        { idMatkul: idMatkul, uuidKoleksi: koleksiTerpilih },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/rekomendasi");
      handleClose();
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        open={formAddBukuToMatkulDialogOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogContent>
          <ShowTableMatkulBuku id={idMatkul} />
          <Typography variant="h6" sx={{ marginY: "20px" }}>
            Tambah Buku
          </Typography>
          <Button
            onClick={getAllKoleksiLiveSearch}
            variant="contained"
            sx={{ marginBottom: "20px" }}
          >
            Cari
          </Button>
          <TextField
            id="outlined-basic"
            label="Kata Kunci"
            variant="outlined"
            sx={{ width: "100%", marginBottom: "20px" }}
            onChange={(event) => setKataKunci(event.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Bahan Pustaka</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={koleksiTerpilih}
              label="Bahan Pustaka"
              onChange={(event) => setKoleksiTerpilih(event.target.value)}
              MenuProps={MenuProps}
            >
              {allKoleksi !== null &&
                allKoleksi.map((koleksi) => (
                  <MenuItem value={koleksi.id}>{koleksi.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitAddBukuToMatkul} variant="contained">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormAddBukuToMatkulDialog;
