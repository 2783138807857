import { Box, IconButton, Backdrop, CircularProgress, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import color from "../theme/Color";
import Fab from '@mui/material/Fab';
import { useContext, useState } from "react";
import MenuContext from "../context/MenuProvider";
import { grey } from '@mui/material/colors';
import Loading from './Loading';

const QrCodeScanButton = () => {
  const [loading, setLoading] = useState(false);
  const { stateQRScanner, setStateQRScanner } = useContext(MenuContext);

  const toggleScanner = () => {
    setLoading(true);
    setStateQRScanner(true);
    setLoading(false);
  };

  return (
    <>
      <Fab 
        color="secondary" 
        aria-label="edit" 
        onClick={toggleScanner}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
      >
        <QrCodeScannerIcon fontSize="large" sx={{ color: color.light_white }}/>
      </Fab>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loading/>
      </Backdrop>
    </>
  );
};

export default QrCodeScanButton;
