import {
  Box, 
  Container, 
  Stack, 
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";

const steps = [
  {
    label: 'Pastikan dalam status berkunjung',
    description: `Pengguna tidak dapat mengajukan pinjaman jika belum berkunjung ke perpustakaan. Oleh
                  karena itu, pastikan pengguna dalam status berkunjung. Status berkunjung dapat dilihat
                  pada foto profil pada AppBar di atas. Pengguna dalam status berkunjung jika badge 
                  berwarna hijau, sedangkan tidak berstatus berkunjung jika badge berwarna merah.`,
  },
  {
    label: 'Scan kode QR atau masukkan nomor induk buku',
    description: `Klik tombol "scan" pada ujung kanan bawah. Pilih tab "Peminjaman Buku". Klik tombol
                   "Request camera permission". Pilih kamera belakang, lalu klik "Start Scanning". 
                  Scan kode QR yang ada di halaman belakang buku. Jika gagal melakukan scan, sebagai 
                  alternatif pengguna dapat memasukkan nomor induk buku dengan kode "BK" + "6 digit" 
                  yang ada pada stempel halaman awal buku`,
  },
  {
    label: 'Konfirmasi peminjaman',
    description: `Setelah mengajukan pinjaman, lakukan konfirmasi peminjaman ke petugas perputakaan.
                  Status peminjaman dapat dilihat pada menu "Koleksi > Riwayat Pinjaman".`,
  }
];

const PeminjamanBahanPustaka = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <MyLayout
    title={"Peminjaman Bahan Pustaka"}
    menu={"Tentang Kami"}
    submenu={"Peminjaman Bahan Pustaka"}
    >
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
          >
          <Box>
            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold"  }}>
              Panduan Peminjaman Bahan Pustaka
            </Typography>
            <Typography sx={{ fontSize: "14px", marginTop: "8px" }}>
              Berikut ini merupakan panduan peminjaman bahan pustaka :
              <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          index === 2 ? (
                            <Typography variant="caption" sx={{fontSize: "14px", marginTop: "8px"}}>Langkah terakhir</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography align="justify" sx={{fontSize: "14px", marginTop: "8px"}}>{step.description}</Typography>
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1 ? 'Selesai' : 'Selanjutnya'}
                            </Button>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Kembali
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3, backgroundColor: "rgba(0,0,0,0)" }}>
                    <Typography sx={{fontSize: "14px"}}>Panduan peminjaman bahan pustaka telah selesai.</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Ulangi
                    </Button>
                  </Paper>
                )}
              </Box>
            </Typography>

            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold"  }}>
              Kriteria Peminjaman
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Siapapun dapat melakukan peminjaman bahan pustaka selama dia
              menjadi <b>anggota perpustakaan</b> dan telah{" "}
              <b>menyelesaikan administrasi perpustakaan</b> (seperti membayar
              denda, mengganti buku yang dihilangkan, dan lain - lain).
            </Typography>

            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold"  }}>
              Jenis Bahan Pustaka
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Anggota perpustakaan dapat meminjam 3 jenis bahan perpustakaan,
              yaitu <b>buku, publikasi, dan kamus bahasa inggris</b>.
            </Typography>

            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold" }}>
              Waktu Peminjaman
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Batas waktu peminjaman <b>buku</b> adalah <b>3 hari kerja</b>,
              selanjutnya dapat diperpanjang sebanyak <b>1 kali perpanjangan</b>
              .
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Batas waktu peminjaman publikasi adalah <b>5 hari kerja</b>,
              selanjutnya dapat diperpanjang sebanyak <b>1 kali perpanjangan</b>
              .
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Batas waktu peminjaman <b>kamus</b> memiliki ketentuan yang{" "}
              <b>sama dengan buku</b>.
            </Typography>

            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold" }}>
              Jumlah Pinjaman
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Setiap anggota perpustakaan dapat meminjam{" "}
              <b>2 bahan pustaka secara bersamaan</b> (dapat berupa 2 buku, 2
              publikasi, atau 1 buku dan 1 publikasi).
            </Typography>

            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold" }}>
              Peminjaman yang Diwakilkan
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Peminjaman <b>tidak boleh</b> diwakilkan oleh orang lain.
            </Typography>

            <Typography sx={{ fontSize: "20px", marginTop: "32px", color: color.dark_blue, fontWeight: "bold" }}>
              Sanksi Keterlambatan
            </Typography>
            <Typography align="justify" sx={{ fontSize: "14px" }}>
              Peminjaman akan dikenai denda <b>Rp 1000/hari</b> keterlambatan.
              Sedangkan jika buku yang dipinjam hilang, peminjam harus{" "}
              <b>mengembalikan bahan pustaka yang sama</b> (judul, pengarang),
              dengan <b>melapor ke petugas perpustakaan</b> terlebih dahulu.
            </Typography>

            {/* <Typography sx={{ fontSize: "20px", marginTop: "32px" }}>
              Tutorial Peminjaman
            </Typography>
            <iframe
              style={{ width: "100%", height: "468px", marginTop: "8px" }}
              src="https://www.youtube.com/embed/QFYvrtm3Jss"
              title="Tutorial SIMPus"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}
          </Box>

          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>

            <RightSideBarTentangKami
              menu-is-active={"Layanan"}
              submenu-is-active={"Peminjaman Bahan Pustaka"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default PeminjamanBahanPustaka;
