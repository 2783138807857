import axios from "axios";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { createContext, useState } from "react";

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [decodedData, setDecodedData] = useState(null);
  const [navTo, setNavTo] = useState("/");

  useEffect(() => {
    isLogin();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) setAccessToken(null);
    }
  };

  const isLogin = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/me`
      );
      if (response.data) refreshToken();
      else setAccessToken(null);
    } catch (error) {
      if (error.response) setAccessToken(null);
    }
  };

  return (
    <TokenContext.Provider
      value={{
        accessToken,
        setAccessToken,
        decodedData,
        setDecodedData,
        navTo,
        setNavTo,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export default TokenContext;
