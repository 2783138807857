import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import color from "../theme/Color";
import { CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

const VerticalCard = (props) => {
  const navigate = useNavigate();

  const handleChangePageVerticalCard = (path) => {
    if (path !== null) navigate(path);
  };

  return (
    <Card
      path={props.path}
      onClick={(e) =>
        handleChangePageVerticalCard(e.currentTarget.attributes.path.value)
      }
      sx={{
        backgroundColor: color.light_white,
        width: { xs: "100%", sm: "180px", md: "200px", lg: "170px" },
        height: "100%",
        margin: "auto",
        borderRadius: "10px",
        color: color.dark_blue,
        ":hover": {
          color: color.dark_blue,
          textDecoration: "underline",
          boxShadow: "0 0 4px 4px rgba(0, 0, 0, 0.1)",
          transform: "scale3d(1.05, 1.05, 1)"
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            width: "96px",
            height: "96px",
            margin: "auto",
          }}
        >
          <CardMedia
            sx={{ width: "85%", height: "85%", margin: "auto"}}
            image={props.image}
            title={props.image}
          />
        </Box>
        <Typography
          sx={{ 
            marginTop: "10px", fontSize: "20px", textAlign:"center", }}
        >
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VerticalCard;
