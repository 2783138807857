import * as React from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Typography,
  Container,
  Button,
  Autocomplete,
  TextField,
  TablePagination,
  Chip
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useState, useEffect } from 'react';
import MenuContext from '../../context/MenuProvider';
import KoleksiContext from "../../context/KoleksiProvider";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import RangeSlider from "../../components/RangeSlider";


export default function FilterDialog() {
  const { stateFilterKoleksi, setStateFilterKoleksi } = useContext(MenuContext);
  const { 
    buttonIsActive,
    setButtonIsActive,
    jenis_pustaka,
    jenisPustakaSelected,
    setJenisPustakaSelected,
    getKoleksi
  } = useContext(KoleksiContext);
  // const [jenisPustaka, setJenisPustaka] = useState(jenis_pustaka[0]);

  // useEffect(() => {
  //   setJenisPustakaSelected(jenisPustaka);
  // }, [
  //   jenisPustaka,
  // ]);

  const handleClickUrutan = (value) => {
    setButtonIsActive({ [value]: true });
  };

  const handleClickTerapkan = () => {
    getKoleksi();
    handleClose();
  };

  // const handleClickOpen = () => {
  //   setStateFilterKoleksi(true);
  // };

  const handleClose = () => {
    setStateFilterKoleksi(false);
  };

  return (
      <Dialog
        fullScreen
        open={stateFilterKoleksi}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Filter
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ padding: "16px" }}>
            <Typography sx={{ fontSize: "12px", marginBottom: "3px" }}>
              Urutkan Berdasarkan
            </Typography>
            <Box
              sx={{
                borderRadius: "10px",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                // backgroundColor: color.grey,
              }}
            >
              <Button
                size="small"
                color={buttonIsActive.relevan ? "primary" : "inherit"}
                variant="contained"
                urutan={"relevan"}
                onClick={(e) =>
                  handleClickUrutan(e.currentTarget.attributes.urutan.value)
                }
                sx={{
                  borderRadius: "10px 0 0 10px",
                }}
              >
                Relevansi
              </Button>
              <Button
                size="small"
                color={buttonIsActive.terbaru ? "primary" : "inherit"}
                variant="contained"
                urutan={"terbaru"}
                onClick={(e) =>
                  handleClickUrutan(e.currentTarget.attributes.urutan.value)
                }
                sx={{
                  borderRadius: "0",      
                }}
              >
                Terbaru
              </Button>
              <Button
                size="small"
                color={buttonIsActive.banyak_dibaca ? "primary" : "inherit"}
                variant="contained"
                urutan={"banyak_dibaca"}
                onClick={(e) =>
                  handleClickUrutan(e.currentTarget.attributes.urutan.value)
                }
                sx={{
                  borderRadius: "0 10px 10px 0",
                }}
              >
                Paling Banyak Dibaca
              </Button>
            </Box>
            <Typography
              sx={{ fontSize: "12px", marginTop: "24px", marginBottom: "3px" }}
            >
              Kategori Bahan Pustaka
            </Typography>
            <Autocomplete
              value={jenisPustakaSelected}
              onChange={(event, newValue) => {
                setJenisPustakaSelected(newValue);
              }}
              size="small"
              disablePortal
              id="combo-box-demo"
              options={jenis_pustaka}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} />}
            />            
            <Box sx={{ marginTop: "24px" }}>
              <IndeterminateCheckbox/>
            </Box> 
            <Typography sx={{ fontSize: "12px", marginTop: "24px" }}>
              Tahun
            </Typography>
            <RangeSlider />
            <Button
              size="small"
              color="primary"
              variant="contained"
              sx={{ marginTop: "24px" }}
              onClick={handleClickTerapkan}
            >
              Terapkan
            </Button>
          </Box>
      </Dialog>
  );
}