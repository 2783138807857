import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import color from "../theme/Color";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Juni",
  "Juli",
  "Agus",
  "Sept",
  "Okt",
  "Nov",
  "Des",
];

const NotificationCard = (props) => {
  const getTanggal = (tanggal) => {
    const d = new Date(tanggal);
    const date = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear();

    return `${date}-${month}-${year}`;
  };
  return (
    <Card
      sx={{
        backgroundColor: color.white_transparent,
        backdropFilter: "blur(10px)",
        width: "100%",
        height: "100%",
        margin: "auto",
        marginTop: "15px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1)",

      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <InfoIcon />
            <Typography sx={{ marginLeft: "5px" }}>{props.tipe}</Typography>
          </Box>
          <Typography>{getTanggal(props.tanggal)}</Typography>
        </Box>
        <Box sx={{ paddingLeft: "30px" }}>
          <Typography sx={{ fontWeight: "bold" }}>{props.judul}</Typography>
          <Typography>{props.konten}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
