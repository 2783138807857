import {
  Alert,
  Box,
  Button,
  CssBaseline,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import GoogleIcon from "@mui/icons-material/Google";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import TokenContext from "../context/TokenProvider";
import jwtDecode from "jwt-decode";
import MessageContext from "../context/MessageProvider";

const Login = () => {
  const navigate = useNavigate();
  const { setAccessToken, setDecodedData, navTo } = useContext(TokenContext);
  const { message, setMessage } = useContext(MessageContext);
  const [messageForm, setMessageForm] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeUsername = (username) => {
    setUsername(username);
  };

  const handleChangePassword = (password) => {
    setPassword(password);
  };

  const handleNavRegister = () => {
    navigate("/register");
  };

  const TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  const handleCloseMessage = () => {
    setMessage({ isOpen: false, content: "" });
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          username: username,
          password: password,
        }
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      setMessage({ isOpen: true, content: response.data.message });
      navigate(navTo);
    } catch (error) {
      if (error.response) {
        setMessageForm(error.response.data.message);
      }
    }
  };

  const handleLoginGmail = async () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#D9D9D9",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "430px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "20px",
            border: "1px solid rgba(128, 128, 128, 0.5)",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px",
          }}
        >
          {/* <Box
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                src="./image/Logo.png"
                alt="Logo SIMPus"
                style={{
                  width: "64px",
                }}
              />
              <Typography sx={{ fontSize: "20px" }}>SIMPus</Typography>
            </Box>
          </Box> */}
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                src="./image/Logo.png"
                alt="Logo SIMPus"
                style={{
                  width: "72px",
                  margin: "16px auto",
                }}
              />
              <Typography
                textAlign="center"
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: "24px" }}
              >
                Masuk
              </Typography>
            </Box>
            {messageForm !== "" && (
              <Alert severity="error" sx={{ marginBottom: "5px" }}>
                {messageForm}
              </Alert>
            )}
            <Typography sx={{ marginBottom: "3px" }}>Username</Typography>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                type="text"
                placeholder="Masukkan username Anda"
                size="small"
                value={username}
                onChange={(e) => handleChangeUsername(e.currentTarget.value)}
              />
            </FormControl>
            <Typography sx={{ marginBottom: "3px", marginTop: "16px" }}>
              Password
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                placeholder="Masukkan password Anda"
                size="small"
                value={password}
                onChange={(e) => handleChangePassword(e.currentTarget.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography
              sx={{ width: "100%", textAlign: "right", fontSize: "12px" }}
            >
              Lupa Password
            </Typography>
            <Button
              variant="contained"
              sx={{ width: "100%", marginTop: "22px" }}
              onClick={handleSubmitLogin}
            >
              Login
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "100%", marginTop: "16px" }}
              onClick={handleNavRegister}
            >
              Register
            </Button>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 50px auto",
                marginTop: "22px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  margin: "auto",
                  backgroundColor: "black",
                }}
              ></Box>
              <Typography sx={{ margin: "auto" }}>atau</Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  margin: "auto",
                  backgroundColor: "black",
                }}
              ></Box>
            </Box>

            <Button
              onClick={handleLoginGmail}
              variant="contained"
              sx={{ width: "100%", marginTop: "10px" }}
            >
              <GoogleIcon sx={{ marginRight: "10px" }} /> Sign In with Google
            </Button>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={message.isOpen}
        onClose={handleCloseMessage}
        autoHideDuration={6000}
        TransitionComponent={TransitionLeft}
        key={"right"}
      >
        <Alert sx={{ width: "100%" }} severity={message.severity}>
          {message.content}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Login;
