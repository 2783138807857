import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuContext from "../../context/MenuProvider";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { red } from "@mui/material/colors";
import Loading from '../Loading';

const TujuanBerkunjungDialog = (props) => {
  const {
    stateTujuanBerkunjungDialog,
    setStateTujuanBerkunjungDialog,
    setStateNoKunciDialog,
  } = useContext(MenuContext);
  const { setMessage } = useContext(MessageContext);
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const { setStateAlertDialog } = useContext(MenuContext);
  const [tujuan, setTujuan] = useState("");
  const [isPinjam, setIsPinjam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageForm, setMessageForm] = useState("");

  useEffect(() => {
    console.log("isPinjam : ", isPinjam);
    console.log("tujuan : ", tujuan);
  }, [isPinjam, tujuan]);

  const handleClose = () => {
    setStateTujuanBerkunjungDialog(false);
  };
  const handleClickOpen = () => {
    setStateTujuanBerkunjungDialog(true);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleTujuanBerkunjung = async () => {
    try {
      if (isPinjam === null || tujuan === "") {
        setMessageForm("Form daftar hadir wajib diisi");
      } else {
        handleClose();
        setLoading(true);
        const response = await axiosJWT.post(
          `${process.env.REACT_APP_API_URL}/masuk`,
          {
            tujuan: tujuan,
            pinjam: isPinjam,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setLoading(false);
        console.log(response.data);
        if (isPinjam === "true") {
          setStateNoKunciDialog(true);
        } else {
          setMessage({
            isOpen: true,
            content: response.data.message,
            severity: "success",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setMessage({
        isOpen: true,
        content: error.response.data.message,
        severity: "error",
      });
    }
  };

  return (
    <div>
      <Dialog open={stateTujuanBerkunjungDialog} maxWidth="xs">
        <DialogTitle sx={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            color="success"
            sx={{
              fontSize: "56px",
            }}
          />
          <div>Login Kunjungan Berhasil</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            Silahkan isi form daftar hadir sebelum memasuki perpustakaan
          </DialogContentText>
          <FormControl fullWidth size="small" sx={{ marginTop: "16px" }}>
            {messageForm !== "" && (
              <Alert severity="error" sx={{ marginBottom: "5px" }}>
                {messageForm}
              </Alert>
            )}
            <FormLabel id="tujuan-berkunjung" sx={{ fontSize: "12px" }}>
              Tujuan Berkunjung
              <span style={{ color: red[500] }}> *</span>
            </FormLabel>
            <Select
              value={tujuan}
              onChange={(e) => setTujuan(e.target.value)}
              aria-labelledby="tujuan-berkunjung"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"1"}>Membaca</MenuItem>
              <MenuItem value={"2"}>Meminjam/Mengembalikan</MenuItem>
              <MenuItem value={"3"}>Belajar/Diskusi</MenuItem>
              <MenuItem value={"4"}>Lainnya</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: "16px" }}>
            <FormLabel id="kunci-loker" sx={{ fontSize: "12px" }}>
              Apakah Anda ingin menggunakan loker?
              <span style={{ color: red[500] }}> *</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="kunci-loker"
              name="row-radio-buttons-group"
              value={isPinjam}
              onChange={(e) => setIsPinjam(e.target.value)}
            >
              <FormControlLabel value={true} control={<Radio />} label="Ya" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Tidak"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ margin: "auto" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleTujuanBerkunjung}
            autoFocus
          >
            Kirim
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Loading/>
      </Backdrop>
    </div>
  );
};

export default TujuanBerkunjungDialog;
