import * as React from "react";
import { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { AdminAppBar } from "./AppBar";
import { AdminSidebar } from "./SideBar";
import { Alert, Box, Slide, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import MessageContext from "../../context/MessageProvider";
import DeleteAlertDialog from "../../components/admin/DeleteAlertDialog";
import FormCreateMatkulDialog from "../../components/admin/FormCreateMatkulDialog";
import FormAddBukuToMatkulDialog from "../../components/admin/FormAddBukuToMatkulDialog";
import KonfirmasiPeminjamanDialog from "../../components/admin/KonfirmasiPeminjamanDialog";
import PerpanjangPeminjamanDialog from "../../components/admin/PerpanjangPeminjamanDialog";
import KembalikanPeminjamanDialog from "../../components/admin/KembalikanPeminjamanDialog";
import UbahKunciDialog from "../../components/admin/UbahKunciDialog";
import KembalikanKunciDialog from "../../components/admin/KembalikanKunciDialog";
import MemulangkanPengunjungDialog from "../../components/admin/MemulangkanPengunjungDialog";
import FormSalinKoleksiDialog from "../../components/admin/FormSalinKoleksiDialog";
import KembalikanDanMemulangkanPengunjungSerentak from "../../components/admin/KembalikanDanMemulangkanPengunjungSerentak";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AdminLayout = (props) => {
  const { message, setMessage } = useContext(MessageContext);

  const TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  const handleCloseMessage = () => {
    setMessage({ isOpen: false, content: "" });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "rgb(240,240,240)",
          minHeight: "100%",
        }}
      >
        <CssBaseline />
        <AdminAppBar />

        <AdminSidebar />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {props.children}
        </Box>
        <Snackbar
          open={message.isOpen}
          onClose={handleCloseMessage}
          autoHideDuration={4000}
          TransitionComponent={TransitionLeft}
          key={"right"}
        >
          <Alert sx={{ width: "100%" }} severity={message.severity}>
            {message.content}
          </Alert>
        </Snackbar>

        <DeleteAlertDialog />
        <KonfirmasiPeminjamanDialog />
        <PerpanjangPeminjamanDialog />
        <KembalikanPeminjamanDialog />
        <KembalikanDanMemulangkanPengunjungSerentak />
        <UbahKunciDialog />
        <KembalikanKunciDialog />
        <MemulangkanPengunjungDialog />
        <FormCreateMatkulDialog />
        <FormAddBukuToMatkulDialog />
        <FormSalinKoleksiDialog />
      </Box>
    </React.Fragment>
  );
};

export default AdminLayout;
