import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppBarItemsLeft from "./AppBarItemsLeft";
import AppBarItemsCenter from "./AppBarItemsCenter";
import AppBarItemsRight from "./AppBarItemsRight";
import { Stack } from "@mui/material";
import color from "../../theme/Color";

const MyAppBar = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: color.white_transparent,
        backdropFilter: "blur(10px)",
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <AppBarItemsLeft />

            <AppBarItemsCenter />

            <AppBarItemsRight />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MyAppBar;
