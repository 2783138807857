import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useContext, useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import DialogContext from "../../context/admin/DialogProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";

const FormCreateMatkulDialog = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const {
    kodeMatkul,
    createFormRekomendasiDialogOpen,
    setCreateFormRekomendasiDialogOpen,
  } = useContext(DialogContext);
  const [matkul, setMatkul] = useState("");

  useEffect(() => {}, [createFormRekomendasiDialogOpen]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleClose = () => {
    setCreateFormRekomendasiDialogOpen(false);
  };

  const handleChangeMatkul = (event) => {
    setMatkul(event.target.value);
  };

  const handleSubmitMatkul = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/a/rekomendasi`,
        { kode: kodeMatkul, matkul: matkul },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setMessage({
        isOpen: true,
        content: response.data.message,
        severity: "success",
      });
      navigate("/a/rekomendasi");
      handleClose();
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <div>
      <Dialog open={createFormRekomendasiDialogOpen} onClose={handleClose}>
        <DialogTitle>
          Tambah Matkul {kodeMatkul.split("/")[1]} Smt{" "}
          {kodeMatkul.split("/")[2]}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Matkul"
            variant="outlined"
            sx={{ width: "100%", marginTop: "10px" }}
            onChange={handleChangeMatkul}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitMatkul} variant="contained">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormCreateMatkulDialog;
