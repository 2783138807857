import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useContext, useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import DialogContext from "../../context/admin/DialogProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { Box } from "@mui/material";

const FormSalinKoleksiDialog = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const { id, formSalinKoleksiDialogOpen, setFormSalinKoleksiDialogOpen } =
    useContext(DialogContext);
  const [koleksi, setKoleksi] = useState("");
  const [noInduk, setNoInduk] = useState("");
  const [tanggalMasuk, setTanggalMasuk] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [infoTambahan, setInfoTambahan] = useState({
    penerbit: "",
    sumber: "",
    cetakan: "",
    edisi: "",
    jilid: "",
    isbn: "",
    no_eks: "",
    harga: "",
    issn: "",
    volume: "",
    no_publikasi: "",
    katalog_publikasi: "",
    keterangan: "",
    bulan_terbit: "",
    kode_klasifikasi: "",
  });

  useEffect(() => {
    if (id) {
      getDetailKoleksi();
    }
  }, [formSalinKoleksiDialogOpen]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getDetailKoleksi = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/koleksi/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setKoleksi(response.data);
      setInfoTambahan(response.data.infoTambahan);
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };
  const handleClose = () => {
    setFormSalinKoleksiDialogOpen(false);
  };

  const handleChangeNoInduk = (event) => {
    setNoInduk(event.target.value);
  };

  const handleChangeTanggalMasuk = (value) => {
    setTanggalMasuk(`${value.$y}-${value.$M + 1}-${value.$D}`);
  };

  const handleChangeSumber = (event) => {
    setInfoTambahan({ ...infoTambahan, sumber: event.target.value });
  };

  const handleChangeNoEks = (event) => {
    setInfoTambahan({ ...infoTambahan, no_eks: event.target.value });
  };

  const handleChangeQrcode = (event) => {
    setQrcode(event.target.value);
  };

  const handleSubmitkoleksi = async (e) => {
    e.preventDefault();
    try {
      if (koleksi) {
        const response = await axiosJWT.post(
          `${process.env.REACT_APP_API_URL}/a/koleksi`,
          {
            noInduk: noInduk,
            jenisPustaka: koleksi.jenisPustaka,
            klasifikasi: koleksi.klasifikasi,
            qrcode: qrcode,
            judul: koleksi.judul,
            penulis: koleksi.penulis,
            tahunTerbit: koleksi.tahunTerbit,
            kolasi: koleksi.kolasi,
            kodeRak: koleksi.kodeRak,
            tanggalMasuk: tanggalMasuk,
            infoTambahan: infoTambahan,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setMessage({
          isOpen: true,
          content: response.data.message,
          severity: "success",
        });
        navigate("/a/koleksi");
        handleClose();
      }
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <div>
      <Dialog open={formSalinKoleksiDialogOpen} onClose={handleClose}>
        <DialogTitle textAlign="center">
          Salin {koleksi && koleksi.jenisPustaka}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              marginY: "20px",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: "20px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="No Induk"
              variant="outlined"
              onChange={handleChangeNoInduk}
            />
            <TextField
              id="outlined-basic"
              label="No Eksemplar"
              variant="outlined"
              onChange={handleChangeNoEks}
            />
            <TextField
              id="outlined-basic"
              label="Sumber"
              variant="outlined"
              onChange={handleChangeSumber}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Tanggal Masuk"
                onChange={(newValue) => handleChangeTanggalMasuk(newValue)}
              />
            </LocalizationProvider>
          </Box>
          {(koleksi.jenisPustaka === "Buku" ||
            koleksi.jenisPustaka === "Publikasi") && (
            <TextField
              id="outlined-basic"
              label="QR Code"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChangeQrcode}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitkoleksi} variant="contained">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormSalinKoleksiDialog;
