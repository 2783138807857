import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
  TextField,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";
import DialogContext from "../../../context/admin/DialogProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DataTablePengunjung = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const {
    setIdPengunjung,
    setKembalikanKunciDialogOpen,
    setUbahKunciDialogOpen,
    setMemulangkanPengunjungDialogOpen,
    setKembalikanDanMemulangkanPengunjungSerentakDialogOpen,
  } = useContext(DialogContext);
  const { message, setMessage } = useContext(MessageContext);
  const [state, setState] = useState(0);
  const [pengunjung, setPengunjung] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [namaPengguna, setNamaPengguna] = useState("");
  const [nomorKunci, setNomorKunci] = useState("");

  useEffect(() => {
    setNavTo("/a/kunci");
    refreshToken();
    getAllPengunjung();
  }, [state, page, rowsPerPage, message]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPengunjung = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/pengunjung?page=${page}&size=${rowsPerPage}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setPengunjung(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const openDialogKembalikanDanMemulangkanPengunjungSerentak = () => {
    setKembalikanDanMemulangkanPengunjungSerentakDialogOpen(true);
  };

  const openDialogKembalikanKunci = (uuid) => {
    setIdPengunjung(uuid);
    setKembalikanKunciDialogOpen(true);
  };

  const openDialogUbahKunci = (uuid) => {
    setIdPengunjung(uuid);
    setUbahKunciDialogOpen(true);
  };

  const openDialogMemulangkanPengunjung = (uuid) => {
    setIdPengunjung(uuid);
    setMemulangkanPengunjungDialogOpen(true);
  };

  const handleChangeNamaPengguna = (value) => {
    setNamaPengguna(value);
  };

  const handleSubmitFilterNamaPengunjung = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/pengunjung?page=${page}&size=${rowsPerPage}&nama=${namaPengguna}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setPengunjung(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const handleChangeNomorKunci = (value) => {
    setNomorKunci(value);
  };

  const handleSubmitFilterNomorKunciPengunjung = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/pengunjung?page=${page}&size=${rowsPerPage}&nomor=${nomorKunci}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setPengunjung(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({
          isOpen: true,
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: "20px" }}>
        <form
          onSubmit={handleSubmitFilterNamaPengunjung}
          style={{ display: "flex" }}
        >
          <TextField
            size="small"
            id="outlined-basic"
            label="Filter Nama"
            variant="outlined"
            sx={{ width: { xs: "100%", md: "200px" } }}
            onChange={(e) => handleChangeNamaPengguna(e.currentTarget.value)}
          />
          <Button size="small" type="submit" variant="contained">
            Cari
          </Button>
        </form>
        <form
          onSubmit={handleSubmitFilterNomorKunciPengunjung}
          style={{ display: "flex" }}
        >
          <TextField
            size="small"
            id="outlined-basic"
            label="Filter Nomor Kunci"
            variant="outlined"
            sx={{ width: { xs: "100%", md: "200px" } }}
            onChange={(e) => handleChangeNomorKunci(e.currentTarget.value)}
          />
          <Button size="small" type="submit" variant="contained">
            Cari
          </Button>
        </form>
        <Button
          size="small"
          variant="contained"
          onClick={openDialogKembalikanDanMemulangkanPengunjungSerentak}
        >
          Kembalikan Semua
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Pengunjung</StyledTableCell>
              <StyledTableCell align="center">No Kunci</StyledTableCell>
              <StyledTableCell align="center">Waktu Datang</StyledTableCell>
              <StyledTableCell align="center">Waktu Keluar</StyledTableCell>
              <StyledTableCell align="center">Aksi</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pengunjung !== null &&
              pengunjung.rows.map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell
                    align="center"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`https://stis.ac.id/sipadu/mahasiswa/foto_mhs/${row.username}.jpg`}
                      alt={`${row.username}.jpg`}
                      width="150"
                    />
                    <Typography
                      sx={{
                        width: "fit-content",
                        marginY: "5px",
                        padding: "5px 10px",
                        backgroundColor: "rgb(200,200,200)",
                        borderRadius: "5px",
                      }}
                    >
                      {row.username}
                    </Typography>
                    <Typography>{row.nama}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.noKunci}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.waktuMasuk).getHours() +
                      ":" +
                      new Date(row.waktuMasuk).getMinutes() +
                      ":" +
                      new Date(row.waktuMasuk).getSeconds()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.waktuKeluar ? (
                      <Typography
                        variant="p"
                        sx={{
                          width: "fit-content",
                          padding: "5px 10px",
                          backgroundColor: "green",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        {new Date(row.waktuKeluar).getHours() +
                          ":" +
                          new Date(row.waktuKeluar).getMinutes() +
                          ":" +
                          new Date(row.waktuKeluar).getSeconds()}
                      </Typography>
                    ) : (
                      <Typography
                        variant="p"
                        sx={{
                          width: "fit-content",
                          padding: "5px 10px",
                          backgroundColor: "red",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        Belum Pulang
                      </Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack alignItems="center">
                      {row.noKunci ? (
                        row.statusKunci ? (
                          <Button
                            sx={{ width: "fit-content" }}
                            variant="contained"
                            disabled
                          >
                            Kembalikan
                          </Button>
                        ) : (
                          <Button
                            sx={{ width: "fit-content" }}
                            variant="contained"
                            onClick={() => openDialogKembalikanKunci(row.id)}
                          >
                            Kembalikan
                          </Button>
                        )
                      ) : (
                        <Button
                          sx={{ width: "fit-content" }}
                          variant="contained"
                          onClick={() =>
                            openDialogMemulangkanPengunjung(row.id)
                          }
                        >
                          Pulangkan
                        </Button>
                      )}
                      <Button
                        sx={{ width: "fit-content" }}
                        variant="contained"
                        color="success"
                        onClick={() => openDialogUbahKunci(row.id)}
                      >
                        Ubah Kunci
                      </Button>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {pengunjung === null && (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <StyledTableRow key={1}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
          <TablePagination
            count={pengunjung !== null ? pengunjung.count : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default DataTablePengunjung;
