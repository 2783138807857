import * as React from "react";
import jwtDecode from "jwt-decode";
import { useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import fileDownload from "js-file-download";
import { Typography, Box, Container, Stack, Button } from "@mui/material";
import { MyLayout } from "../../../layout";
import color from "../../../theme/Color";
import RightSideBarTentangKami from "../../../components/RightSideBarTentangKami";
import MessageContext from "../../../context/MessageProvider";
import TokenContext from "../../../context/TokenProvider";
import MenuContext from "../../../context/MenuProvider";

const SuratBebasPerpustakaan = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setDecodedData, navTo, setNavTo } =
    useContext(TokenContext);
  const { setStateAlertDialog } = useContext(MenuContext);
  const { setMessage } = useContext(MessageContext);

  useEffect(() => {
    setNavTo("/sbp");
    // refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const unduhSurat = async (e) => {
    try {
      e.preventDefault();
      refreshToken();
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/surat-bebas-perpus`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        }
      );
      fileDownload(response.data, `Surat-Bebas-Perpus.pdf`);
    } catch (error) {
      if (error.response) {
        setStateAlertDialog({
          isOpen: true,
          title: "Pengunduhan Surat Gagal",
          content: error.response.data.message,
          severity: "error",
        });
      }
    }
  };
  return (
    <MyLayout
      title={"Surat Bebas Perpustakaan"}
      menu={"Tentang Kami"}
      submenu={"Surat Bebas Perpustakaan"}
    >
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: color.dark_blue,
                  fontWeight: "bold",
                }}
              >
                Surat Bebas Perpustakaan
              </Typography>
              <Typography
                align="justify"
                sx={{ fontSize: "14px", marginTop: "8px" }}
              >
                Surat bebas pustaka adalah sebuah dokumen resmi yang dikeluarkan
                oleh perpustakaan yang menyatakan bahwa seseorang telah
                mengembalikan semua bahan pustaka yang dipinjam dan telah
                melunasi semua tunggakan atau denda yang terkait dengan
                peminjaman tersebut. Dokumen ini merupakan bukti bahwa seseorang
                tidak memiliki tanggungan apa pun terhadap perpustakaan.
              </Typography>
              <Typography
                align="justify"
                sx={{ fontSize: "14px", marginTop: "8px" }}
              >
                Surat bebas pustaka merupakan salah satu syarat kelulusan bagi
                yang masih memiliki tanggungan di perpustakaan STIS. Pengajuan
                surat bebas pustaka hanya dibuka pada waktu yang telah
                ditentukan oleh petugas perpustakaan.
              </Typography>
              {/* <Typography sx={{ fontSize: "14px", marginTop: "8px" }}>
                Surat bebas perpustakaan dapat diunduh <Button onClick={(e) => handleClick()} sx={{padding : "0"}}>di sini.</Button>
              </Typography> */}
              <Button
                sx={{ marginTop: "10px" }}
                variant="contained"
                onClick={unduhSurat}
              >
                Unduh Surat
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Tentang Kami
              </Typography>
            </Stack>
            <RightSideBarTentangKami
              menu-is-active={"Layanan"}
              submenu-is-active={"Surat Bebas Perpustakaan"}
            />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default SuratBebasPerpustakaan;
