import * as React from "react";
import { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { MyAppBar } from "./AppBar";
import { MyFooter } from "./Footer";
import ProfilScreenSmall from "../pages/ProfilScreenSmall";
import ProfilScreenMedium from "../pages/ProfilScreenMedium";
import NotifikasiScreenSmall from "../pages/NotifikasiScreenSmall";
import NotifikasiScreenMedium from "../pages/NotifikasiScreenMedium";
import MenuContext from "../context/MenuProvider";
import QrCodeScanButton from "../components/QrCodeScanButton";
import {
  Box,
  Typography,
  Container,
  Snackbar,
  Slide,
  Alert,
  Modal,
} from "@mui/material";
import BasicBreadcrumbs from "../components/BasicBreadcrumbs";
import MySideBar from "./SideBar/MySideBar";
import SubSideBarTentangKami from "./SideBar/SubSideBar/SubSideBarTentangKami";
import SubSideBarKoleksi from "./SideBar/SubSideBar/SubSideBarKoleksi";
import SubSideBarBantuan from "./SideBar/SubSideBar/SubSideBarBantuan";
import { SubSideBarFasilitas, SubSideBarLayanan } from "./SideBar/SubSideBar";
import KoleksiDetail from "../pages/KoleksiDetail";
import { SubAppBarKoleksi } from "./AppBar/SubAppBar";
import SubAppBarBantuan from "./AppBar/SubAppBar/SubAppBarBantuan";
import SubAppBarTentangKami from "./AppBar/SubAppBar/SubAppBarTentangKami";
import color from "../theme/Color";
import KritikSaranDialog from "../components/KritikSaranDialog";
import UjiCobaDialog from "../components/dialog/UjiCobaDialog";
import AlertDialog from "../components/dialog/AlertDialog";
import NoKunciDialog from "../components/dialog/NoKunciDialog"
import TujuanBerkunjungDialog from "../components/dialog/TujuanBerkunjungDialog";
import FormPenilaianDialog from "../components/dialog/FormPenilaian";
import RekomendasiDialog from "../components/RekomendasiDialog";
import QRScanner from "../components/dialog/QRScanner";
import ShareKoleksiDialog from "../components/dialog/ShareKoleksi";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TokenContext from "../context/TokenProvider";
import KoleksiContext from "../context/KoleksiProvider";
import FilterDialog from "../pages/Koleksi/FilterDialog";
import MessageContext from "../context/MessageProvider";
// import Html5QrcodePlugin from "../components/QrCodeScanner";

const MyLayout = (props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("sm"));
  const { stateAppBar } = useContext(MenuContext);
  const { accessToken } = useContext(TokenContext);
  const { idKoleksi } = useContext(KoleksiContext);
  const { message, setMessage } = useContext(MessageContext);

  const TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  const handleCloseMessage = () => {
    setMessage({ isOpen: false, content: "" });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {sm && accessToken !== null && <NotifikasiScreenSmall />}
      {md && accessToken !== null && <NotifikasiScreenMedium />}
      {sm && accessToken !== null && <ProfilScreenSmall />}
      {md && accessToken !== null && <ProfilScreenMedium />}

      <KritikSaranDialog />
      <UjiCobaDialog />
      <AlertDialog />
      <NoKunciDialog />
      <TujuanBerkunjungDialog />
      <FormPenilaianDialog />
      <FilterDialog />
      <QRScanner />
      <ShareKoleksiDialog />
      <RekomendasiDialog />

      <MyAppBar />
      {stateAppBar["subAppBarKoleksi"] && <SubAppBarKoleksi />}
      {stateAppBar["subAppBarBantuan"] && <SubAppBarBantuan />}
      {stateAppBar["subAppBarTentangKami"] && <SubAppBarTentangKami />}

      <MySideBar />
      <SubSideBarTentangKami />
      <SubSideBarFasilitas />
      <SubSideBarLayanan />
      <SubSideBarKoleksi />
      <SubSideBarBantuan />

      {idKoleksi !== 0 && <KoleksiDetail />}

      {props.title === "Beranda" && props.children}

      {props.title !== "Beranda" && (
        <>
          <Box
            sx={{
              paddingTop: "90px",
              paddingBottom: "25px",
              backgroundImage: "url(./image/header_each_page.png)",
              backgroundSize: "cover",
            }}
          >
            <Container maxWidth="lg">
              <BasicBreadcrumbs menu={props.menu} submenu={props.submenu} />
              <Typography
                sx={{
                  marginTop: "8px",
                  fontSize: { xs: "40px", md: "60px" },
                  color: color.light_white,
                }}
              >
                {props.title}
              </Typography>
            </Container>
          </Box>
          <Box sx={{ ...props.sx }}>{props.children}</Box>
        </>
      )}

      {accessToken !== null && <QrCodeScanButton />}
      <Snackbar
        open={message.isOpen}
        onClose={handleCloseMessage}
        autoHideDuration={6000}
        TransitionComponent={TransitionLeft}
        key={"right"}
      >
        <Alert sx={{ width: "100%" }} severity={message.severity}>
          {message.content}
        </Alert>
      </Snackbar>
      <MyFooter />
    </React.Fragment>
  );
};

export default MyLayout;
