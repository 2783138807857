import {
  Typography,
  Box,
  Container,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Alert,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useContext, useEffect } from "react";
import { MyLayout } from "../../layout";
import color from "../../theme/Color";
import RightSideBarBantuan from "../../components/RightSideBarBantuan";
import TokenContext from "../../context/TokenProvider";
import MessageContext from "../../context/MessageProvider";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";

const KritikDanSaran = () => {
  const [kategori, setKategori] = useState("");
  const [konten, setKonten] = useState("");
  const [messageForm, setMessageForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { message, setMessage } = useContext(MessageContext);
  const { accessToken, setAccessToken, decodedData, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/kritik-saran");
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const handleChange = (event) => {
    setKategori(event.target.value);
    setSuccess(false);
  };

  const handleSendData = () => {
    if (kategori === "" || konten === "") {
      setMessageForm("Semua form wajib diisi");
    } else {
      setLoading(true);
      // setTimeout(() => {
      sendKritikSaran();
      setLoading(false);
      // }, 3000);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const sendKritikSaran = async () => {
    try {
      const response = await axiosJWT.post(
        `${process.env.REACT_APP_API_URL}/saran`,
        {
          kategori: kategori,
          konten: konten,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setMessage({ isOpen: true, content: response.data.message });
      setKategori("");
      setKonten("");
      setSuccess(true);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <MyLayout
      title={"Kritik dan Saran"}
      menu={"Bantuan"}
      submenu={"Kritik dan Saran"}
    >
      <Box sx={{ backgroundColor: color.dark_white }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingY: "40px",
            display: "grid",
            gridTemplateColumns: { xs: "auto", md: "auto 373px" },
            gap: "114px",
          }}
        >
          <Box>
            <Box sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth size="small">
                {messageForm !== "" && (
                  <Alert severity="error" sx={{ marginBottom: "5px" }}>
                    {messageForm}
                  </Alert>
                )}
                <Typography sx={{ fontSize: "12px" }}>
                  Kategori <span style={{ color: red[500] }}> *</span>
                </Typography>
                <Select
                  value={kategori}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"1"}>Fasilitas</MenuItem>
                  <MenuItem value={"2"}>Buku</MenuItem>
                  <MenuItem value={"3"}>Pelayanan</MenuItem>
                  <MenuItem value={"4"}>Sistem/Aplikasi</MenuItem>
                  <MenuItem value={"5"}>Lainnya</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" sx={{ marginTop: "22px" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  Kritik dan Saran <span style={{ color: red[500] }}> *</span>
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  value={konten}
                  onChange={(e) => {
                    setKonten(e.currentTarget.value);
                    setSuccess(false);
                  }}
                />
              </FormControl>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={handleSendData}
                  sx={{ ...buttonSx, width: "fit-content", marginTop: "22px" }}
                >
                  Kirim
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
          {/* rightSideBarbutton */}
          <Box sx={{ width: "373px", display: { xs: "none", md: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "62px", backgroundColor: color.dark_blue }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: color.light_white,
                  fontSize: "20px",
                }}
              >
                Bantuan
              </Typography>
            </Stack>

            <RightSideBarBantuan menu-is-active={"Kritik dan Saran"} />
          </Box>
        </Container>
      </Box>
    </MyLayout>
  );
};

export default KritikDanSaran;
