import { Box, Typography } from "@mui/material";
import "../css/Profil.css";
import { useContext, useEffect } from "react";
import MenuContext from "../context/MenuProvider";
import NotificationCard from "../components/NotificationCard";
import color from "../theme/Color";
import TokenContext from "../context/TokenProvider";
import { useState } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";

const notifications = [
  {
    type: "Pengingat",
    date: "12-02-2023",
    title: "Pengembalian Kunci",
    content: "Terima kasih telah berkunjung, jangan lupa kembalikan kunci ya.",
  },
  {
    type: "Info",
    date: "12-02-2023",
    title: "Pengadaan Buku",
    content:
      "Halo teman-teman, kini skripsi terbaru tahun ajaran 2020-2021 sudah tersedia di SIMPus. Untuk membacanya pastikan install aplikasi SIMPus e-Reader di Playstore.",
  },
  {
    type: "Pengingat",
    date: "12-02-2023",
    title: "Pengembalian Buku",
    content:
      "Ini hari terakhir pengembalian buku yang kamu pinjam. Ayo segera kembalikan buku di perpustakaan.",
  },
];
const NotifikasiScreenMedium = () => {
  const { stateUserMenu, setStateUserMenu } = useContext(MenuContext);
  const { accessToken, setAccessToken, decodedData, setDecodedData } =
    useContext(TokenContext);
  const [notifikasi, setNotifikasi] = useState(null);

  useEffect(() => {
    getNotifikasi();
  }, []);

  const getNotifikasi = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/notifikasi?role=intern`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setNotifikasi(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (decodedData.exp * 1000 < currentDate.getTime()) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/refresh-token`
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setAccessToken(response.data.accessToken);
        setDecodedData(jwtDecode(response.data.accessToken));
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateUserMenu({ [anchor]: open });
  };
  return (
    <Box
      sx={
        stateUserMenu.notifikasi
          ? {
              width: "385px",
              height: "436px",
              position: "fixed",
              zIndex: "1000",
              right: "0",
              top: "64px",
              backgroundColor: color.white_transparent,
              backdropFilter: "blur(10px)",
              overflow: "auto",
              borderRadius: "0 0 10px 10px",
              boxShadow: "0 0 15px #ADB5BD",
            }
          : { display: "none" }
      }
      onMouseOver={toggleDrawer("notifikasi", true)}
      onMouseOut={toggleDrawer("notifikasi", false)}
    >
      <Box sx={{ padding: "20px" }}>
        {/* <Typography sx={{ marginBottom: "10px" }}>Hari Ini</Typography> */}
        {notifikasi !== null &&
          notifikasi.map((element, key) => (
            <NotificationCard
              key={key}
              tipe={element.tipe}
              tanggal={element.tanggal}
              judul={element.judul}
              konten={element.konten}
            />
          ))}
      </Box>
    </Box>
  );
};

export default NotifikasiScreenMedium;
