import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenContext from "../../../../context/TokenProvider";
import ShowMatkulSDSemester7 from "./Tingkat4/SD/Semester7";
import ShowMatkulSDSemester8 from "./Tingkat4/SD/Semester8";
import ShowMatkulSESemester7 from "./Tingkat4/SE/Semester7";
import ShowMatkulSESemester8 from "./Tingkat4/SE/Semester8";
import ShowMatkulSISemester7 from "./Tingkat4/SI/Semester7";
import ShowMatkulSISemester8 from "./Tingkat4/SI/Semester8";
import ShowMatkulSKSemester7 from "./Tingkat4/SK/Semester7";
import ShowMatkulSKSemester8 from "./Tingkat4/SK/Semester8";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanelTingkat4 = () => {
  const { setAccessToken, setDecodedData, setNavTo } = useContext(TokenContext);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setNavTo("/a/rekomendasi");
    refreshToken();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="SD" {...a11yProps(0)} />
          <Tab label="SI" {...a11yProps(1)} />
          <Tab label="SE" {...a11yProps(2)} />
          <Tab label="SK" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ShowMatkulSDSemester7 />
        <ShowMatkulSDSemester8 />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowMatkulSISemester7 />
        <ShowMatkulSISemester8 />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ShowMatkulSESemester7 />
        <ShowMatkulSESemester8 />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ShowMatkulSKSemester7 />
        <ShowMatkulSKSemester8 />
      </TabPanel>
    </Box>
  );
};

export default TabPanelTingkat4;
