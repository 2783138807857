import { createContext, useState } from "react";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [stateUserMenu, setStateUserMenu] = useState({
    profil: false,
    notifikasi: false,
  });
  const [stateKritikSaranDialog, setStateKritikSaranDialog] = useState(false);
  const [stateUjiCobaDialog, setStateUjiCobaDialog] = useState(false);
  const [ stateNoKunciDialog, setStateNoKunciDialog ] = useState(false);
  const [ stateAlertDialog, setStateAlertDialog ] = useState({
    isOpen: false,
    title:"",
    content: "",
    severity: "",
  });
  const [ stateTujuanBerkunjungDialog, setStateTujuanBerkunjungDialog ] = useState(false);
  const [ stateFormPenilaianDialog, setStateFormPenilaianDialog ] = useState(false);
  const [ rekomendasiDialog, setRekomendasiDialog ]= useState(false);
  const [id, setId] = useState("1");
  const [type, setType] = useState("");
  const [ stateQRScanner, setStateQRScanner ] = useState(false);
  const [state, setState] = useState({
    mainSideBar: false,
    subSideBarTentangKami: false,
    subSideBarFasilitas: false,
    subSideBarLayanan: false,
    subSideBarKoleksi: false,
    subSideBarBantuan: false,
  });
  const [stateKoleksi, setStateKoleksi] = useState(false);
  const [stateFilterKoleksi, setStateFilterKoleksi] = useState(false);
  const [stateShareKoleksiDialog, setStateShareKoleksiDialog] = useState(false);
  const [stateAppBar, setStateAppBar] = useState({
    subAppBarTentangKami: false,
    subAppBarKoleksi: false,
    subAppBarBantuan: false,
  });
  const [stateListItem, setStateListItem] = useState({
    listItemFasilitas: false,
    listItemLayanan: false,
  });
  const [stateQrScanner, setStateQrScanner] = useState(false);

  return (
    <MenuContext.Provider
      value={{
        stateUserMenu,
        setStateUserMenu,
        stateKritikSaranDialog,
        setStateKritikSaranDialog,
        state,
        setState,
        stateKoleksi,
        setStateKoleksi,
        stateFilterKoleksi,
        setStateFilterKoleksi,
        stateShareKoleksiDialog,
        setStateShareKoleksiDialog,
        stateAppBar,
        setStateAppBar,
        stateListItem,
        setStateListItem,
        stateQrScanner,
        setStateQrScanner,
        stateUjiCobaDialog,
        setStateUjiCobaDialog,
        stateNoKunciDialog,
        setStateNoKunciDialog,
        stateAlertDialog,
        setStateAlertDialog,
        stateTujuanBerkunjungDialog,
        setStateTujuanBerkunjungDialog,
        stateFormPenilaianDialog,
        setStateFormPenilaianDialog,
        stateQRScanner, 
        setStateQRScanner,
        rekomendasiDialog,
        setRekomendasiDialog,
        id,
        setId,
        type,
        setType
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
