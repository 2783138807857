import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TablePagination,
  Paper,
  TableCell,
  Button,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { AdminLayout } from "../../../layout/admin";
import TokenContext from "../../../context/TokenProvider";
import MessageContext from "../../../context/MessageProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ShowNotifikasi = () => {
  const { accessToken, setAccessToken, decodedData, setDecodedData, setNavTo } =
    useContext(TokenContext);
  const { setMessage } = useContext(MessageContext);
  const [notifikasi, setNotifikasi] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    setNavTo("/a/notifikasi");
    refreshToken();
    getAllNotifikasi();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`
      );
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setAccessToken(response.data.accessToken);
      setDecodedData(jwtDecode(response.data.accessToken));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllNotifikasi = async () => {
    try {
      const response = await axiosJWT.get(
        `${process.env.REACT_APP_API_URL}/a/notifikasi?page=${page}&size=${rowsPerPage}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setNotifikasi(response.data);
    } catch (error) {
      if (error.response) {
        setMessage({ isOpen: true, content: error.response.data.message });
        navigate("/");
      }
    }
  };

  const changePage = () => {
    navigate("/a/notifikasi/create");
  };

  return (
    <AdminLayout>
      <Box sx={{ width: "100%" }}>
        <Button variant="contained" onClick={changePage}>
          <ControlPointIcon sx={{ marginRight: "10px" }} />
          Tambah
        </Button>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Judul</StyledTableCell>
                <StyledTableCell align="center">Kategori</StyledTableCell>
                <StyledTableCell align="center">Konten</StyledTableCell>
                <StyledTableCell align="center">Tanggal</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifikasi !== null &&
                notifikasi.rows.map((row, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell align="center">
                      {row.judul}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.kategori}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.konten}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.tanggal}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {notifikasi === null && (
                <>
                  <StyledTableRow key={1}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={2}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={3}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={4}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={5}>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}
            </TableBody>
            <TablePagination
              count={notifikasi !== null ? notifikasi.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Box>
    </AdminLayout>
  );
};

export default ShowNotifikasi;
